import PropTypes from 'prop-types';

const CrudField = () => {
  return '';
};

CrudField.propTypes = {
  id: PropTypes.string,

  editor: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  getterName: PropTypes.string,
  dictionaryType: PropTypes.string,
  label: PropTypes.string,
  needToFindCountryCode: PropTypes.bool,
  type: PropTypes.string,

  required: PropTypes.bool,

  color: PropTypes.string,

  readonly: PropTypes.bool,
  disableOnAdd: PropTypes.bool,
  disableOnEdit: PropTypes.bool,
  className: PropTypes.string,
};

export default CrudField;

