/*eslint-disable*/
import HttpProvider from './httpProvider';

import { API } from '../constants/api';

class OrganizationService {
  store = {};

  constructor() {
    this.http = HttpProvider;
  }

  async deleteProfileFromOrganization(params) {
    return this.http.remove(API.ORGANIZATION.PROFILE(params));
  }

  async addProfileToOrganization(params, data) {
    return this.http.put(API.ORGANIZATION.PROFILE(params), data);
  }

  async rejectOrganizationProfile(params) {
    return this.http.post(API.ORGANIZATION.REJECT_PROFILE(params));
  }

  async approveOrganizationProfile(params) {
    return this.http.post(API.ORGANIZATION.APPROVE_PROFILE(params));
  }

  async getOrganizations(params) {
    return this.http.get(API.ORGANIZATION.BASE(params));
  }

  async getOrganizationsAll(params) {
    return this.http.get(API.ORGANIZATION.BASE_ALL(params));
  }
  async getOrganizationsClients(id) {
    return this.http.get(API.ORGANIZATION.CLIENTS({id}), false);
  }

  async getOrganizationById(id, force = false) {
    return await this.getDictionary(API.ORGANIZATION.ORGANIZATION({ id }), force);
  }

  async updateOrganizationById(id, data) {
    return this.http.put(API.ORGANIZATION.ORGANIZATION({ id }), data);
  }

  async bulkDeleteOrganization(ids) {
    return this.http.post(API.DICTIONARIES.FREIGHT_SURCHARGES, ids);
  }

  async getOrganizationProfiles(id) {
    console.log('getOrganizationProfiles id', id);
    return this.http.get(API.ORGANIZATION.GET_PROFILES({ id }), false);
  }

  async getCommission(id, cargoType, force = false) {
    return this.http.get(API.COMMISSION.COMMISSION({ id, cargoType }), force);
  }

  async createCommission(data) {
    return this.http.post(API.COMMISSION.CREATE_COMMISSION, data);
  }

  async updateCommission(id, data) {
    return this.http.put(API.COMMISSION.UPDATE_COMMISSION({ id }), data);
  }

  async getCommissionIntegrator(cargoType, force = false) {
    return this.http.get(API.COMMISSION.COMMISSION_INTEGRATOR({ cargoType }), force);
  }

  async createCommissionIntegrator(data) {
    return this.http.post(API.COMMISSION.CREATE_COMMISSION_INTEGRATOR, data);
  }

  async updateCommissionIntegrator(id, data) {
    return this.http.put(API.COMMISSION.UPDATE_COMMISSION_INTEGRATOR({ id }), data);
  }

  async getExpiredPortTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_PORT_TARIFFS({ id }), false);
  }

  async getExpiredRoadTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_ROAD_TARIFFS({ id }), false);
  }

  async getExpiredSeaTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_SEA_TARIFFS({ id }), false);
  }

  async getExpiredSurchargesTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_SURCHARGES_TARIFFS({ id }), false);
  }

  async getExpiredAirTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_AIR_TARIFFS({ id }), false);
  }

  async getExpiredAirportTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_AIRPORT_TARIFFS({ id }), false);
  }

  async getExpiredRailwayTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_RAILWAY_TARIFFS({ id }), false);
  }
 /* async getExpiredComplexTariff(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_COMPLEX_TARIFF({ id }), false);
  }*/

  async getExpiredRailwayStationTariffs(id) {
    return this.http.get(API.ORGANIZATION.GET_EXPIRED_RAILWAY_STATION_TARIFFS({ id }), false);
  }

  async getExpiredTariffs(id) {
    const [
      expiredSeaTariffsCount,
      expiredSurchargesTariffsCount,
      expiredPortTariffsCount,
      expiredRoadTariffsCount,
      expiredAirTariffsCount,
      expiredAirportTariffsCount,
      expiredRailwayTariffsCount,
      expiredRailwayStationTariffs,
      //expiredComplexTariffs,
    ] = await Promise.all([
      this.getExpiredSeaTariffs(id),
      this.getExpiredSurchargesTariffs(id),
      this.getExpiredPortTariffs(id),
      this.getExpiredRoadTariffs(id),
      this.getExpiredAirTariffs(id),
      this.getExpiredAirportTariffs(id),
      this.getExpiredRailwayTariffs(id),
      this.getExpiredRailwayStationTariffs(id),
      //this.getExpiredComplexTariff(id),
    ]);

    const parseCount = (count) => {
      return count.status === 'error' ? 0 : count;
    };

    return [{
      name: 'sea',
      count: parseCount(expiredSeaTariffsCount),
    }, {
      name: 'port-services',
      count: parseCount(expiredPortTariffsCount),
    }, {
      name: 'freight-surcharges',
      count: parseCount(expiredSurchargesTariffsCount),
    }, {
      name: 'road-transportation',
      count: parseCount(expiredRoadTariffsCount),
    }, {
      name: 'air-transportation',
      count: parseCount(expiredAirTariffsCount),
    }, {
      name: 'airport-services',
      count: parseCount(expiredAirportTariffsCount),
    }, {
      name: 'railway-transportation',
      count: parseCount(expiredRailwayTariffsCount),
    }, {
      name: 'railway-services',
      count: parseCount(expiredRailwayStationTariffs),
    }];
      /*{
        name: 'complex-Tariff',
        count:parseCount(expiredComplexTariffs),
      }];*/
  }

  async getBankDetails(id){
    return this.http.get(API.ORGANIZATION.BANK_DETAILS.GET_BY_ORGANIZATION({id}), false)
  }

  async saveBankDetails(data){
    if(data.id)
      return this.http.put(API.ORGANIZATION.BANK_DETAILS.UPDATE({id:data.id}), data)
    else
      return this.http.post(API.ORGANIZATION.BANK_DETAILS.CREATE(), data)
  }

  async getDictionary(url, force) {
    return await this.http.get(url, force).then(res => res.data);
  }

  async getAllExternal(id){
    return this.http.get(API.ORGANIZATION.GET_ALL_EXTERNAL({id}), false)
  }

  async getAllExternalAgora(id){
    return this.http.get(API.ORGANIZATION.GET_ALL_EXTERNAL_AGORA({id}), false)
  }

  async getOrganizationParentDomain(parentId) {
    return this.http.get(API.ORGANIZATION.GET_PARENT_DOMAIN({id: parentId}), false);
  }

  async checkDomain(domain) {
    return this.http.get(API.ORGANIZATION.CHECK_DOMAIN({domain}), false)
  }


  async updateSettingsExternalOrganizationById(id, newData) {
    return this.http.put(API.ORGANIZATION.UPDATE_FULL({id}), newData)
  }

  async getOrganizationSettingsApi(id){
    return this.http.get(API.ORGANIZATION.SETTINGS_API({id}), false)
  }

  async getCountryCodesWizard(domain) {
    return this.http.get(API.ORGANIZATION.GET_COUNTRY_CODES_WIZARD({domain}), false)
  }

  async isEnabledCustomClearanceWizard(domain=undefined){
    return this.http.get(API.ORGANIZATION.IS_ENABLED_CUSTOM_CLEARANCE_WIZARD({domain}), false)
  }
  async isEnabledInsuranceFormWizard(domain=undefined){
    return this.http.get(API.ORGANIZATION.IS_ENABLED_INSURANCE_FORM_WIZARD({domain}), false)
  }

  async updateOrganizationProfiles(data, id){
    return this.http.post(API.ORGANIZATION.UPDATE_PROFILES({id}), {profiles:data})
  }
}

export default new OrganizationService();
