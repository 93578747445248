/* eslint-disable */
import React, {Component, Fragment} from 'react';
import {Modal} from "./Modal";
import {get, isEmpty, cloneDeep, isEqual, set} from 'lodash';
import {
    addFormatName,
    copyToBuffer,
    getExistLangValue,
    getLangInStorage,
    getWordDeclension,
    translate
} from "../../utils/utils";
import {Button, Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import FontAwesome from "react-fontawesome";
import HttpProvider from '../../services/httpProvider';
import FlashStore from "../../stores/flashStore";
import {API} from "../../constants/api";
import {DOC_TYPES_MAPPINGS, VEHICLE_BODY_TYPE} from "../../constants/staticData";
import {Checkbox, DateInput, TextInput} from "./form-control";
import orderDeliveryService from "../../services/orderDeliveryService";
import {DATE_FORMAT} from "../../constants/global";
import AppStore from "../../stores/appStore";
import {
    checkIsExistFactDate,
    getContainerNumber,
    getIndicator,
    getIndicatorById, setDate,
    setDateFake, shiftPlanDates,
    shiftPlanesDatesFake
} from "../Orders/OrderCommon";
import moment from 'moment';
import PropTypes from "prop-types";
import OrderDeliveryService from "../../services/orderDeliveryService";
import NumberInputInplace from "./form-control/NumberInputInplace";
import CdekService from "../../services/cdekService";
import CdekTracking from "../Orders/partners/cdek/CdekTracking";
import userStore from "../../stores/userStore";

import UserStore from "../../stores/userStore";
import dictionaryService from "../../services/dictionaryService";
import {FlashStatus, ManualFlashStatus} from "../common/Statuses";


export default class LogisticPopupModal extends Component {

    state = {
        item: this.props.item,
        deliveryTrackingInfo: [],
        trackingStatus: this.props.trackingStatus,
        mainDocument: '',
        homeDocument: '',
        indicator: [],
        trackingData: this.props.dataTracking,
        trackingSteps: [],
        cdekSteps: [],
        partner: null,
        activeTab: -1,
        loading: false,
        waiting: true,
        sync: false,
        showModalSync: false
    };

    /*constructor(props) {
        super(props);
        this.setState((prevState, props) => {
            return {
                item: {
                    ...prevState.state.item,
                    trackingInfo: this.calcTransitTimes(this.props.item.trackingInfo),
                },
            }
        })
    }*/

    // используется для изменения длительности плеча
    transitTimeRules = new Map([
        ['pointOfPickup', {from: 'pointOfPickup.departure', to: 'pointOfOrigin.arrival',}],
        ['pointOfOrigin', {from: 'pointOfOrigin.departure', to: 'pointOfArrival.arrival',}],
        ['pointOfArrival', {from: 'pointOfArrival.departure', to: 'pointOfDestination.arrival',}],
    ]);

    updateState(newState, callback = () => {
    }) {
        setTimeout(
            () => {
                this.setState(newState, callback)
            }
        )
    }

    prepareData = (data) => {
        if (data?.orderDeliveryRequestGroup?.cargoInfo?.generals) {
            data.trackingInfoItems = [data.trackingInfoItems[0]];
            this.updateState({
                sync: true,
            })
        }
        return data
    }

    isGeneralCargo = () => {
        return !!this.state.deliveryTrackingInfo?.orderDeliveryRequestGroup?.cargoInfo?.generals;
    }
    isContainerCargo = () => {
        return !!this.state.deliveryTrackingInfo?.orderDeliveryRequestGroup?.cargoInfo?.containers;
    }

    updateFactDate() {
        let id = this.state.item.id;
        OrderDeliveryService.updateTrackingFactDate(id).then(response => {
            if ((response.status === 'success')) {
                FlashStore.successNow(translate({id: 'common.save.success'}), true);
                this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                this.props.onUpdateTrackingDetails(response.data.trackingDetails);
                const statusName = response.data.trackingDetails.lastCompleted ?
                    response.data.trackingDetails.lastCompleted.name : '';
                this.updateState({
                    trackingStatus: statusName,
                })
            }
        });
    }


    componentDidUpdate(prevProps) {
        if (this.props.item !== prevProps.item) {
            this.updateState({
                item: this.props.item
            });
        }
        if (this.props.isShow && !prevProps.isShow) {
            this.getDocuments();
            this.updateState({
                item: {
                    ...this.state.item,
                },
                //indicator: this.props.indicator,
            }, () => this.updateIndicatorActiveTab());
        } else {
            if (!_.isEqual(prevProps.item.createDate, this.props.item.createDate)) {
                this.updateState({
                    item: {
                        ...this.props.item,
                        createDate: this.props.item.createDate,
                    },
                    //indicator: this.props.indicator,
                }, async () => {
                    this.updateState({
                        waiting: true,
                    })
                    await this.getDeliveryTrackingInfo();
                    await this.updateIndicatorActiveTab();
                    this.updateState({
                        waiting: false,
                    }, () => this.props.idCargo === null && this.changeTab(0))
                })
            }
        }
    }

    async getDeliveryTrackingInfo() {
        const {id} = this.state.item;
        const response = (await OrderDeliveryService.getTrackingInfo(id)).data;
        this.setState({
            deliveryTrackingInfo: this.prepareData(response),
        }, () => {
            if (this.props.idCargo !== null && this.props.idCargo !== undefined) {
                this.setState({
                    activeTab: this.state.deliveryTrackingInfo?.trackingInfoItems?.findIndex(item => item.idCargo === this.props.idCargo)
                }, () => this.updateIndicatorActiveTab())
            }

        })
    }

    async changeTab(index = 0) {
        const indicator = await getIndicator(this.state.item?.id, get(this.state.deliveryTrackingInfo, `trackingInfoItems[${index}].status`), get(this.state.deliveryTrackingInfo, `trackingInfoItems[${index}].trackingInfo`));
        this.updateState({
            activeTab: index,
            indicator
        })
    }

    async updateIndicatorActiveTab() {
        const indicator = await getIndicator(null, get(this.state.deliveryTrackingInfo, `trackingInfoItems[${this.state.activeTab}].status`), get(this.state.deliveryTrackingInfo, `trackingInfoItems[${this.state.activeTab}].trackingInfo`));
        this.updateState({
            indicator
        })
    }

    async syncDates() {
        this.updateState({
            sync: !this.state.sync
        }, () => {
            if (this.state.sync === true) {
                this.updateState({
                    loading: true
                }, () => {
                    const trackingInfoItem = get(this.state.deliveryTrackingInfo, `trackingInfoItems[${this.state.activeTab}]`);
                    orderDeliveryService.sendLogisticBlock(this.state.item.id, trackingInfoItem.trackingInfo, trackingInfoItem.idCargo, this.state.sync)
                        .then((response) => {
                            if ((response.status === 'success')) {
                                this.updateState({
                                    deliveryTrackingInfo: cloneDeep(response.data),
                                    loading: false
                                }, () => this.updateIndicatorActiveTab())
                                FlashStore.successNow(translate({id: 'common.save.success'}), true);

                            } else {
                                FlashStore.errorNow(translate({id: 'tracking.fields.error.update'}), true);
                                this.updateState({
                                    loading: false
                                })
                            }
                        });
                })
            }
        })
    }

    async componentWillMount() {
        await this.getCdekStatuses()
        await this.getDeliveryTrackingInfo()
        this.updateState({
            waiting: false,
        }, () => this.props.idCargo === null && this.changeTab(0))
    }

    getContainerNumber = () => {
        return getContainerNumber(this.state.item);
    };

    getCode = (items, type) => {
        const code = DOC_TYPES_MAPPINGS[type];
        if (code && items) {
            return items.filter(item => item.kind && item.kind.uid === code);
        }

        return null;
    };

    fillDocumentTitle = (docTypeitem, items, docToSave) => {
        if (docTypeitem) {
            const {typeDescription} = docTypeitem;
            const transportDoc = this.getCode(items, typeDescription);

            if (!isEmpty(transportDoc) && this.state.item.documentContainer) {
                this.updateState({
                    [docToSave]: transportDoc[0].title,
                });
            } else {
                this.updateState({
                    [docToSave]: '',
                });
            }
        }

    }

    getCdekStatuses = async () => {
        const {id} = this.state.item;
        await OrderDeliveryService.getRequestGroup(id)
            .then((response) => {
                if (isEmpty(response.data)) {
                    return;
                }
                this.setState(prevState => {
                    // CRBR-282 определяем есть ли плечи СДЭК
                    const pathParts = get(response.data, 'orderCalculation.pathParts', []);
                    let partner = prevState?.partner;
                    pathParts.forEach(item => {
                        if (get(item, 'partner.name', '') === 'CDEK') {
                            partner = {name: 'CDEK', data: {}}; // плечи есть
                            item.services.forEach(s => {
                                const [prefix, code] = s.service.uid.split('-');
                                if (prefix === 'cdek') {
                                    set(partner, `data.id`, response.data?.id);
                                    set(partner, `data.tariffCode`, code);
                                    set(partner, `data.tariffName`, s.service.name);
                                    set(partner, `data.serviceUi`, s.service.uid);
                                    set(partner, `data.serviceId`, s.service.id);
                                    set(partner, `data.cargoDescription `, get(response.data, 'requestDetails.cargoDescription', "Коробка"));
                                }
                            });
                        }
                    });
                    return {...prevState, requestGroup: response.data, partner: partner}
                }, async () => {
                    if (get(this.state, "partner.data", false)) {
                        await this.getOrderInfo(this.state?.partner?.data);
                    }
                });
            });
    }

    getOrderInfo = async (data) => {
        CdekService.getOrderInfo(data.id, data.tariffCode).then(orderInfoResult => {
            try {
                if (orderInfoResult.code && orderInfoResult.code === 200) {
                    const getOrderResult = orderInfoResult.data;
                    CdekService.getEventsByUUID(getOrderResult.uuid).then(res => {
                        if (res.code === 200) {
                            this.updateState({
                                cdekSteps: res.data
                            })
                        }
                    })
                } else {
                }
            } catch (e) {
            }
        })
    }

    getDocuments = () => {
        const {id} = this.state.item;
        const crudEndpoint = `documents/docs/${id}`;
        const {item} = this.state;

        HttpProvider.get(API.BASE(`${crudEndpoint}`), false).then(
            (response) => {
                if (!response) {
                    return;
                }

                this.fillDocumentTitle(item.documentContainer.mainDocument, response.data.items, 'mainDocument');
                this.fillDocumentTitle(item.documentContainer.homeDocument, response.data.items, 'homeDocument');
            }
        );
    };

    sendStatus = (idCargo, manualStatus) => {
        orderDeliveryService.sendStatus(this.state.item.id, idCargo, manualStatus)
            .then((response) => {
                if (response.status === 'success') {
                    FlashStore.successNow(translate({id: 'common.save.success'}), true);
                } else {
                    FlashStore.errorNow(translate({id: 'common.documents.error.fieldsWithoutFile'}), true);
                }
            });
    };

    onChangeStatus = (trackingInfo, index, value) => {
        this.updateState({
            loading: true
        })
        const newState = {...this.state.deliveryTrackingInfo}
        set(newState, `trackingInfoItems[${index}].manualStatus`, value);
        this.updateState({
            deliveryTrackingInfo: newState,
            loading: false
        });
    };

    /**
     * Checks if a fact date exists for a given block and direction.
     *
     * @param {string} block - The block identifier.
     * @param {string} direction - The direction identifier.
     * @param {string} type - The type of tracking info.
     * @return {boolean} - Returns true if a fact date exists, false otherwise.
     */
    isFactDateExists(block, direction, type) {
        if (type === 'plan') {
            const factDate = get(this.state.item.trackingInfo, `${block}.${direction}.fact`, null)
            if (factDate !== null) {
                if (factDate.length > 0)
                    return true
            }
        }
        return false;
    }

    isFactDateFakeExists(transitPoint, direction, type) {
        if (type === 'plan') {
            const factDate = get(transitPoint, `${direction}.fact`, null)
            if (factDate !== null) {
                if (factDate.length > 0)
                    return true
            }
        }
        return false;
    }

    canEditTransitTime(block) {
        const rule = this.transitTimeRules.get(block);
        if (rule) {
            const [toBlock, toDirection] = rule.to.split('.');
            return this.isFactDateExists(toBlock, toDirection, 'plan');
        }
        return false;
    }

    calcTransitTimes = (trackingInfo) => {
        const result = trackingInfo;
        for (const [block, directions] of this.transitTimeRules) {

            if (get(result, `${block}`, undefined) !== undefined) {
                const [fromBlock, fromDirection] = directions.from.split('.');
                const dateFrom = get(trackingInfo, `${fromBlock}.${fromDirection}.plan`, null);
                const [toBlock, toDirection] = directions.to.split('.');
                const dateTo = get(trackingInfo, `${toBlock}.${toDirection}.plan`, null);
                if (dateFrom && dateTo && result[block]) {
                    const delta = moment(dateTo).diff(moment(dateFrom), 'days');
                    result[block].transitTime = delta;
                } else {
                    result[block].transitTime = null;
                }
            }
        }
        return result;
    };

    calcSummaryTransitTime = (trackingInfo) => {
        const start = get(trackingInfo, 'pointOfPickup.ready.plan', null);
        if (start === null) return null;
        let end = get(trackingInfo, 'deliveryToRecipientDate.date.plan', null);
        if (end === null) {
            end = get(trackingInfo, 'pointOfDestination.arrival.plan', null);
        }
        if (end === null) return null;
        if (typeof start === 'undefined' || typeof end === 'undefined') {
            return null;
        }
        console.log('⌚', trackingInfo, start, end)
        return moment(end).diff(moment(start), 'days');
    }

    onChangeTransitTime = (block, transitTime, trackingInfo, index, idCargo) => {
        const rule = this.transitTimeRules.get(block);
        if (rule) {
            const [toBlock, toDirection] = rule.to.split('.');
            const oldTransitTime = get(trackingInfo, `${block}.transitTime`, 0);
            const delta = transitTime - oldTransitTime;
            const oldPlanDate = get(trackingInfo, `${toBlock}.${toDirection}.plan`, null);
            const newPlanDate = moment(oldPlanDate).add(delta, 'days').format('YYYY-MM-DD');
            const newState = shiftPlanDates(newPlanDate, oldPlanDate, toBlock, toDirection, this.state, true, trackingInfo, index, idCargo);
            this.updateState(newState, () => this.changeDateCallback(trackingInfo, index, idCargo));
        }
    }

    changeDateCallback = (trackingInfo, index, idCargo) => {
        this.updateState({
            loading: true
        }, () => {
            orderDeliveryService.sendLogisticBlock(this.state.item.id, trackingInfo, idCargo, this.state.sync)
                .then((response) => {
                    if ((response.status === 'success')) {
                        this.props.onUpdateTrackingInfo(response.data.trackingInfoItems[index].trackingInfo);
                        this.updateState({
                            deliveryTrackingInfo: this.prepareData(response.data),
                            loading: false
                        }, () => this.updateIndicatorActiveTab())
                        //this.updateDateFields(response.data);
                        FlashStore.successNow(translate({id: 'common.save.success'}), true);

                    } else {
                        FlashStore.errorNow(translate({id: 'tracking.fields.error.update'}), true);
                        this.updateState({
                            loading: false
                        })
                    }
                });
        })

    }

    onChangeDate = (value, block, direction, type, trackingInfo, index, idCargo) => {
        if (!this.isFactDateExists(block, direction, type)) {
            let newState = {};

            if (value === undefined || value === null || value?.length === 0) {
                newState = setDate(value, block, direction, type, this.state, trackingInfo, index, idCargo);
                this.updateState(newState, () => this.changeDateCallback(trackingInfo, index, idCargo));
            } else {
                if (type === "fact") {
                    const dateReadyPlan = new Date(trackingInfo.pointOfPickup.ready?.plan);
                    const dateValue = new Date(value)
                    let delta = dateValue.getTime() - dateReadyPlan.getTime();
                    if (delta >= 0) {
                        newState = setDate(value, block, direction, type, this.state, trackingInfo, index, idCargo);
                        this.updateState(newState, () => this.changeDateCallback(trackingInfo, index, idCargo));
                    } else {
                        FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
                    }

                } else {
                    newState = setDate(value, block, direction, type, this.state, trackingInfo, index, idCargo);
                }
                if (type === 'plan' && trackingInfo[block][direction][type]) {
                    newState = shiftPlanDates(value, trackingInfo[block][direction][type], block, direction, newState, trackingInfo, index, idCargo);
                    this.updateState(newState, () => this.changeDateCallback(trackingInfo, index, idCargo));
                }
            }
        } else {
            FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
        }
    };

    onChangeDateFake = (value, indexCargo, direction, type, idCargo, transitPoint, indexTransitPoint) => {
        if (!this.isFactDateFakeExists(transitPoint, direction, type)) {
            let newState = {};
            let trackingInfo = cloneDeep(this.state.deliveryTrackingInfo.trackingInfoItems[indexCargo].trackingInfo);
            if (value === undefined || value === null || value?.length === 0) {
                newState = setDateFake(value, direction, type, this.state, indexCargo, indexTransitPoint, idCargo);
                this.updateState(newState, () => this.changeDateCallback(this.state.deliveryTrackingInfo.trackingInfoItems[indexCargo].trackingInfo, indexCargo, idCargo));
            } else {
                if (type === "fact") {
                    const trackingInfo = this.state.deliveryTrackingInfo.trackingInfoItems[indexCargo].trackingInfo;
                    const dateReadyPlan = new Date(trackingInfo?.transitPoints[0]?.ready?.plan);
                    const dateValue = new Date(value)
                    let delta = dateValue.getTime() - dateReadyPlan.getTime();
                    if (delta >= 0) {
                        newState = setDateFake(value, direction, type, this.state, indexCargo, indexTransitPoint, idCargo);
                        this.updateState(newState, () => this.changeDateCallback(this.state.deliveryTrackingInfo.trackingInfoItems[indexCargo].trackingInfo, indexCargo, idCargo));
                    } else {
                        FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
                    }

                } else {
                    newState = setDateFake(value, direction, type, this.state, indexCargo, indexTransitPoint, idCargo);
                    if (get(trackingInfo, `transitPoints[${indexTransitPoint}].${direction}.${type}`) === undefined)
                        trackingInfo = cloneDeep(newState.deliveryTrackingInfo.trackingInfoItems[indexCargo].trackingInfo);
                }

                if (type === 'plan' && get(trackingInfo, `transitPoints[${indexTransitPoint}].${direction}.${type}`)) {
                    newState = shiftPlanesDatesFake(value, get(trackingInfo, `transitPoints[${indexTransitPoint}].${direction}.${type}`), direction, type, newState, false, trackingInfo, indexCargo, indexTransitPoint, idCargo);
                    this.updateState(newState, () => this.changeDateCallback(this.state.deliveryTrackingInfo.trackingInfoItems[indexCargo].trackingInfo, indexCargo, idCargo));
                }
            }
        } else {
            FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
        }
    };

    onChangeSingleDateFake = (value, indexTransitPoint, indexCargo, idCargo) => {
        let newState = {};
        newState = setDateFake(value, undefined, undefined, this.state, indexCargo, indexTransitPoint, idCargo);
        this.updateState(newState, () => this.changeDateCallback(this.state.deliveryTrackingInfo.trackingInfoItems[indexCargo].trackingInfo, indexCargo, idCargo));
    }


    onUpdateTrackingInfoShiftDateTransitPoint = (pointOfPickupReady, name, idCargo) => {
        if (name === "pointOfPickup.ready.plan") {
            let id = this.state.item.id;
            let datePlan = new Date(pointOfPickupReady.plan);
            let orderDate = new Date(this.state.item.createDate.split(' ')[0]);
            let delta = datePlan.getTime() - orderDate.getTime();
            if (delta >= 0) {
                this.updateState({
                    loading: true
                }, () => {
                    OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady, idCargo, this.state.sync).then(response => {
                        if ((response.status === 'success')) {
                            FlashStore.successNow(translate({id: 'common.save.success'}), true);
                            this.updateState({
                                deliveryTrackingInfo: this.prepareData(response.data),
                                loading: false
                            }, () => this.updateIndicatorActiveTab())
                            this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                        } else {
                            this.updateState({
                                loading: false
                            })
                        }
                    });
                })

            } else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.datePlanError'}), true);
            }
        }
        if (name === "pointOfPickup.ready.fact") {
            let id = this.state.item.id;
            let datePlan = new Date(pointOfPickupReady.plan);
            let dateFact = new Date(pointOfPickupReady.fact);
            let delta = dateFact.getTime() - datePlan.getTime();
            if (pointOfPickupReady.fact === '' || delta >= 0) {
                this.updateState({
                    loading: true
                }, () => {
                    OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady, idCargo, this.state.sync).then(response => {
                        if ((response.status === 'success')) {
                            FlashStore.successNow(translate({id: 'common.save.success'}), true);
                            this.updateState({
                                deliveryTrackingInfo: this.prepareData(response.data),
                                loading: false
                            }, () => this.updateIndicatorActiveTab())
                            this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                        } else {
                            this.updateState({
                                loading: false
                            })
                        }
                    });
                })
            } else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
            }
        }

    }

    onUpdateTrackingInfoShiftDate = (pointOfPickupReady, name, trackingInfo, index, idCargo) => {
        if (name === "pointOfPickup.ready.plan") {
            let id = this.state.item.id;
            let datePlan = new Date(pointOfPickupReady.plan);
            let orderDate = new Date(this.state.item.createDate.split(' ')[0]);
            let delta = datePlan.getTime() - orderDate.getTime();
            if (delta >= 0) {
                this.updateState({
                    loading: true
                }, () => {
                    OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady, idCargo, this.state.sync).then(response => {
                        if ((response.status === 'success')) {
                            FlashStore.successNow(translate({id: 'common.save.success'}), true);
                            this.updateState({
                                deliveryTrackingInfo: this.prepareData(response.data),
                                loading: false
                            }, () => this.updateIndicatorActiveTab())
                            this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                        } else {
                            this.updateState({
                                loading: false
                            })
                        }
                    });
                })

            } else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.datePlanError'}), true);
            }
        }
        if (name === "pointOfPickup.ready.fact") {
            let id = this.state.item.id;
            let datePlan = new Date(pointOfPickupReady.plan);
            let dateFact = new Date(pointOfPickupReady.fact);
            let delta = dateFact.getTime() - datePlan.getTime();
            if (pointOfPickupReady.fact === '' || delta >= 0) {
                this.updateState({
                    loading: true
                }, () => {
                    OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady, idCargo, this.state.sync).then(response => {
                        if ((response.status === 'success')) {
                            FlashStore.successNow(translate({id: 'common.save.success'}), true);
                            this.updateState({
                                deliveryTrackingInfo: this.prepareData(response.data),
                                loading: false
                            }, () => this.updateIndicatorActiveTab())
                            this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                        } else {
                            this.updateState({
                                loading: false
                            })
                        }
                    });
                })
            } else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
            }
        }

    }

    onChangeSingleDate = (value, block, trackingInfo, index, idCargo) => {
        const newState = setDate(value, block, false, false, this.state, trackingInfo, index, idCargo);
        this.updateState(newState, () => this.changeDateCallback(trackingInfo, index, idCargo));
    };

    onChangeDoubleDate = (value, block, type, trackingInfo, index, idCargo) => {
        let newState = {}
        if (type === "fact") {
            const dateReadyPlan = new Date(trackingInfo.pointOfPickup.ready?.plan);
            const dateValue = new Date(value)
            let delta = dateValue.getTime() - dateReadyPlan.getTime();
            if (value === null || delta >= 0) {
                newState = setDate(value, block, false, type, this.state, trackingInfo, index, idCargo);
                this.updateState(newState, () => this.changeDateCallback(trackingInfo, index, idCargo));
            } else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.update'}), true);
            }

        } else {
            newState = setDate(value, block, false, type, this.state, trackingInfo, index, idCargo);
            this.updateState(newState, () => this.changeDateCallback(trackingInfo, index, idCargo));
        }

    };

    updateDateFields = (data, index) => {
        const newState = {...this.state.deliveryTrackingInfo}
        set(newState, `trackingInfoItems[${index}].trackingInfo`, this.calcTransitTimes(get(data, `trackingInfoItems[${index}].trackingInfo`)))
        this.updateState({
            deliveryTrackingInfo: newState
        });
    }

    disableDateRow = (block, direction, trackingInfo, index, idCargo) => {
        const newState = {
            ...this.state.deliveryTrackingInfo
        }
        set(newState, `trackingInfoItems[${index}].trackingInfo.${block}.${direction}.enabled`, !trackingInfo[block][direction].enabled)
        this.updateState({
            deliveryTrackingInfo: newState,
        }, () => {
            orderDeliveryService.sendLogisticBlock(this.state.item.id, trackingInfo, idCargo)
                .then((response) => {
                    if ((response.status === 'success')) {
                        FlashStore.successNow(translate({id: 'common.save.success'}), true);
                    } else {
                        FlashStore.errorNow(translate({id: 'common.documents.error.fieldsWithoutFile'}), true);
                    }
                });
        });
    };

    disableDateFakeRow = (indexCargo, type, idCargo, transitPoint, indexTransitPoint) => {
        const newState = {
            ...this.state.deliveryTrackingInfo
        }
        set(newState, `trackingInfoItems[${indexCargo}].trackingInfo.transitPoints[${indexTransitPoint}].${type}.enabled`, !(get(transitPoint, `${type}`)?.enabled))
        this.updateState({
            deliveryTrackingInfo: newState,
        }, () => {
            orderDeliveryService.sendLogisticBlock(this.state.item.id, get(newState, `trackingInfoItems[${indexCargo}].trackingInfo`), idCargo)
                .then((response) => {
                    if ((response.status === 'success')) {
                        FlashStore.successNow(translate({id: 'common.save.success'}), true);
                    } else {
                        FlashStore.errorNow(translate({id: 'common.documents.error.fieldsWithoutFile'}), true);
                    }
                });
        });
    };

    checkIsExistFactDateTransitPoint = (name, transitPoint, arrayPoints, indexCargo, indexTransitPoint) => {
        const date = get(transitPoint, `${name}.fact`, null)
        for(let i=indexTransitPoint; i<=arrayPoints.length; i++){
            let index = i===0?i:i-1
            const names = ["arrival", "receiptOfDocuments", "departure", '-' ]
            let filteredNames = cloneDeep(names);
            if(indexTransitPoint===i){
                filteredNames = filteredNames.slice(names.indexOf(name), names.length)
            }
            const transitPointItem = arrayPoints[index];
            for (const name of filteredNames) {
                const date = get(transitPointItem, `${name}.fact`,null);
                if(date!==null){
                    if (date.length > 0)
                        return true
                }
            }
        }
        if (date !== null) {
            if (date.length > 0)
                return true
        }
        return false
    }

    render() {
        const {isShow, onClose, isModal} = this.props;
        const {mainDocument, homeDocument} = this.state;
        const usedIdCargoBtnBlock = [];
        const usedIdCargo = [];

        const getFormatNameCargoBtn = (value) => {
            const cargoInfo = this.state.item?.cargoInfo;
            const cargo = (cargoInfo?.containers ? cargoInfo.containers : cargoInfo?.generals ? cargoInfo.generals : [cargoInfo.vehicle]).filter(cargo => cargo.idCargo === value?.idCargo)[0]
            if (cargoInfo?.containers) {
                return `${cargo?.containerId ? cargo.containerId : "-"} - ${cargo?.type?.formatName}`
            }
            if (cargoInfo?.generals) {
                let name = `-`
                if (this.state.item.awbNumber)
                    name = `${this.state.item.awbNumber}`
                if (this.state.item.containerNumber)
                    name = `${this.state.item.containerNumber}`
                return name
                if (cargo?.typeId !== undefined) {
                    const typeId = {
                        1: "cargoType.box",
                        2: "cargoType.pallet",
                        3: "cargoType.cube"
                    }
                    let formatSize = `${cargo?.length ? cargo?.length : '-'} x ${cargo?.width ? cargo?.width : '-'} x ${cargo?.height ? cargo?.height : '-'} (${cargo?.sizeUnit}) - ${cargo?.weight} ${cargo?.weightUnit}`
                    if (cargo.typeId === 3) {
                        formatSize = `${cargo.cubeVolume ? cargo.cubeVolume : "-"} ${cargo.sizeUnit} - ${cargo?.weight} ${cargo?.weightUnit}`
                    }
                    return `${translate({id: `${typeId[cargo.typeId]}`})} - ${cargo?.amount} ${translate({id: "detailsOfCalculation.pieces"})} - ${formatSize}`
                }
            }
            if (cargoInfo?.vehicle) {
                const bodyType = VEHICLE_BODY_TYPE.find(v => v.value === cargo?.bodyType)?.message
                return `${translate({id: `cargoType.car`})} - ${cargo?.type?.name ? getExistLangValue(cargo?.type?.name) : ''} - ${translate({id: `constants.${bodyType}`})}`
            }
            return "-"
        }

        const docsBlock = (<Fragment>
            <p style={{fontSize: '20px'}}><b><FormattedMessage id="common.documents"/></b></p>
            <Row>
                <Col xs={12} md={6}>
                    {this.state.item.documentContainer.mainDocument &&
                        <div style={{display: 'inline-flex'}}>
                            <p className="label" style={{minWidth: '140px'}}>
                                <b>
                                    <FormattedMessage
                                        id={`${this.state.item.documentContainer.mainDocument.typeDescription}`}/>
                                </b>
                                <br/>
                                {this.state.item.documentContainer.mainDocument ? this.state.mainDocument
                                    : 'N/A '}
                            </p>
                            <FontAwesome
                                name="copy"
                                style={{marginTop: '8px', marginLeft: '10px', cursor: 'pointer'}}
                                onClick={() =>
                                    copyToBuffer(mainDocument ?
                                        mainDocument : 'N/A'
                                    )}
                            />
                        </div>
                    }
                    {this.state.item.documentContainer.homeDocument &&
                        <div style={{display: 'inline-flex'}}>
                            <p className="label" style={{minWidth: '140px'}}>
                                <b>
                                    <FormattedMessage
                                        id={`${this.state.item.documentContainer.homeDocument.typeDescription}`}/>
                                </b>
                                <br/>
                                {this.state.item.documentContainer.homeDocument ? this.state.homeDocument : 'N/A'}
                            </p>
                            <FontAwesome
                                name="copy"
                                style={{marginTop: '8px', marginLeft: '10px', cursor: 'pointer'}}
                                onClick={() => this.copyToBuffer(homeDocument ? homeDocument : 'N/A')}/>
                        </div>
                    }
                    {/* {this.state.item.transportDocument && this.state.item.transportDocument.railwayNumber &&
                            // <div style={{ display: 'flex', alignItems: 'center' }}>
                            //   <p className="label" style={{ minWidth: '140px' }} ><b><FormattedMessage id="RAILWAY" /></b><br />{this.state.item.transportDocument.railwayNumber}</p>
                            //   <FontAwesome name="copy" style={{ marginTop: '8px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.copyToBuffer(this.state.item.transportDocument.railwayNumber)} />
                            // </div>} */}
                </Col>
                {(this.getContainerNumber() !== 'N/A') &&
                    <Col xs={12} md={6}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <p className="label" style={{minWidth: '140px'}}><b><FormattedMessage
                                id="orderForm.numberOfFirstContainer"/></b><br/>{this.getContainerNumber()}</p>
                            <FontAwesome name="copy" style={{marginTop: '8px', marginLeft: '10px', cursor: 'pointer'}}
                                         onClick={() => this.copyToBuffer(this.getContainerNumber())}/>
                        </div>
                    </Col>
                }
            </Row>
        </Fragment>);

        // заголовок для каждого блока в форме
        const trackingBlockTitle = (title, label1, label2) => (
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                <p className="tracking-block-title">
                    {title}
                </p>
                <div className="tracking-item-date">
                    <div className="input-group tracking-block-label">{label1}</div>
                </div>
                <div className="tracking-item-date">
                    <div className="input-group tracking-block-label">{label2}</div>
                </div>
                <span className="fa"></span>
            </div>);


        const trackingInfoPath = (steps = {
            type: 0,
            isCurrent: false,
            isArchive: false,
            isLast: false
        }, trackingInfo, index, idCargo) => {
            const icons = [
                {type: 0, icon: "point"},
                {type: STAGES.pointOfPickup, icon: "point"},
                {type: STAGES.invoicePaymentDate, icon: "payment"},
                {type: STAGES.invoiceDate, icon: "payment"},
                {type: STAGES.pointOfOrigin, icon: "point"},
                {type: STAGES.pointOfArrival, icon: "point"},
                {type: STAGES.customsClearance, icon: "point"},
                {type: STAGES.pointOfDestination, icon: "point"},
                {type: STAGES.deliveryToRecipientDate, icon: "calendar"}
            ]
            return (<div className="tracking-modal-row__icon-container">
                    <div className={steps.isCurrent === true ? 'icon current' : 'icon'}>
                        <div className={'icon-data'}>
                            <div
                                className={steps.isArchive === true ? `${icons.find(value => value.type === steps.type).icon} active` : `${icons.find(value => value.type === steps.type).icon}`}></div>
                        </div>
                    </div>
                    {steps.isLast === false &&
                        <div className={steps.isArchive === true ? 'bottom-line active' : 'bottom-line'}></div>
                    }
                </div>
            )
        }

        const trackingInfoPathTransitPoint = (itemTransitPoint) => {
            const icons = [
                {type: 0, icon: "point"},
                {type: "POINT_OF_PICKUP", icon: "point"},
                {type: "INVOICE", icon: "payment"},
                {type: "POINT_OF_ORIGIN", icon: "point"},
                {type: "POINT_OF_TRANSIT", icon: "point"},
                {type: "POINT_OF_ARRIVAL", icon: "point"},
                {type: "POINT_OF_CUSTOMS_CLEARANCE", icon: "point"},
                {type: "POINT_OF_DESTINATION", icon: "point"},
                {type: "POINT_OF_DELIVERY_TO_RECIPIENT", icon: "calendar"}
            ]
            return (<div className="tracking-modal-row__icon-container">
                    <div className={itemTransitPoint.isCurrent === true ? 'icon current' : 'icon'}>
                        <div className={'icon-data'}>
                            <div
                                className={itemTransitPoint.isArchive === true ? `${icons.find(value => value.type === itemTransitPoint.typeTracking)?.icon} active` : `${icons.find(value => value.type === itemTransitPoint.typeTracking)?.icon}`}></div>
                        </div>
                    </div>
                    {itemTransitPoint.isLast === false &&
                        <div
                            className={itemTransitPoint.isArchive === true ? 'bottom-line active' : 'bottom-line'}></div>
                    }
                </div>
            )
        }


        const STAGES = {
            pointOfPickup: 1,
            invoicePaymentDate: 2,
            invoiceDate: 2,
            pointOfOrigin: 3,
            pointOfArrival: 4,
            customsClearance: 5,
            pointOfDestination: 6,
            deliveryToRecipientDate: 7
        }

        const prepareArraySteps = (trackingInfo) => {
            let result = []
            if (trackingInfo) {
                if (trackingInfo.pointOfPickup) {
                    let item = {
                        id: STAGES.pointOfPickup,
                        value: false
                    }
                    if (trackingInfo.pointOfPickup.departure && trackingInfo.pointOfPickup.departure.fact) {
                        item.value = true
                    }
                    result.push(item)
                }
                if (trackingInfo.invoicePaymentDate) {
                    let item = {
                        id: STAGES.invoicePaymentDate,
                        value: false
                    }
                    if (((trackingInfo.invoicePaymentDate.date && trackingInfo.invoicePaymentDate.date.date)
                        ? trackingInfo.invoicePaymentDate.date.date : 0) !== 0
                    ) {
                        item.value = true
                    }
                    result.push(item)
                }
                if (trackingInfo.pointOfOrigin) {
                    let item = {
                        id: STAGES.pointOfOrigin,
                        value: false
                    }
                    if (trackingInfo.pointOfOrigin.departure.fact) {
                        item.value = true
                    }
                    result.push(item)
                }
                if (trackingInfo.pointOfArrival) {
                    let item = {
                        id: STAGES.pointOfArrival,
                        value: false
                    }
                    if (trackingInfo.pointOfArrival.departure.fact)
                        item.value = true
                    result.push(item)
                }
                if (trackingInfo.customsClearance) {
                    let item = {
                        id: STAGES.customsClearance,
                        value: false
                    }
                    if (trackingInfo.customsClearance.departure.fact)
                        item.value = true
                    result.push(item)
                }
                if (trackingInfo.pointOfDestination) {
                    let item = {
                        id: STAGES.pointOfDestination,
                        value: false
                    }
                    if (trackingInfo.pointOfDestination.arrival.fact)
                        item.value = true
                    result.push(item)
                }
                if (trackingInfo.deliveryToRecipientDate) {
                    let item = {
                        id: STAGES.deliveryToRecipientDate,
                        value: false
                    }
                    if (trackingInfo.deliveryToRecipientDate.date && trackingInfo.deliveryToRecipientDate.date.fact)
                        item.value = true
                    result.push(item)
                }
            }
            return result
        }

        const setAdditionalData = (array) => {
            const now = moment.now();
            for (let transitPoint of array) {
                set(transitPoint, `isCurrent`, false)
                set(transitPoint, `isArchive`, false)
                set(transitPoint, `isLast`, array.indexOf(transitPoint) === array.length - 1)
                if (transitPoint.typeTracking === "POINT_OF_PICKUP") {
                    if (transitPoint?.departure?.fact && moment(transitPoint?.departure?.fact).isBefore(now)) {
                        set(transitPoint, `isCurrent`, false)
                        set(transitPoint, `isArchive`, true)
                    }
                } else if (transitPoint.typeTracking === "INVOICE") {
                    if (transitPoint.data[1]?.singleDate) {
                        set(transitPoint, `isCurrent`, false)
                        set(transitPoint, `isArchive`, true)
                    }
                } else if (transitPoint.typeTracking === "POINT_OF_TRANSIT" || transitPoint.typeTracking === "POINT_OF_ARRIVAL" || transitPoint.typeTracking === "POINT_OF_CUSTOMS_CLEARANCE") {
                    if (transitPoint?.departure?.fact && moment(transitPoint?.departure?.fact).isBefore(now)) {
                        set(transitPoint, `isCurrent`, false)
                        set(transitPoint, `isArchive`, true)
                    }
                } else if (transitPoint.typeTracking === "POINT_OF_DESTINATION") {
                    if (!transitPoint?.departure && transitPoint?.arrival?.fact && moment(transitPoint?.arrival?.fact).isBefore(now)) {
                        set(transitPoint, `isCurrent`, false)
                        set(transitPoint, `isArchive`, true)
                    }else if(transitPoint?.departure?.fact && moment(transitPoint?.departure?.fact).isBefore(now)){
                        set(transitPoint, `isCurrent`, false)
                        set(transitPoint, `isArchive`, true)
                    }
                } else if (transitPoint.typeTracking === "POINT_OF_DELIVERY_TO_RECIPIENT") {
                    if (transitPoint?.arrival?.fact && moment(transitPoint?.arrival?.fact).isBefore(now)) {
                        set(transitPoint, `isCurrent`, false)
                        set(transitPoint, `isArchive`, true)
                    }
                } else {

                }
            }
            let indexCurrent = -1;
            for (let transitPoint of array) {
                const index = array.indexOf(transitPoint)
                if (transitPoint.isArchive === false) {
                    if (index > 0) {
                        if (array[index - 1].isArchive === true) {
                            set(transitPoint, `isCurrent`, true)
                            indexCurrent = index
                            break;
                        }
                    } else {
                        set(transitPoint, `isCurrent`, true)
                        indexCurrent = index
                        break;
                    }
                }
            }
            if (indexCurrent === -1 && array[array.length - 1].isArchive === true)
                indexCurrent = array.length;
            for (let transitPoint of array) {
                const index = array.indexOf(transitPoint)
                if (index > indexCurrent) {
                    set(transitPoint, `isCurrent`, false)
                    set(transitPoint, `isArchive`, false)
                }
            }
        }

        const prepareTrackingSteps = (type, trackingInfo) => {
            const stepsArray = prepareArraySteps(trackingInfo)
            let result = {
                type: 0,
                isCurrent: false,
                isArchive: false,
                isLast: false
            }
            result.type = type

            const currItem = stepsArray.find(value => value.id === type)

            if (currItem === undefined || currItem === null)
                return result

            const indexCurrItem = stepsArray.findIndex(value => value.id === type)

            if (indexCurrItem === stepsArray.length - 1) {
                result.isLast = true
            }

            for (let i = 0; i < indexCurrItem; i++) {
                if (stepsArray[i].value === false)
                    return result
            }

            if (currItem.value === true) {
                if (indexCurrItem > 0) {
                    if (stepsArray[indexCurrItem - 1].value === true) {
                        result.isArchive = true
                    }
                } else {
                    result.isArchive = true
                }
            } else {
                if (indexCurrItem > 0) {
                    if (stepsArray[indexCurrItem - 1].value === true) {
                        result.isCurrent = true
                    }
                } else {
                    result.isCurrent = true
                }
            }

            return result
        }

        const getStatus = (stepData) => {
            let statusStyle = "status" + getClassName4Step(stepData)
            if (stepData.isCurrent === true)
                return (
                    <div className={statusStyle}><FormattedMessage id={"common.status.current"}/></div>
                )
            if (stepData.isArchive === true)
                return (
                    <div className={statusStyle}><FormattedMessage id={"common.status.completed"}/></div>
                )
            return (<div className={"no-status"}></div>)
        }

        const getClassName4Step = (stepData) => {
            if (stepData.isCurrent === true)
                return " current"
            if (stepData.isArchive === true)
                return " completed"
            return " "
        }

        const getNameStep = (transitPoint) => {
            if(transitPoint.typeTracking==="POINT_OF_DELIVERY_TO_RECIPIENT"){
                return <FormattedMessage id={"logistBlock.deliveryToRecipientDate"}/>
            }
            if (transitPoint?.name)
                return (
                    getExistLangValue(transitPoint.name)
                )
            else
                return (
                    <FormattedMessage id={`tracking.field.${transitPoint?.typeTracking}.title`}/>
                )
        }

        const prepareDataArray = (initTransitPoint = []) => {
            let result = [];
            let invoiceArray = [];
            for (let transitPoint of initTransitPoint) {
                if (transitPoint.typeTracking === "POINT_OF_PICKUP") {
                    result.push(transitPoint);
                    continue;
                }
                if (transitPoint.typeTracking === "INVOICE_DATE") {
                    invoiceArray.push(transitPoint);
                    continue;
                }
                if (transitPoint.typeTracking === "INVOICE_PAYMENT_DATE") {
                    invoiceArray.push(transitPoint);
                    result.push({
                        typeTracking: "INVOICE",
                        data: invoiceArray
                    })
                    continue;
                }
                result.push(transitPoint);
            }
            setAdditionalData(result);

            return result;
        }

        const getMobileActions = (transitPoint, indexCargo, indexTransitPoint, idCargo, type, arrayTransitPoints) => {
            return (
                <div className={"actions d-flex d-md-none ml-auto"}>
                    <FontAwesome
                        name={(transitPoint && get(transitPoint, type)?.enabled) ? 'unlock-alt' : 'lock'}
                        onClick={() => this.disableDateFakeRow(indexCargo, type, idCargo, transitPoint, indexTransitPoint)}/>
                    <FontAwesome
                        name={(transitPoint && get(transitPoint, type)?.enabled) ? 'eraser' : 'eraser disabled'}
                        onClick={() => {
                            if ((transitPoint && get(transitPoint, type)?.enabled)) {
                                this.onChangeDateFake('', indexCargo, type, 'fact', idCargo, transitPoint, indexTransitPoint)
                            }
                            if ((!(this.checkIsExistFactDateTransitPoint(type, transitPoint, arrayTransitPoints, indexCargo, indexTransitPoint)) && (transitPoint && get(transitPoint, type)?.enabled))) {
                                this.onChangeDateFake('', indexCargo, type, 'plan', idCargo, transitPoint, indexTransitPoint)
                            }
                        }}/>
                </div>
            )
        }
        const getDesktopActions = (transitPoint, indexCargo, indexTransitPoint, idCargo, type) => {
            return (
                <div className={"actions"}>
                    <FontAwesome
                        name={(transitPoint && get(transitPoint, type)?.enabled) ? 'unlock-alt' : 'lock'}
                        onClick={() => this.disableDateFakeRow(indexCargo, type, idCargo, transitPoint, indexTransitPoint)}/>
                </div>
            )
        }

        const getDateInputPlan = (transitPoint, type, indexCargo, indexTransitPoint, arrayTransitPoints, idCargo) => {
            return (
                <Col xs={12} md={4} className={"pr-md-0"}>
                    <div className={"d-block d-md-none"}><FormattedMessage
                        id={"logistBlock.plan"}/>
                    </div>
                    <DateInput
                        onChangeOnDelete={true}
                        value={transitPoint ? get(transitPoint, type)?.plan : ''}
                        dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                        icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                        disabled={this.checkIsExistFactDateTransitPoint(type, transitPoint, arrayTransitPoints, indexCargo, indexTransitPoint) || !(transitPoint && get(transitPoint, type)?.enabled)}
                        onChange={value => this.onChangeDateFake(value, indexCargo, type, 'plan', idCargo, transitPoint, indexTransitPoint)}
                        className={'tracking-item-date'}
                        //error={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 1) ? '-' : ''}
                        //note={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 2) ? '-' : ''}
                    />
                </Col>
            )
        }
        const getDateInputFact = (transitPoint, type, indexCargo, indexTransitPoint, arrayTransitPoints, idCargo) => {
            return (
                <Col xs={12} md={4} className={"pr-md-0"}>
                    <div className={"d-block d-md-none"}><FormattedMessage
                        id={"logistBlock.fact"}/>
                    </div>
                    <DateInput
                        onChangeOnDelete={true}
                        value={transitPoint ? get(transitPoint, type)?.fact : ''}
                        dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                        icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                        disabled={!(transitPoint && get(transitPoint, type)?.enabled)}
                        onChange={value => this.onChangeDateFake(value, indexCargo, type, 'fact', idCargo, transitPoint, indexTransitPoint)}
                        // error={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.load', 1) ? '-' : ''}
                        // note={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.load', 2) ? '-' : ''}
                        className={'tracking-item-date'}
                    />
                </Col>
            )
        }

        const getHeaderInputFields = () => {
            return (
                <Row className={"header-date-columns d-none d-md-flex"}>
                    <Col xs={2} md={3} className={"pr-md-0"}></Col>
                    <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage
                        id={"logistBlock.plan"}/></Col>
                    <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage
                        id={"logistBlock.fact"}/></Col>
                    <Col xs={2} md={1}></Col>
                </Row>
            )
        }

        const renderFieldTransitPoint = (transitPoint, indexCargo, idCargo, arrayTransitPoints, indexTransitPoint, isLastTransitPoint=false) => {
            const type = transitPoint?.typeTracking;
            switch (type) {
                case "POINT_OF_PICKUP":
                    return (
                        <Row>
                            <Col xs={12} md={12}>
                                {getHeaderInputFields()}
                                <Row>
                                    <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                         style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.cargoReadyDate"/></span>
                                        <div className={"actions d-flex d-md-none ml-auto"}>
                                            <FontAwesome
                                                name={(transitPoint && transitPoint.ready.enabled) ? 'unlock-alt' : 'lock'}
                                                onClick={() => this.disableDateFakeRow(indexCargo, "ready", idCargo, transitPoint, indexTransitPoint)}/>
                                            <FontAwesome
                                                name={(transitPoint && transitPoint.ready.enabled) ? 'eraser' : 'eraser disabled'}
                                                onClick={() => {
                                                    if (transitPoint && transitPoint.ready.enabled) {
                                                        this.onUpdateTrackingInfoShiftDateTransitPoint({
                                                            fact: '',
                                                            plan: transitPoint ? transitPoint.ready.plan : ''
                                                        }, "pointOfPickup.ready.fact", idCargo);
                                                    }
                                                }}/>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} className={"pr-md-0"}>
                                        <div className={"d-block d-md-none"}><FormattedMessage
                                            id={"logistBlock.plan"}/>
                                        </div>
                                        <DateInput
                                            value={transitPoint ? transitPoint.ready.plan : ''}
                                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                            disabled={(this.checkIsExistFactDateTransitPoint("ready", transitPoint, arrayTransitPoints, indexCargo, indexTransitPoint) || !(transitPoint && transitPoint.ready.enabled))}
                                            //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                            onChange={value => {
                                                if (value) {
                                                    this.onUpdateTrackingInfoShiftDateTransitPoint({
                                                        fact: transitPoint ? transitPoint.ready.fact : '',
                                                        plan: value ? value : ''
                                                    }, "pointOfPickup.ready.plan", idCargo);
                                                }
                                            }}
                                            className={'tracking-item-date'}
                                        />
                                    </Col>
                                    <Col xs={12} md={4} className={"pr-md-0"}>
                                        <div className={"d-block d-md-none"}><FormattedMessage
                                            id={"logistBlock.fact"}/>
                                        </div>
                                        <DateInput
                                            onChangeOnDelete={true}
                                            value={transitPoint ? transitPoint.ready.fact : ''}
                                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                            disabled={!(transitPoint && transitPoint.ready.enabled)}
                                            //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                            onChange={value => {
                                                this.onUpdateTrackingInfoShiftDateTransitPoint({
                                                    fact: value ? value : '',
                                                    plan: transitPoint ? transitPoint.ready.plan : ''
                                                }, "pointOfPickup.ready.fact", idCargo);
                                            }}
                                            //error={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 1) ? '-' : ''}
                                            //note={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 2) ? '-' : ''}
                                            className={'tracking-item-date'}
                                        />
                                    </Col>
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                        {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "ready")}
                                    </Col>
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                         style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.loadingDate"/></span>

                                        {getMobileActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "load", arrayTransitPoints)}

                                    </Col>
                                    {getDateInputPlan(transitPoint, "load", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    {getDateInputFact(transitPoint, "load", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                        {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "load")}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                         style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.departureDate"/></span>
                                        {getMobileActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "departure", arrayTransitPoints)}
                                    </Col>
                                    {getDateInputPlan(transitPoint, "departure", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    {getDateInputFact(transitPoint, "departure", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                        {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "departure")}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                case "INVOICE":
                    return (
                        <Row>
                            <Col xs={12} md={12}>
                                <Row className={"header-date-columns d-none d-md-flex"}>
                                    <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                    <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage
                                        id={"logistBlock.invoiceDate"}/></Col>
                                    <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage
                                        id={"logistBlock.invoicePaymentDate"}/></Col>
                                    <Col xs={2} md={1}></Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                         style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}>{/*<FormattedMessage
                                                            id="logistBlock.cargoReadyDate"/>*/}</span>
                                        <div className={"actions d-flex d-md-none ml-auto"}>
                                            <FontAwesome
                                                name={'eraser'}
                                                onClick={() => {
                                                    this.onChangeSingleDateFake('', indexTransitPoint, indexCargo, idCargo);
                                                    this.onChangeSingleDateFake('', indexTransitPoint + 1, indexCargo, idCargo);
                                                }}/>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={4} className={"pr-md-0"}>
                                        <div className={"d-block d-md-none"}><FormattedMessage
                                            id={"logistBlock.invoiceDate"}/>
                                        </div>
                                        <DateInput
                                            onChangeOnDelete={true}
                                            value={transitPoint.data[0] ? transitPoint.data[0].singleDate : ''}
                                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                            disabled={false}
                                            onChange={value => this.onChangeSingleDateFake(value ? value : '', indexTransitPoint, indexCargo, idCargo)}
                                            /*error={getIndicatorById(this.state.indicator, 'indicator.invoiceDate', 1) ? '-' : ''}
                                            note={getIndicatorById(this.state.indicator, 'indicator.invoiceDate', 2) ? '-' : ''}*/
                                            className={'tracking-item-date'}
                                        />
                                    </Col>
                                    <Col xs={12} md={4} className={"pr-md-0"}>
                                        <div className={"d-block d-md-none"}><FormattedMessage
                                            id={"logistBlock.invoicePaymentDate"}/>
                                        </div>
                                        <DateInput
                                            onChangeOnDelete={true}
                                            value={transitPoint.data[1] ? transitPoint.data[1].singleDate : ''}
                                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                            disabled={false}
                                            onChange={value => this.onChangeSingleDateFake(value ? value : '', indexTransitPoint + 1, indexCargo, idCargo)}
                                            //error={getIndicatorById(this.state.indicator, 'indicator.invoicePaymentDate', 1) ? '-' : ''}
                                            //note={getIndicatorById(this.state.indicator, 'indicator.invoicePaymentDate', 2) ? '-' : ''}
                                            className={'tracking-item-date'}
                                        />
                                    </Col>
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                        <div className={"actions"}>
                                            {/*<FontAwesome
                                                                name={'eraser'}
                                                                onClick={() => {
                                                                    this.onChangeSingleDate("", 'invoiceDate');
                                                                    this.onChangeSingleDate("", 'invoicePaymentDate');
                                                                }}/>*/}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                case "POINT_OF_CUSTOMS_CLEARANCE":
                case "POINT_OF_ORIGIN":
                case "POINT_OF_TRANSIT":
                case "POINT_OF_ARRIVAL":
                    indexTransitPoint = indexTransitPoint + 1;
                    return (
                        <Row>
                            <Col xs={12} md={12}>
                                {getHeaderInputFields()}
                                <Row>
                                    <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                         style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.arrivalDate"/></span>
                                        <div className={"actions d-flex d-md-none ml-auto"}>
                                            {getMobileActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "arrival", arrayTransitPoints)}
                                        </div>
                                    </Col>
                                    {getDateInputPlan(transitPoint, "arrival", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    {getDateInputFact(transitPoint, "arrival", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                        {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "arrival")}
                                    </Col>
                                </Row>
                                {type === "POINT_OF_ARRIVAL" &&
                                    <Row>
                                        <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                             style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.documentsDate"/></span>
                                            {getMobileActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "receiptOfDocuments", arrayTransitPoints)}
                                        </Col>
                                        {getDateInputPlan(transitPoint, "receiptOfDocuments", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                        {getDateInputFact(transitPoint, "receiptOfDocuments", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                        <Col xs={2} md={1} className={"d-none d-md-block"}>
                                            {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "receiptOfDocuments")}
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                         style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage id={isLastTransitPoint===true?"logistBlock.dateOfEnd":"logistBlock.departureDate"}/></span>
                                        {getMobileActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "departure", arrayTransitPoints)}
                                    </Col>
                                    {getDateInputPlan(transitPoint, "departure", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    {getDateInputFact(transitPoint, "departure", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                        {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "departure")}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )
                case "POINT_OF_DESTINATION":
                case "POINT_OF_DELIVERY_TO_RECIPIENT":
                    indexTransitPoint = indexTransitPoint + 1;
                    return (
                        <Row>
                            <Col xs={12} md={12}>
                                {getHeaderInputFields()}
                                <Row>
                                    <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                         style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.arrivalDate"/></span>
                                        {getMobileActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "arrival", arrayTransitPoints)}
                                    </Col>
                                    {getDateInputPlan(transitPoint, "arrival", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    {getDateInputFact(transitPoint, "arrival", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                                        {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "arrival")}
                                    </Col>
                                </Row>
                                {(type==="POINT_OF_DESTINATION" && indexTransitPoint===arrayTransitPoints.length) &&
                                    <Row>
                                        <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                             style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage id={isLastTransitPoint===true?"logistBlock.dateOfEnd":"logistBlock.departureDate"}/></span>
                                            {getMobileActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "departure", arrayTransitPoints)}
                                        </Col>
                                        {getDateInputPlan(transitPoint, "departure", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                        {getDateInputFact(transitPoint, "departure", indexCargo, indexTransitPoint, arrayTransitPoints, idCargo)}
                                        <Col xs={2} md={1} className={"d-none d-md-block"}>
                                            {getDesktopActions(transitPoint, indexCargo, indexTransitPoint, idCargo, "departure")}
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    )
            }
        }


        const trackingInfoBlock = (index, value) => {
            const idCargo = value?.idCargo
            const trackingInfoData = value?.trackingInfo
            const manualStatus = value?.manualStatus

            const trackingInfo = this.calcTransitTimes(trackingInfoData)
            const pointOfPickupStep = prepareTrackingSteps(STAGES.pointOfPickup, trackingInfo)
            const invoicePaymentDate = prepareTrackingSteps(STAGES.invoicePaymentDate, trackingInfo)
            const pointOfOrigin = prepareTrackingSteps(STAGES.pointOfOrigin, trackingInfo)
            const pointOfArrival = prepareTrackingSteps(STAGES.pointOfArrival, trackingInfo)
            const customsClearance = prepareTrackingSteps(STAGES.customsClearance, trackingInfo)
            const pointOfDestination = prepareTrackingSteps(STAGES.pointOfDestination, trackingInfo)
            const deliveryToRecipientDate = prepareTrackingSteps(STAGES.deliveryToRecipientDate, trackingInfo)

            const getPlanDateInputField = (block, direction, type, error, note) => {
                return (
                    <Col xs={12} md={4} className={"pr-md-0"}>
                        <div className={"d-block d-md-none"}><FormattedMessage
                            id={"logistBlock.plan"}/>
                        </div>
                        <DateInput
                            onChangeOnDelete={true}
                            value={trackingInfo ? get(trackingInfo, `${block}.${direction}.${type}`) : ''}
                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                            disabled={checkIsExistFactDate(`${block}.${direction}`, trackingInfo, index, idCargo) || !(trackingInfo && get(trackingInfo, `${block}.${direction}`)?.enabled)}
                            //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                            onChange={value => this.onChangeDate(value, block, direction, type, trackingInfo, index, idCargo)}
                            error={error}
                            note={note}
                            className={'tracking-item-date'}
                        />
                    </Col>
                )
            }
            const getFactDateInputField = (block, direction, type, error, note) => {
                return (
                    <Col xs={12} md={4} className={"pr-md-0"}>
                        <div className={"d-block d-md-none"}><FormattedMessage
                            id={"logistBlock.fact"}/>
                        </div>
                        <DateInput
                            onChangeOnDelete={true}
                            value={trackingInfo ? get(trackingInfo, `${block}.${direction}.${type}`) : ''}
                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                            disabled={!(trackingInfo && get(trackingInfo, `${block}.${direction}`)?.enabled)}
                            //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                            onChange={value => this.onChangeDate(value, block, direction, type, trackingInfo, index, idCargo)}
                            error={error}
                            note={note}
                            className={'tracking-item-date'}
                        />
                    </Col>
                )
            }

            const getMobileActions = (block, direction) => {
                return (
                    <div className={"actions d-flex d-md-none ml-auto"}>
                        <FontAwesome
                            name={(trackingInfo && get(trackingInfo, `${block}.${direction}`)?.enabled) ? 'unlock-alt' : 'lock'}
                            onClick={() => this.disableDateRow(block, direction, trackingInfo, index, idCargo)}/>
                        <FontAwesome
                            name={(!checkIsExistFactDate(`${block}.${direction}`, trackingInfo) && (trackingInfo && get(trackingInfo, `${block}.${direction}`)?.enabled)) ? 'eraser' : 'eraser disabled'}
                            onClick={() => {
                                if (trackingInfo && get(trackingInfo, `${block}.${direction}`)?.enabled === true) {
                                    this.onChangeDate("", block, direction, 'fact', trackingInfo, index, idCargo);
                                }
                                if (!checkIsExistFactDate(`${block}.${direction}`, trackingInfo, index, idCargo) && trackingInfo && get(trackingInfo, `${block}.${direction}`)?.enabled === true) {
                                    this.onChangeDate("", block, direction, 'plan', trackingInfo, index, idCargo);
                                }
                            }}/>
                    </div>
                )
            }

            const getDesktopActions = (block, direction) => {
                return (
                    <Col xs={2} md={1} className={"d-none d-md-block"}>
                        <div className={"actions"}>
                            <FontAwesome
                                name={(trackingInfo && get(trackingInfo, `${block}.${direction}`)?.enabled) ? 'unlock-alt' : 'lock'}
                                onClick={() => this.disableDateRow(block, direction, trackingInfo, index, idCargo)}/>
                        </div>
                    </Col>
                )
            }

            if (userStore.isAdmin() && this.state.partner !== null && this.state.partner.name === "CDEK")
                return (
                    <CdekTracking cdekSteps={this.state.cdekSteps}/>
                )

            if (trackingInfoData?.transitPoints && trackingInfoData?.transitPoints.length > 0) {
                const transitPoints = prepareDataArray(trackingInfoData?.transitPoints);
                return (
                    <div>
                        {(index === 0 && this.isContainerCargo() && this.state.deliveryTrackingInfo?.trackingInfoItems?.length > 1) &&
                            <Row>
                                <Col xs={12} className={"px-0 mb-3"}>
                                    <Checkbox
                                        label={<FormattedMessage id={"orderForm.sync"}/>}
                                        checked={this.state.sync}
                                        onChange={(event) => {
                                            if (event.target.checked === true)
                                                this.updateState({
                                                    showModalSync: true
                                                })
                                            else {
                                                this.updateState({
                                                    sync: false
                                                })
                                            }
                                            //this.syncDates();
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} md={12} className={"px-0"}>
                                <div className={"tracking-header"}>
                                    <p><FormattedMessage id="orderForm.currentStatus"/></p>
                                    <TextInput
                                        placeholder="orderForm.currentStatus"
                                        onChange={ev => this.onChangeStatus(value, index, ev.target.value)}
                                        value={manualStatus} // пусть в trackingDetails приходит хотя пустая строка для статуса
                                        type="text"
                                        onBlur={() => this.sendStatus(idCargo, manualStatus)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className="tracking-modal-container">
                            {transitPoints.map((itemTransitPoint, indexTransitPoint) => {
                                const isLastTransitPoint = indexTransitPoint === transitPoints.length - 1;
                                return (
                                    <div key={`transitPoint-${indexTransitPoint}`} className="tracking-modal-row">
                                        {trackingInfoPathTransitPoint(itemTransitPoint, transitPoints, indexTransitPoint, idCargo)}
                                        <div className="tracking-modal-row__item">
                                            <div className="tracking-modal-row__title">
                                                {getNameStep(itemTransitPoint)}
                                            </div>
                                            <div className={"card-item" + getClassName4Step(itemTransitPoint)}>
                                                <Row>
                                                    <Col xs={12} className={"px-0"}>
                                                        <div>{getStatus(itemTransitPoint)}</div>
                                                    </Col>
                                                </Row>
                                                {renderFieldTransitPoint(itemTransitPoint, index, idCargo, transitPoints, indexTransitPoint, isLastTransitPoint)}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            }

            const getPointOfPickup = () => {
                if (trackingInfo && trackingInfo.pointOfPickup) {
                    const summaryTransitTime = this.calcSummaryTransitTime(trackingInfo);
                    return <div className="tracking-modal-row">
                        {trackingInfoPath(pointOfPickupStep, trackingInfo, index, idCargo)}
                        <div className="tracking-modal-row__item">
                            <div className="tracking-modal-row__title">
                                {trackingInfo.pointOfPickup.name ? trackingInfo.pointOfPickup.name[getLangInStorage()] : ''}

                                {summaryTransitTime &&
                                    <NumberInputInplace
                                        value={summaryTransitTime}
                                        onSave={() => false}
                                        leftAddon={<FormattedMessage id={"detailsOfCalculation.transitTime"}/>}
                                        rightAddon={getWordDeclension(summaryTransitTime)}
                                        disabled={true}
                                    />
                                }

                                {/* временно отключено редактирование времени между хабами */}
                                {/*trackingInfo.pointOfPickup.transitTime !== null &&
                                typeof trackingInfo.pointOfPickup.transitTime !== 'undefined'
                                    ? <NumberInputInplace
                                        value={trackingInfo.pointOfPickup.transitTime}

                                        onSave={(v) => this.onChangeTransitTime('pointOfPickup', v, trackingInfo, index, idCargo)}
                                        leftAddon={<FormattedMessage id={"detailsOfCalculation.transitTime"}/>}
                                        rightAddon={getWordDeclension(trackingInfo.pointOfPickup.transitTime)}
                                        disabled={this.canEditTransitTime('pointOfPickup', trackingInfo, index, idCargo)}
                                    />
                                    : null
                                */}
                            </div>
                            <div className={"card-item " + getClassName4Step(pointOfPickupStep)}>
                                <Row>
                                    <Col xs={12} className={"px-0"}>
                                        <div>{getStatus(pointOfPickupStep)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        {getHeaderInputFields()}
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.cargoReadyDate"/></span>
                                                <div className={"actions d-flex d-md-none ml-auto"}>
                                                    <FontAwesome
                                                        name={(trackingInfo && trackingInfo.pointOfPickup.ready.enabled) ? 'unlock-alt' : 'lock'}
                                                        onClick={() => this.disableDateRow('pointOfPickup', 'ready', trackingInfo, index, idCargo)}/>
                                                    <FontAwesome
                                                        name={(!checkIsExistFactDate("pointOfPickup.ready", trackingInfo) && (trackingInfo && trackingInfo.pointOfPickup.ready.enabled)) ? 'eraser' : 'eraser disabled'}
                                                        onClick={() => {
                                                            if (!checkIsExistFactDate("pointOfPickup.ready", trackingInfo) && (trackingInfo && trackingInfo.pointOfPickup.ready.enabled)) {
                                                                this.onChangeDate("", 'pointOfPickup', 'ready', 'fact', trackingInfo, index, idCargo);
                                                            }
                                                        }}/>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={4} className={"pr-md-0"}>
                                                <div className={"d-block d-md-none"}><FormattedMessage
                                                    id={"logistBlock.plan"}/>
                                                </div>
                                                <DateInput
                                                    value={trackingInfo ? trackingInfo.pointOfPickup.ready.plan : ''}
                                                    dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                    icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                    disabled={(checkIsExistFactDate("pointOfPickup.ready", trackingInfo) || !(trackingInfo && trackingInfo.pointOfPickup.ready.enabled))}
                                                    //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                    onChange={value => {
                                                        if (value)
                                                            this.onUpdateTrackingInfoShiftDate({
                                                                fact: trackingInfo ? trackingInfo.pointOfPickup.ready.fact : '',
                                                                plan: value ? value : ''
                                                            }, "pointOfPickup.ready.plan", trackingInfo, index, idCargo)
                                                    }}
                                                    className={'tracking-item-date'}
                                                />
                                            </Col>
                                            <Col xs={12} md={4} className={"pr-md-0"}>
                                                <div className={"d-block d-md-none"}><FormattedMessage
                                                    id={"logistBlock.fact"}/>
                                                </div>
                                                <DateInput
                                                    onChangeOnDelete={true}
                                                    value={trackingInfo ? trackingInfo.pointOfPickup.ready.fact : ''}
                                                    dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                    icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                    disabled={!(trackingInfo && trackingInfo.pointOfPickup.ready.enabled)}
                                                    //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                    onChange={value => {
                                                        //this.onChangeDate(value, 'pointOfPickup', 'ready', 'fact')
                                                        this.onUpdateTrackingInfoShiftDate({
                                                            fact: value ? value : '',
                                                            plan: trackingInfo ? trackingInfo.pointOfPickup.ready.plan : ''
                                                        }, "pointOfPickup.ready.fact", trackingInfo, index, idCargo)
                                                    }}
                                                    error={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 1) ? '-' : ''}
                                                    note={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 2) ? '-' : ''}
                                                    className={'tracking-item-date'}
                                                />
                                            </Col>
                                            <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                <div className={"actions"}>
                                                    <FontAwesome
                                                        name={(trackingInfo && trackingInfo.pointOfPickup.ready.enabled) ? 'unlock-alt' : 'lock'}
                                                        onClick={() => this.disableDateRow('pointOfPickup', 'ready', trackingInfo, index, idCargo)}/>
                                                </div>
                                            </Col>
                                            <Col xs={2} md={1} className={"d-none d-md-block"}>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.loadingDate"/></span>
                                                {getMobileActions('pointOfPickup', 'load')}
                                            </Col>
                                            {getPlanDateInputField('pointOfPickup', 'load', 'plan')}
                                            {getFactDateInputField('pointOfPickup', 'load', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.load', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.load', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('pointOfPickup', 'load')}
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.departureDate"/></span>
                                                {getMobileActions('pointOfPickup', 'departure')}
                                            </Col>
                                            {getPlanDateInputField('pointOfPickup', 'departure', 'plan')}
                                            {getFactDateInputField('pointOfPickup', 'departure', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.departure', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.departure', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('pointOfPickup', 'departure')}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>;
                }
            }

            const getInvoice = () => {
                if (trackingInfo
                    && (trackingInfo.invoiceDate || trackingInfo.invoicePaymentDate))
                    return <div className="tracking-modal-row">
                        {trackingInfoPath(invoicePaymentDate, trackingInfo, index, idCargo)}
                        <div className="tracking-modal-row__item">
                            <div className="tracking-modal-row__title">
                                <FormattedMessage id={"logistBlock.invoice"}/>
                            </div>
                            <div className={"card-item " + getClassName4Step(invoicePaymentDate)}>
                                <Row>
                                    <Col xs={12} className={"px-0"}>
                                        <div>{getStatus(invoicePaymentDate)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        <Row className={"header-date-columns d-none d-md-flex"}>
                                            <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                            <Col xs={4} md={4} className={"pr-md-0"}>
                                                <FormattedMessage id={"logistBlock.invoiceDate"}/>
                                            </Col>
                                            <Col xs={4} md={4} className={"pr-md-0"}>
                                                <FormattedMessage id={"logistBlock.invoicePaymentDate"}/>
                                            </Col>
                                            <Col xs={2} md={1}></Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}>{/*<FormattedMessage
                                                            id="logistBlock.cargoReadyDate"/>*/}</span>
                                                <div className={"actions d-flex d-md-none ml-auto"}>
                                                    <FontAwesome
                                                        name={'eraser'}
                                                        onClick={() => {
                                                            this.onChangeSingleDate('', 'invoiceDate', trackingInfo, index, idCargo);
                                                            this.onChangeSingleDate('', 'invoicePaymentDate', trackingInfo, index, idCargo);
                                                        }}/>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={4} className={"pr-md-0"}>
                                                <div className={"d-block d-md-none"}><FormattedMessage
                                                    id={"logistBlock.invoiceDate"}/>
                                                </div>
                                                <DateInput
                                                    onChangeOnDelete={true}
                                                    value={trackingInfo
                                                        ? ((trackingInfo.invoiceDate.date && trackingInfo.invoiceDate.date.date)
                                                            ? trackingInfo.invoiceDate.date.date
                                                            : '')
                                                        : ''}
                                                    dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                    icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                    disabled={false}
                                                    onChange={value => this.onChangeSingleDate(value, 'invoiceDate', trackingInfo, index, idCargo)}
                                                    error={getIndicatorById(this.state.indicator, 'indicator.invoiceDate', 1) ? '-' : ''}
                                                    note={getIndicatorById(this.state.indicator, 'indicator.invoiceDate', 2) ? '-' : ''}
                                                    className={'tracking-item-date'}
                                                />
                                            </Col>
                                            <Col xs={12} md={4} className={"pr-md-0"}>
                                                <div className={"d-block d-md-none"}><FormattedMessage
                                                    id={"logistBlock.invoicePaymentDate"}/>
                                                </div>
                                                <DateInput
                                                    onChangeOnDelete={true}
                                                    value={trackingInfo
                                                        ? ((trackingInfo.invoicePaymentDate.date && trackingInfo.invoicePaymentDate.date.date)
                                                            ? trackingInfo.invoicePaymentDate.date.date
                                                            : '')
                                                        : ''}
                                                    dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                    icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                    disabled={false}
                                                    onChange={value => this.onChangeSingleDate(value, 'invoicePaymentDate', trackingInfo, index, idCargo)}
                                                    error={getIndicatorById(this.state.indicator, 'indicator.invoicePaymentDate', 1) ? '-' : ''}
                                                    note={getIndicatorById(this.state.indicator, 'indicator.invoicePaymentDate', 2) ? '-' : ''}
                                                    className={'tracking-item-date'}
                                                />
                                            </Col>
                                            <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                <div className={"actions"}>
                                                    {/*<FontAwesome
                                                                name={'eraser'}
                                                                onClick={() => {
                                                                    this.onChangeSingleDate("", 'invoiceDate');
                                                                    this.onChangeSingleDate("", 'invoicePaymentDate');
                                                                }}/>*/}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
            }

            const getPointOfOrigin = () => {
                if (trackingInfo && trackingInfo.pointOfOrigin)
                    return <div className="tracking-modal-row">
                        {trackingInfoPath(pointOfOrigin, trackingInfo, index, idCargo)}
                        <div className="tracking-modal-row__item">
                            <div className="tracking-modal-row__title">
                                {trackingInfo.pointOfOrigin.name ? trackingInfo.pointOfOrigin.name[getLangInStorage()] : ''}

                                {/* временно отключено редактирование времени между хабами */}
                                {/* trackingInfo.pointOfOrigin.transitTime !== null &&
                                typeof trackingInfo.pointOfPickup.transitTime !== 'undefined'
                                    ? <NumberInputInplace
                                        value={trackingInfo.pointOfOrigin.transitTime}
                                        onSave={(v) => this.onChangeTransitTime('pointOfOrigin', v, trackingInfo, index, idCargo)}
                                        leftAddon={<FormattedMessage id={"detailsOfCalculation.transitTime"}/>}
                                        rightAddon={getWordDeclension(trackingInfo.pointOfOrigin.transitTime)}
                                        disabled={this.canEditTransitTime('pointOfOrigin', trackingInfo, index, idCargo)}
                                    />
                                    : null
                                */}

                            </div>
                            <div className={"card-item " + getClassName4Step(pointOfOrigin)}>
                                <Row>
                                    <Col xs={12} className={"px-0"}>
                                        <div>{getStatus(pointOfOrigin)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        {getHeaderInputFields()}
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.arrivalDate"/>
                                                        </span>
                                                {getMobileActions('pointOfOrigin', 'arrival')}
                                            </Col>
                                            {getPlanDateInputField('pointOfOrigin', 'arrival', 'plan')}
                                            {getFactDateInputField('pointOfOrigin', 'arrival', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.arrival', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.arrival', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('pointOfOrigin', 'arrival')}
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.departureDate"/>
                                                        </span>
                                                {getMobileActions('pointOfOrigin', 'departure')}
                                            </Col>
                                            {getPlanDateInputField('pointOfOrigin', 'departure', 'plan')}
                                            {getFactDateInputField('pointOfOrigin', 'departure', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.departure', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.departure', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('pointOfOrigin', 'departure')}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
            }

            const getPointOfArrival = () => {
                if (trackingInfo && trackingInfo.pointOfArrival)
                    return <div className="tracking-modal-row">
                        {trackingInfoPath(pointOfArrival, trackingInfo, index, idCargo)}
                        <div className="tracking-modal-row__item">
                            <div className="tracking-modal-row__title">
                                {trackingInfo.pointOfArrival.name ? trackingInfo.pointOfArrival.name[getLangInStorage()] : ''}

                                {/* временно отключено редактирование времени между хабами */}
                                {/* trackingInfo.pointOfArrival.transitTime !== null &&
                                typeof trackingInfo.pointOfPickup.transitTime !== 'undefined'
                                    ? <NumberInputInplace
                                        value={trackingInfo.pointOfArrival.transitTime}
                                        onSave={(v) => this.onChangeTransitTime('pointOfArrival', v, trackingInfo, index, idCargo)}
                                        leftAddon={<FormattedMessage id={"detailsOfCalculation.transitTime"}/>}
                                        rightAddon={getWordDeclension(trackingInfo.pointOfArrival.transitTime)}
                                        disabled={this.canEditTransitTime('pointOfArrival')}
                                    />
                                    : null
                                */}

                            </div>
                            <div className={"card-item " + getClassName4Step(pointOfArrival)}>
                                <Row>
                                    <Col xs={12} className={"px-0"}>
                                        <div>{getStatus(pointOfArrival)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        {getHeaderInputFields()}
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.arrivalDate"/></span>
                                                {getMobileActions('pointOfArrival', 'arrival')}
                                            </Col>
                                            {getPlanDateInputField('pointOfArrival', 'arrival', 'plan')}
                                            {getFactDateInputField('pointOfArrival', 'arrival', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.arrival', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.arrival', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('pointOfArrival', 'arrival')}
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.documentsDate"/>
                                                        </span>
                                                {getMobileActions('pointOfArrival', 'receiptOfDocuments')}
                                            </Col>
                                            {getPlanDateInputField('pointOfArrival', 'receiptOfDocuments', 'plan')}
                                            {getFactDateInputField('pointOfArrival', 'receiptOfDocuments', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.receiptOfDocuments', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.receiptOfDocuments', 2) ? '-' : '')
                                            }
                                            <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                {getDesktopActions('pointOfArrival', 'receiptOfDocuments')}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.export"/></span>
                                                {getMobileActions('pointOfArrival', 'departure')}
                                            </Col>
                                            {getPlanDateInputField('pointOfArrival', 'departure', 'plan')}
                                            {getFactDateInputField('pointOfArrival', 'departure', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.departure', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.departure', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('pointOfArrival', 'departure')}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
            }

            const getCustomsClearance = () => {
                if (trackingInfo && trackingInfo.customsClearance)
                    return <div className="tracking-modal-row">
                        {trackingInfoPath(customsClearance, trackingInfo, index, idCargo)}
                        <div className="tracking-modal-row__item">
                            <div className="tracking-modal-row__title">
                                <FormattedMessage id="form.field.customsClearance"/>
                            </div>
                            <div className={"card-item " + getClassName4Step(customsClearance)}>
                                <Row>
                                    <Col xs={12} className={"px-0"}>
                                        <div>{getStatus(customsClearance)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        {getHeaderInputFields()}
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.arrivalOrInnings"/></span>
                                                {getMobileActions('customsClearance', 'arrival')}
                                            </Col>
                                            {getPlanDateInputField('customsClearance', 'arrival', 'plan')}
                                            {getFactDateInputField('customsClearance', 'arrival', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.customsClearance.arrival', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.customsClearance.arrival', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('customsClearance', 'arrival')}
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.departureOrIssueDate"/>
                                                        </span>
                                                {getMobileActions('customsClearance', 'departure')}
                                            </Col>
                                            {getPlanDateInputField('customsClearance', 'departure', 'plan')}
                                            {getFactDateInputField('customsClearance', 'departure', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.customsClearance.departure', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.customsClearance.departure', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('customsClearance', 'departure')}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
            }

            const getPointIfDestination = () => {
                if (trackingInfo && trackingInfo.pointOfDestination)
                    return <div className="tracking-modal-row">
                        {trackingInfoPath(pointOfDestination, trackingInfo, index, idCargo)}
                        <div className="tracking-modal-row__item">
                            <div className="tracking-modal-row__title">
                                {trackingInfo.pointOfDestination.name ? trackingInfo.pointOfDestination.name[getLangInStorage()] : ''}
                            </div>
                            <div className={"card-item " + getClassName4Step(pointOfDestination)}>
                                <Row>
                                    <Col xs={12} className={"px-0"}>
                                        <div>{getStatus(pointOfDestination)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        {getHeaderInputFields()}
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.arrivalDate"/></span>
                                                {getMobileActions('pointOfDestination', 'arrival')}
                                            </Col>
                                            {getPlanDateInputField('pointOfDestination', 'arrival', 'plan')}
                                            {getFactDateInputField('pointOfDestination', 'arrival', 'fact',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfDestination.arrival', 1) ? '-' : '',
                                                getIndicatorById(this.state.indicator, 'indicator.pointOfDestination.arrival', 2) ? '-' : '')
                                            }
                                            {getDesktopActions('pointOfDestination', 'arrival')}
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
            }

            const getDeliveryToRecipientDate = () => {
                if (trackingInfo && trackingInfo.deliveryToRecipientDate)
                    return <div className="tracking-modal-row">
                        {trackingInfoPath(deliveryToRecipientDate, trackingInfo, index, idCargo)}
                        <div className="tracking-modal-row__item">
                            <div className="tracking-modal-row__title">
                                <FormattedMessage id="logistBlock.deliveryToRecipientDate"/>
                            </div>
                            <div className={"card-item " + getClassName4Step(deliveryToRecipientDate)}>
                                <Row>
                                    <Col xs={12} className={"px-0"}>
                                        <div>{getStatus(deliveryToRecipientDate)}</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={12}>
                                        {getHeaderInputFields()}
                                        <Row>
                                            <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                 style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                            id="logistBlock.deliveryToRecipientDate"/></span>
                                                <div className={"actions d-flex d-md-none ml-auto"}>
                                                    <FontAwesome
                                                        name={(trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled) ? 'unlock-alt' : 'lock'}
                                                        onClick={() => this.disableDateRow('deliveryToRecipientDate', 'date', trackingInfo, index, idCargo)}/>
                                                    <FontAwesome
                                                        name={(!checkIsExistFactDate("deliveryToRecipientDate.date", trackingInfo, index, idCargo) && (trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled)) ? 'eraser' : 'eraser disabled'}
                                                        onClick={() => {
                                                            if (trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled === true) {
                                                                this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'plan', trackingInfo, index, idCargo);
                                                            }
                                                            if (!checkIsExistFactDate("deliveryToRecipientDate.date", trackingInfo, index, idCargo) && trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled === true) {
                                                                this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'fact', trackingInfo, index, idCargo);
                                                            }
                                                        }}/>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={4} className={"pr-md-0"}>
                                                <div className={"d-block d-md-none"}><FormattedMessage
                                                    id={"logistBlock.plan"}/>
                                                </div>
                                                <DateInput
                                                    onChangeOnDelete={true}
                                                    value={trackingInfo ? trackingInfo.deliveryToRecipientDate.date && trackingInfo.deliveryToRecipientDate.date.plan : ''}
                                                    dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                    icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                    disabled={checkIsExistFactDate("deliveryToRecipientDate.date", trackingInfo, index, idCargo) || !(trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled)}
                                                    onChange={value => this.onChangeDoubleDate(value, 'deliveryToRecipientDate', 'plan', trackingInfo, index, idCargo)}
                                                    className={'tracking-item-date'}
                                                />
                                            </Col>
                                            <Col xs={12} md={4} className={"pr-md-0"}>
                                                <div className={"d-block d-md-none"}><FormattedMessage
                                                    id={"logistBlock.fact"}/>
                                                </div>
                                                <DateInput
                                                    onChangeOnDelete={true}
                                                    value={trackingInfo ? trackingInfo.deliveryToRecipientDate.date && trackingInfo.deliveryToRecipientDate.date.fact : ''}
                                                    dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                    icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                    disabled={!(trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled)}
                                                    onChange={value => this.onChangeDoubleDate(value, 'deliveryToRecipientDate', 'fact', trackingInfo, index, idCargo)}
                                                    error={getIndicatorById(this.state.indicator, 'indicator.deliveryToRecipientDate', 1) ? '-' : ''}
                                                    note={getIndicatorById(this.state.indicator, 'indicator.deliveryToRecipientDate', 2) ? '-' : ''}
                                                    className={'tracking-item-date'}
                                                />
                                            </Col>
                                            <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                <div className={"actions"}>
                                                    <FontAwesome
                                                        name={(trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled) ? 'unlock-alt' : 'lock'}
                                                        onClick={() => this.disableDateRow('deliveryToRecipientDate', 'date', trackingInfo, index, idCargo)}/>
                                                    {/*<FontAwesome
                                                                name={(trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (trackingInfo && trackingInfo.deliveryToRecipientDate.date.enabled === true) {
                                                                        this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'plan');
                                                                        this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'fact');
                                                                    }
                                                                }}/>*/}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </div>
            }

            return (<Fragment>
                    {(index === 0 && this.isContainerCargo() && this.state.deliveryTrackingInfo?.trackingInfoItems?.length > 1) &&
                        <Row>
                            <Col xs={12} className={"px-0 mb-3"}>
                                <Checkbox
                                    label={<FormattedMessage id={"orderForm.sync"}/>}
                                    checked={this.state.sync}
                                    onChange={(event) => {
                                        if (event.target.checked === true)
                                            this.updateState({
                                                showModalSync: true
                                            })
                                        else {
                                            this.updateState({
                                                sync: false
                                            })
                                        }
                                        //this.syncDates();
                                    }}
                                />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xs={12} md={12} className={"px-0"}>
                            <div className={"tracking-header"}>
                                <p><FormattedMessage id="orderForm.currentStatus"/></p>
                                <TextInput
                                    placeholder="orderForm.currentStatus"
                                    onChange={ev => this.onChangeStatus(value, index, ev.target.value)}
                                    value={manualStatus} // пусть в trackingDetails приходит хотя пустая строка для статуса
                                    type="text"
                                    onBlur={() => this.sendStatus(idCargo, manualStatus)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="tracking-modal-container">
                        {/* pointOfPickup */}
                        {getPointOfPickup()}

                        {/* invoice */}
                        {getInvoice()}

                        {/* pointOfOrigin */}
                        {getPointOfOrigin()}

                        {/* pointOfArrival */}
                        {getPointOfArrival()}

                        {/* customsClearance */}
                        {getCustomsClearance()}

                        {/* pointIfDestination */}
                        {getPointIfDestination()}

                        {/* deliveryToRecipientDate */}
                        {getDeliveryToRecipientDate()}
                    </div>
                </Fragment>
            )
        };

        if (isModal === true) {
            return (
                <Modal
                    isShow={isShow}
                    className="logistic-modal edit-order-modal"
                    title={translate({id: 'crudForm.field.edit'})}
                    onClose={() => {
                        onClose(false);
                    }}
                    top
                >
                    <div>
                        <Modal
                            isShow={this.state.showModalSync}
                            onClose={() => {
                                this.updateState({
                                    showModalSync: false
                                })
                            }}
                            title={<Row><Col xs={12}><FormattedMessage id={"dialog.confirm.title"}/></Col></Row>}
                            isModal={true}
                        >
                            <Row>
                                <Col xs={12}>
                                    <FormattedMessage id={"dialog.enableSyncDates.body"}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className={"d-flex mt-3"}>
                                    <Button
                                        className={"btn-primary-new"}
                                        onClick={() => {
                                            this.updateState({
                                                showModalSync: false
                                            }, () => this.syncDates())
                                        }}
                                    >
                                        <div className={"d-flex"}>
                                            <span><FormattedMessage id={"dialog.confirm.yes"}/></span>
                                        </div>

                                    </Button>
                                    <Button
                                        className={"btn-primary-new ml-2"}
                                        onClick={() => {
                                            this.updateState({
                                                showModalSync: false
                                            })
                                        }}
                                    >
                                        <span><FormattedMessage id={"dialog.confirm.no"}/></span>
                                    </Button>
                                </Col>
                            </Row>
                        </Modal>
                        {(this.state.loading === true) &&
                            <div className={"readonly-overlay"}></div>
                        }
                        {(this.state.loading === true) &&
                            <div className={"manual-messages"}>
                                <ManualFlashStatus type={"success"}>
                                    <div className="fa-1x mr-2">
                                        <i className="fa fa-spinner fa-spin"/><span className={"ml-2"}><FormattedMessage
                                        id={"common.saveStatus"}/></span>
                                    </div>
                                </ManualFlashStatus>
                            </div>

                        }

                        <Col xs={12}>
                            <div>
                                <p className={"tracking-header"}><FormattedMessage id={"logistBlock.cargoTracking"}/>
                                </p>
                                {(this.state.waiting === true) &&
                                    <div className={"d-flex justify-content-center"}>
                                        <div className="fa-1x mr-2" style={{fontSize: 20}}>
                                            <i className="fa fa-spinner fa-spin"/>
                                        </div>
                                    </div>
                                }
                                <div
                                    className={"d-block d-lg-flex align-items-center flex-wrap"}>{this.state.deliveryTrackingInfo?.trackingInfoItems?.filter(value => {
                                    if (usedIdCargoBtnBlock.includes(value.idCargo))
                                        return false;
                                    usedIdCargoBtnBlock.push(value.idCargo);
                                    return true;
                                }).map((value, index) => {
                                    return (<div key={`btn-cargo-modal-${index}`}>
                                        <Button
                                            disabled={this.state.sync === true ? index !== 0 : false}
                                            className={`btn-outline-primary-new height-auto w-100 mr-2 w-lg-auto no-focus mb-3 tracking-tab-btn ${index === this.state.activeTab ? 'active' : ''}`}
                                            onClick={() => this.changeTab(index)}>
                                            {getFormatNameCargoBtn(value)}
                                        </Button>
                                    </div>)
                                })}
                                </div>
                                <div
                                    className={"mt-2"}>{this.state.deliveryTrackingInfo?.trackingInfoItems?.filter(value => {
                                    if (usedIdCargo.includes(value.idCargo))
                                        return false;
                                    usedIdCargo.push(value.idCargo);
                                    return true;
                                }).map((value, index) => {
                                    if (index === this.state.activeTab)
                                        return (<div>
                                            {trackingInfoBlock(index, value)}
                                        </div>)
                                })}
                                </div>
                                <div className={"ml-auto"} style={{width: "fit-content"}}>
                                    <Button color="secondary" onClick={() => {
                                        this.props.onClose(false);
                                    }}>
                                        <FormattedMessage id="common.btn.ready"/>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </div>
                </Modal>
            )
                ;
        } else {
            return (
                <div>
                    <Modal
                        isShow={this.state.showModalSync}
                        onClose={() => {
                            this.updateState({
                                showModalSync: false
                            })
                        }}
                        title={<Row><Col xs={12}><FormattedMessage id={"dialog.confirm.title"}/></Col></Row>}
                        isModal={true}
                    >
                        <Row>
                            <Col xs={12}>
                                <FormattedMessage id={"dialog.enableSyncDates.body"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className={"d-flex mt-3"}>
                                <Button
                                    className={"btn-primary-new"}
                                    onClick={() => {
                                        this.updateState({
                                            showModalSync: false
                                        }, () => this.syncDates())
                                    }}
                                >
                                    <div className={"d-flex"}>
                                        <span><FormattedMessage id={"dialog.confirm.yes"}/></span>
                                    </div>

                                </Button>
                                <Button
                                    className={"btn-primary-new ml-2"}
                                    onClick={() => {
                                        this.updateState({
                                            showModalSync: false
                                        })
                                    }}
                                >
                                    <span><FormattedMessage id={"dialog.confirm.no"}/></span>
                                </Button>
                            </Col>
                        </Row>
                    </Modal>
                    {(this.state.loading === true) &&
                        <div className={"readonly-overlay"}></div>
                    }
                    {(this.state.loading === true) &&
                        <div className={"manual-messages"}>
                            <ManualFlashStatus type={"success"}>
                                <div className="fa-1x mr-2">
                                    <i className="fa fa-spinner fa-spin"/><span className={"ml-2"}><FormattedMessage
                                    id={"common.saveStatus"}/></span>
                                </div>
                            </ManualFlashStatus>
                        </div>

                    }

                    <Col xs={12}>
                        <div className={"logistic-tab"}>
                            <p className={"tracking-header"}><FormattedMessage id={"logistBlock.cargoTracking"}/></p>
                            {(this.state.waiting === true) &&
                                <div className={"d-flex justify-content-center"}>
                                    <div className="fa-1x mr-2" style={{fontSize: 20}}>
                                        <i className="fa fa-spinner fa-spin"/>
                                    </div>
                                </div>
                            }
                            <div
                                className={"d-block d-lg-flex align-items-center flex-wrap"}>{this.state.deliveryTrackingInfo?.trackingInfoItems?.filter(value => {
                                if (usedIdCargoBtnBlock.includes(value.idCargo))
                                    return false;
                                usedIdCargoBtnBlock.push(value.idCargo);
                                return true;
                            }).map((value, index) => {
                                return (<div key={`btn-cargo-${index}`}>
                                    <Button
                                        disabled={this.state.sync === true ? index !== 0 : false}
                                        className={`btn-outline-primary-new height-auto w-100 mr-2 w-lg-auto no-focus mb-3 tracking-tab-btn ${index === this.state.activeTab ? 'active' : ''}`}
                                        onClick={() => this.changeTab(index)}>
                                        {getFormatNameCargoBtn(value)}
                                    </Button>
                                </div>)
                            })}
                            </div>
                            <div
                                className={"mt-2"}>{this.state.deliveryTrackingInfo?.trackingInfoItems?.filter(value => {
                                if (usedIdCargo.includes(value.idCargo))
                                    return false;
                                usedIdCargo.push(value.idCargo);
                                return true;
                            }).map((value, index) => {
                                if (index === this.state.activeTab)
                                    return (<div>
                                        {trackingInfoBlock(index, value)}
                                    </div>)
                            })}
                            </div>
                            <div className={"ml-auto"} style={{width: "fit-content"}}>
                                <Button color="secondary" onClick={() => {
                                    this.props.onClose(false);
                                }}>
                                    <FormattedMessage id="common.btn.ready"/>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </div>);
        }
    }
}

LogisticPopupModal.defaultProps = {
    isModal: false,
    dataTracking: [],
    idCargo: null
}

LogisticPopupModal.propTypes = {
    isModal: PropTypes.bool,
    dataTracking: PropTypes.array,
    idCargo: PropTypes.string
}
