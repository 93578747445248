import {
  keyColumn,
  DynamicDataSheetGrid,
} from 'react-datasheet-grid';

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import 'react-datasheet-grid/dist/style.css';
import Select from 'react-select';
import dictionaryService from '../../../services/dictionaryService';
import { API } from '../../../constants/api';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import { getExistLangValue, translate } from '../../../utils/utils';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup, Input, Row } from 'reactstrap';
import tableTariffsService from '../../../services/tableTariffsService';
import moment from 'moment';
import { DATE_FORMAT, LANG_CODE_FULL } from '../../../constants/global';
import DatePicker from 'react-datepicker';
import AppStore from '../../../stores/appStore';
import FlashStore from '../../../stores/flashStore';
import { ContextMenu } from './ContextMenu';
import { toJS } from 'mobx';
import UserStore from '../../../stores/userStore';
import {
  isError,
  prepareExternalData,
  formatError,
  formatValues,
  formatSurchargesValues,
  onCopyValueSelect,
  onCopyValueText,
  onPasteValueSelect,
  onPasteValueText,
  onCopyValueDate,
  onPasteValueSelectSurcharges,
  createAddRowsComponent,
  createParam4Deleted,
  onPasteValueDate,
  patchRow,
  onDuplicate,
  renderOptions,
  renderFreight,
  emptyRowsFilter,
  formatValuesAirHubs,
  onPasteValueAirHubSelect,
  onPasteValueTypeCargoSelect,
  formatValuesAviaCompanies, onPasteValueCheckbox, onCopyValueReadonly, onCopyValueRate, onPasteValueRate,
} from './TableTariffsUtils';
import { Checkbox, TextInput } from '../form-control';


const createTariffItem = (type, airWeightRanges = {}) => (
  {
    SEA_TARIFFS: {
      id: null,
      dateFrom: null,
      dateTo: null,
      departurePortId: null,
      destinationPortId: null,
      seaLineId: null,
      freightId: null,
      freightCondition: null, //FIFO, FILO LIFO, LILO
      transitTime: null,
      demurrage: null,
      detention: null,
      documentDeadline: null,
      currency: null,
      weightUnit: null,
      freight20: null,
      weight20: null,
      freight40: null,
      weight40: null,
      freight40HC: null,
      weight40HC: null,
      commentDescription: null,

    },
    AIR_TARIFFS: {
      id: null,
      dateFrom: null,
      dateTo: null,
      transitTime: null,
      fromHubId: null,
      toHubId: null,
      aviaCompanyId: null,
      typeCargo: null,
      currency: null,
      minPrice: null,
      ...airWeightRanges,
      terminalProcessing: null,
      surcharges: null,
      commentDescription: null,
    },
  }[type]
);


const TableTariffs = ({ endpoint, type, onAfterSave, dataTariffsEdit, onCancel, airWeightRanges = {} }) => {

  const [errors, setErrors] = useState({});
  const [isShow, setIsShow] = useState(true);
  const [currincies, setCurrincies] = useState([
    { value: 'USD', label: 'USD' },
    { value: 'EUR', label: 'EUR' },
    { value: 'CNY', label: 'CNY' },
    { value: 'RUB', label: 'RUB' },
  ]);
  const [ports, setPorts] = useState([]);
  const [seaLines, setSeaLines] = useState([]);
  const [seaTariffSurcharges, setSeaTariffSurcharges] = useState([]);
  const [typeFreight, setTypeFreight] = useState([
    { value: 'FIFO', label: 'FIFO' },
    { value: 'FILO', label: 'FILO' },
    { value: 'LIFO', label: 'LIFO' },
    { value: 'LILO', label: 'LILO' },
  ]);

  const [airHubs, setAirHubs] = useState([]);
  const [aviaCompanies, setAviaCompanies] = useState([]);
  const [typeCargo, setTypeCargo] = useState([{ value: 'GENERAL_CARGO', label: 'GENERAL CARGO' }]);

  const [rerender, setRerender] = useState(false);
  const [data, setData] = useState(
    () => {
      let def = [];
      for (let i = 0; i < 10; i++) {
        def.push(createTariffItem(type, airWeightRanges));
      }
      return def;
    });
  const [arrayDeleteId, setArrayDeleteId] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const checkAccessAllowPaste = (rowIndex) => {
    const rowData = data[rowIndex];
    if (rowData === null || rowData === undefined) {
      return true;
    }
    if (rowData.id === null || rowData.id === '-') {
      return true;
    }
    if (rowData.organizationId && rowData.organizationId !== UserStore.user.organization.id) {
      return false;
    }
    return true;
  };

  const checkAccessDeleteRow = (rowIndex) => {
    return checkAccessAllowPaste(rowIndex);
  };

  const checkDisabledField = (rowIndex) => {

    return !checkAccessAllowPaste(rowIndex);
  };

  useEffect(() => {
    setIsLoad(true);
    if (dataTariffsEdit && dataTariffsEdit.length > 0) {
      let dataJs = toJS(dataTariffsEdit);
      setData(prepareExternalData(dataJs));
    }
    setIsLoad(false);
  }, [dataTariffsEdit]);


  const init = async () => {
    setIsLoad(true);
    dictionaryService.getDictionary(API.DICTIONARIES.SEA_LINES()).then(res => {
      setSeaLines(formatValues(res));
    });
    dictionaryService.getDictionary(API.DICTIONARIES.SEA_PORT_HUBS()).then(res => {
      setPorts(formatValues(res));
    });
    dictionaryService.getContainerFreightSurchargesAll({}).then((res) => {
      setSeaTariffSurcharges(formatSurchargesValues(res));
    });
    dictionaryService.getDictionary(API.DICTIONARIES.AIR_HUBS()).then(res => {
      setAirHubs(formatValuesAirHubs(res));
    });
    dictionaryService.getDictionary(API.DICTIONARIES.AVIA_COMPANIES()).then(res => {
      setAviaCompanies(formatValuesAviaCompanies(res));
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (data.length < 10) {
      let diff = 10 - data.length;
      let arrData = [];
      for (let i = 0; i < diff; i++) {
        arrData.push(createTariffItem(type, airWeightRanges));
      }
      setData([...data, ...arrData]);
    }
  }, [data]);


  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setIsShow(false);
    }
  }, [errors]);

  useEffect(() => {
    if (isShow === false) {
      setIsShow(true);
    }
  }, [isShow]);

  const onChange = (value, operations) => {
    let resultData = [];
    let deletedRows = [...arrayDeleteId];
    for (const operation of operations) {
      const fromIndex = operation.fromRowIndex;
      const toIndex = operation.toRowIndex;
      const createArrIndexes = () => {
        let r = [];
        for (let i = fromIndex; i < toIndex; i++) {
          r.push(i);
        }
        return r;
      };
      if (operation.type === 'CREATE') {
        resultData = value;
        setRerender(true);
      }
      if (operation.type === 'UPDATE') {
        resultData = value;
      }
      if (operation.type === 'DELETE') {
        const arrDeleteIndexes = createArrIndexes();
        const checkDelete = (index) => {
          if (arrDeleteIndexes.includes(index)) {
            return !checkAccessDeleteRow(index);
          }
          return true;
        };

        data.forEach((value, index) => {
          if (arrDeleteIndexes.includes(index) && checkAccessDeleteRow(index)) {
            if (value && value.id !== null && value.id !== undefined) {
              deletedRows.push(value.id);
            }
          }
        });
        setArrayDeleteId(deletedRows);

        resultData = data.filter((row, index) => checkDelete(index));
        setRerender(true);
      }
    }

    setData(resultData);
  };

  const onSave = async () => {
    setIsLoad(true);
    const saveArr = emptyRowsFilter(cloneDeep(data));
    let patchedArr = [];
    console.log('CRT: ', patchedArr);
    for (let row of saveArr) {
      patchedArr.push(patchRow(row, type));
    }

    const err = {};
    for (let i = 0; i < patchedArr.length; i++) {
      const item = patchedArr[i];
      if (item.__emptyRow !== true) {
        if (type === 'AIR_TARIFFS') {
          if (item.generalCargoRangeRate === null) {
            set(err, `tariffs[${i}].rate_0`, 'common.required');
          }
        }
      }
    }
    if (!isEmpty(err)) {
      setErrors(err);
      FlashStore.errorNow(translate({ id: 'tableTariffs.enterRates' }), true);
      return;
    }


    let createArr = [];

    for (let row of patchedArr) {
      createArr.push(row);
    }

    let updateArr = [];
    for (let row of patchedArr) {
      if (row.id !== null && row.id !== undefined) {
        if (row.organizationId !== null && row.organizationId === UserStore.user.organization.id) {
          updateArr.push(toJS(row, true));
        }
      }

    }

    let deleteArr = createParam4Deleted(cloneDeep(arrayDeleteId));


    console.log('CRT ARR: ', createArr);

    let createSuccess = true;
    let updateSuccess = true;
    let deleteSuccess = true;


    if (createArr.length > 0) {
      const responseCreate = await tableTariffsService.uploadTariffs(endpoint, { tariffs: createArr }).then(res => {
        if (res.code === 200) {
        }

        if (res.code === 400) {
          FlashStore.errorNow(translate('common.errorEmptyFields'), true);
          const dataClone = cloneDeep(res.data.errors);
          setErrors(formatError(dataClone));
        }
        return res;
      });
      createSuccess = responseCreate.code === 200;
      updateSuccess = responseCreate.code === 200;
      console.log('CREATE AND UPDATE DONE.', responseCreate);
    }

    /*if (updateArr.length > 0) {
        console.log("UPD ARR: ", {tariffs: updateArr})
        const responseUpdate = await tableTariffsService.updateTariffs(endpoint, {tariffs: updateArr}).then(res => {
            if (res.code === 200) {
            }

            if (res.code === 400) {
                FlashStore.errorNow(translate("common.errorEmptyFields"), true)
                const dataClone = cloneDeep(res.data.errors)
                setErrors(formatError(dataClone))
            }
            return res;
        });
        updateSuccess = responseUpdate.code === 200;
        console.log("UPDATE DONE.", responseUpdate)
    }*/

    if (deleteArr.length > 0) {
      console.log('DLT ARR: ', createParam4Deleted(cloneDeep(arrayDeleteId)));
      const responseDelete = await tableTariffsService.deleteTariffs(endpoint, { tariffs: deleteArr }).then(res => res);
      deleteSuccess = responseDelete.code === 200;
      console.log('DELETE DONE.', responseDelete);
    }


    setIsLoad(false);
    if (createSuccess === true && updateSuccess === true && deleteSuccess === true) {
      onAfterSave();
    }

  };

  //Select column components

  const DictionarySelect = React.memo((props) => {
    const {
      url,
      optionsRenderer,
      valuesDefault = [],
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      focus,
      stopEditing,
      disabled,
      additionalValues,
      defaultValue = null,
      multi,
      active,
    } = props;
    const [values, setValues] = useState([]);
    const [error, setError] = useState(false);


    const selectRef = useRef();

    const [disabledField, setDisabledField] = useState(false);

    useEffect(() => {
      if (name === 'id') {
        setDisabledField(true);
      } else {
        setDisabledField(disabled);
      }
    }, [data, disabled]);


    useEffect(() => {
      setError(isError(errors, rowIndex, name));
    }, [errors]);

    const formatValues = (values = []) => {
      let result = [];
      for (let val of values) {
        result.push({ value: val.id, label: getExistLangValue(val.name) });
      }
      setValues(result);
    };

    useEffect(() => {
      if (url && typeof url === 'function') {
        dictionaryService.getDictionary(url(), false).then(response => {
          formatValues(response);
        });
      }
    }, [url]);


    useEffect(() => {
      if (valuesDefault && valuesDefault.length > 0) {
        setValues(valuesDefault);
      }
    }, [valuesDefault]);


    const defaultRenderer = (option) => {
      if (option) {
        return <div>{option.label}</div>;
      }
      return '-';
    };

    const onSearch = (option, input) => {
      if (input && input.length > 1) {
        return option.label.toLowerCase().includes(input.toLowerCase());
      }
      return true;
    };

    const onChange = (value) => {
      console.log('VALUE: ', value);
      if (value) {
        setRowData(value);
        setTimeout(() => stopEditing({ nextRow: false }), 200);
        if (name === 'seaLineId' || name === 'toHubId') {
          setRerender(true);
        }
      }
    };

    useLayoutEffect(() => {
      if (selectRef && selectRef.current) {
        if (focus) {
          selectRef.current.focus();
        } else {
          selectRef.current.blur();
        }
      }
    }, [focus]);

    useLayoutEffect(() => {
      if (selectRef && selectRef.current) {
        if (active) {
          selectRef.current.blur();
        }
      }
    }, [active]);

    const filterValues = (options, n) => {
      switch (n) {
        case 'freightId': {
          return options.filter(v => data[rowIndex] && data[rowIndex].seaLineId && data[rowIndex].seaLineId.value === v.seaLine);
        }
        default:
          return options;
      }
    };

    let options = filterValues(values, name);

    return (
      <Select
        isMulti={multi}
        classNamePrefix={'Select5V'}
        ref={selectRef}
        menuIsOpen={focus}
        menuPortalTarget={document.body}
        placeholder={<FormattedMessage id={'form.select.defaultPlaceholder'}/>}
        className={'w-100 text-left Select5V no-border small'}
        filterOption={onSearch}
        noOptionsMessage={() => <FormattedMessage id={'form.select.noResultText'}/>}
        options={options}
        value={rowData ? rowData : defaultValue}
        isDisabled={disabledField}
        isClearable={false}
        onChange={onChange}
        onMenuClose={() => setTimeout(e => stopEditing({ nextRow: false }), 200)}
        formatOptionLabel={optionsRenderer ? optionsRenderer : defaultRenderer}
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: error ? 'rgba(255,119,119,0.2)' : 'transparent',
          }),
        }}
      />
    );
  });

  const SelectDeparturePortIdPortHubComponent = (data) => {
    return <DictionarySelect {...data} name={'departurePortId'} optionsRenderer={renderOptions}
                             valuesDefault={ports}/>;
  };

  const SelectDestinationPortIdPortHubComponent = (data) => {
    return <DictionarySelect {...data} name={'destinationPortId'} optionsRenderer={renderOptions}
                             valuesDefault={ports}/>;
  };

  const SelectSeaLineComponent = (data) => {
    return <DictionarySelect {...data} name={'seaLineId'} valuesDefault={seaLines}/>;
  };

  const SelectSeaSurchargesComponent = (data) => {
    return <DictionarySelect {...data} optionsRenderer={renderFreight} multi={true} name={'freightId'}
                             valuesDefault={seaTariffSurcharges}/>;
  };

  const SelectFreightConditionComponent = (data) => {
    return <DictionarySelect {...data} name={'freightCondition'} valuesDefault={typeFreight}/>;
  };

  const SelectCurrencyComponent = (data) => {
    return <DictionarySelect {...data} name={'currency'} valuesDefault={currincies}/>;
  };

  const SelectDepartureAirportIdHubComponent = (data) => {
    return <DictionarySelect {...data} name={'fromHubId'}
                             optionsRenderer={(option) => renderOptions(option, 'AIRPORT_HUB')}
                             valuesDefault={airHubs}/>;
  };
  const SelectDestinationAirportIdHubComponent = (data) => {
    return <DictionarySelect {...data} name={'toHubId'}
                             optionsRenderer={(option) => renderOptions(option, 'AIRPORT_HUB')}
                             valuesDefault={airHubs}/>;
  };

  const SelectAirCarrierComponent = (data) => {
    return <DictionarySelect {...data} name={'aviaCompanyId'}
                             optionsRenderer={(option) => renderOptions(option, 'DEFAULT')}
                             valuesDefault={aviaCompanies}/>;
  };

  const SelectTypeCargoComponent = (data) => {
    return <DictionarySelect {...data} name={'typeCargo'} optionsRenderer={(option) => renderOptions(option, 'DEFAULT')}
                             valuesDefault={typeCargo}/>;
  };

  const SelectAirSurchargesComponent = (data) => {
    return <DictionarySelect {...data} name={'surcharges'} valuesDefault={[]}/>;
  };

  const initWeight = (name, rowIndex, value) => {
    /*setTimeout(()=>{
        if(name==="cargoContainerRates[0].price" && get(data, `[${rowIndex}].weight20`, null)===null){
            const newData = cloneDeep(data);
            set(newData, `[${rowIndex}].freight20`, value)
            set(newData, `[${rowIndex}.cargoContainerRates[0].price]`, value);
            set(newData, `[${rowIndex}.weight20]`, 20000);
            set(newData, `[${rowIndex}.cargoContainerRates[0].maxWeight]`, 20000);
            setData(newData)
            setTimeout(()=>{
                setRerender(true)
            }, 100)
        }
        if(name==="cargoContainerRates[1].price" && get(data, `[${rowIndex}].weight40`, null)===null){
            const newData = cloneDeep(data);
            set(newData, `[${rowIndex}].freight40`, value)
            set(newData, `[${rowIndex}.cargoContainerRates[1].price]`, value);
            set(newData, `[${rowIndex}.weight40]`, 20000);
            set(newData, `[${rowIndex}.cargoContainerRates[1].maxWeight]`, 20000);
            setData(newData)
            setTimeout(()=>{
                setRerender(true)
            }, 100)
        }
        if(name==="cargoContainerRates[2].price" && get(data, `[${rowIndex}].weight40HC`, null)===null){
            const newData = cloneDeep(data);
            set(newData, `[${rowIndex}].freight40HC`, value)
            set(newData, `[${rowIndex}.cargoContainerRates[2].price]`, value);
            set(newData, `[${rowIndex}.weight40HC]`, 20000);
            set(newData, `[${rowIndex}.cargoContainerRates[2].maxWeight]`, 20000);
            setData(newData)
            setTimeout(()=>{
                setRerender(true)
            }, 100)
        }
    }, 200)*/

  };

  //!Select column components

  //Integer column components

  const IntegerColumnInput = React.memo((props) => {
    const { columnData, rowData, setRowData, placeholder = '', rowIndex, name, className, focus, disabled } = props;

    const inputRef = useRef();

    const [error, setError] = useState(false);

    const [disabledField, setDisabledField] = useState(false);

    const [value, setValue] = useState(rowData);

    useEffect(() => {
      if (name === 'id') {
        setDisabledField(true);
      } else {
        setDisabledField(disabled);
      }
    }, [data, disabled]);

    const onChange = (columnData) => {
      setRowData(name === 'transitTime' ? parseInt(columnData) : parseFloat(columnData));
      if (name === 'cargoContainerRates[0].price') {
        initWeight(name, rowIndex, parseFloat(columnData));
      }
      if (name === 'cargoContainerRates[1].price') {
        initWeight(name, rowIndex, parseFloat(columnData));
      }
      if (name === 'cargoContainerRates[2].price') {
        initWeight(name, rowIndex, parseFloat(columnData));
      }
    };

    useEffect(() => {
      setError(isError(errors, rowIndex, name));
    }, [errors]);

    useEffect(() => {
      setValue(rowData === null ? '' : rowData);
    }, [rowData]);

    useEffect(() => {
      if (inputRef && inputRef.current) {
        if (focus === true) {
          inputRef.current.focus();
        } else {
          inputRef.current.blur();
        }
      }
    }, [focus]);


    return <span className="react-numeric-input">
        <input
          type={'number'}
          value={value}
          disabled={disabledField}
          placeholder={name === 'id' ? translate({ id: 'crudForm.field.id.placeholder' }) : translate({ id: 'common.placeholder' })}
          onChange={(ev) => onChange(ev.target.value)}
          className="form-control"
          ref={inputRef}
          style={error ? { backgroundColor: 'rgba(255,119,119,0.2)', border: 'none' } : {
            border: 'none',
            backgroundColor: 'transparent',
          }}
        />
      </span>;
  });

  const IntColumn = (data) => {
    return <IntegerColumnInput {...data} name={data.name}/>;
  };

  const IdColumn = (data) => {
    set(data, 'name', 'id');
    return <IntColumn {...data} name={'id'}/>;
  };

  const TransitTimeColumn = (data) => {
    set(data, 'name', 'transitTime');
    return <IntColumn {...data} name={'transitTime'}/>;
  };

  const DemurrageTimeColumn = (data) => {
    set(data, 'name', 'demurrage');
    return <IntColumn {...data} name={'demurrage'}/>;
  };

  const DetentionTimeColumn = (data) => {
    set(data, 'name', 'detention');
    return <IntColumn {...data} name={'detention'}/>;
  };

  const DocumentDeadlineTimeColumn = (data) => {
    set(data, 'name', 'documentDeadline');
    return <IntColumn {...data} name={'documentDeadline'}/>;
  };

  const Freight20Column = (data) => {
    set(data, 'name', 'cargoContainerRates');
    return <IntColumn {...data} name={'cargoContainerRates[0].price'}/>;
  };

  const Freight40Column = (data) => {
    set(data, 'name', 'cargoContainerRates');
    return <IntColumn {...data} name={'cargoContainerRates[1].price'}/>;
  };

  const Freight40HCColumn = (data) => {
    set(data, 'name', 'cargoContainerRates');
    return <IntColumn {...data} name={'cargoContainerRates[2].price'}/>;
  };

  const Weight20Column = (data) => {
    set(data, 'name', 'cargoContainerRates');
    if (get(data, 'cargoContainerRates[0].price', null) !== null) {
      set(data, 'cargoContainerRates[0].maxWeight', 20000);
    }
    return <IntColumn {...data} name={'cargoContainerRates[0].maxWeight'}/>;
  };

  const Weight40Column = (data) => {
    set(data, 'name', 'cargoContainerRates');
    return <IntColumn {...data} name={'cargoContainerRates[1].maxWeight'}/>;
  };

  const Weight40HCColumn = (data) => {
    set(data, 'name', 'cargoContainerRates');
    return <IntColumn {...data} name={'cargoContainerRates[2].maxWeight'}/>;
  };

  const RateColumn = (data) => {
    return <IntColumn {...data}/>;
  };

  //!Integer column components

  //Date column components
  const DateInputColumn = (props) => {
    const {
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name = '',
      className,
      focus,
      stopEditing,
      disabled,
    } = props;

    const [error, setError] = useState(false);

    const [disabledField, setDisabledField] = useState(false);

    useEffect(() => {
      if (name === 'id') {
        setDisabledField(true);
      } else {
        setDisabledField(disabled);
      }
    }, [data, disabled]);

    const onChangeValue = (val, operations) => {
      if (val) {
        setRowData(val.format(Array.isArray(DATE_FORMAT.ru) ? DATE_FORMAT.ru[0] : DATE_FORMAT.ru));
        stopEditing({ nextRow: true });
      } else {
        setRowData(null);
      }
    };

    useEffect(() => {
      setError(isError(errors, rowIndex, name));
    }, [errors]);

    return <div className={`f-element f-element-date no-border ${error ? 'error' : ''}`}>
      <DatePicker
        disabled={disabledField}
        autoFocus={false}
        locale={LANG_CODE_FULL[AppStore.userLang]}
        name={name}
        onChange={onChangeValue}
        placeholderText={translate({ id: 'common.selectDate' })}
        selected={rowData != null ? moment(rowData, 'YYYY-MM-DD') : null}
        dateFormat={DATE_FORMAT[AppStore.userLang]}
        autoComplete={'off'}
        style={error ? { backgroundColor: 'rgba(255,119,119,0.2)' } : { backgroundColor: 'transparent' }}
        dropdownMode="select"
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          },
        }}
      />
    </div>;
  };

  const DateFromColumn = (data) => {
    return <DateInputColumn {...data} name={'dateFrom'}/>;
  };

  const DateToColumn = (data) => {
    return <DateInputColumn {...data} name={'dateTo'}/>;
  };

  //!Date column components

  //Readonly column component
  const ReadOnlyColumnComponent = (props) => {
    const {
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name = '',
      className,
      focus,
      stopEditing,
      disabled,
    } = props;

    const [error, setError] = useState(false);

    const [disabledField, setDisabledField] = useState(true);

    const [value, setValue] = useState('');
    useEffect(() => {
      let findValue = '';
      if (name === 'fromHubId.city') {
        const value = get(data[rowIndex], 'fromHubId', null);
        findValue = airHubs.find((e, i, a) => e.value === value?.value)?.city?.name;
      }
      if (name === 'fromHubId.country') {
        const value = get(data[rowIndex], 'fromHubId', null);
        findValue = airHubs.find((e, i, a) => e.value === value?.value)?.country?.name;
      }
      if (name === 'toHubId.city') {
        const value = get(data[rowIndex], 'toHubId', null);
        findValue = airHubs.find((e, i, a) => e.value === value?.value)?.city?.name;
      }
      if (name === 'toHubId.country') {
        const value = get(data[rowIndex], 'toHubId', null);
        findValue = airHubs.find((e, i, a) => e.value === value?.value)?.country?.name;
      }
      if (findValue) {
        setValue(getExistLangValue(findValue).split(',')[0]);
      } else {
        setValue('');
      }
    }, []);

    useEffect(() => {
      if (value && value.length > 0) {
        setRowData(value);
      }
    }, [value]);

    return <div className={'f-element f-element-text p-2'}>
      <div>{value ? value :
        <span className={'d-flex'} style={{ color: 'hsl(0, 0%, 50%)', lineHeight: 1, fontSize: 13 }}><FormattedMessage
          id={placeholder}/></span>}</div>
    </div>;
  };

  const ReadOnlyColumnFromHubIdCity = (props) => {
    return <ReadOnlyColumnComponent {...props} name={'fromHubId.city'}/>;
  };
  const ReadOnlyColumnFromHubIdCountry = (props) => {
    return <ReadOnlyColumnComponent {...props} name={'fromHubId.country'}/>;
  };

  const ReadOnlyColumnToHubIdCity = (props) => {
    return <ReadOnlyColumnComponent {...props} placeholder={'table.tariffs.fillAuto'} name={'toHubId.city'}/>;
  };
  const ReadOnlyColumnToHubIdCountry = (props) => {
    return <ReadOnlyColumnComponent {...props} placeholder={'table.tariffs.fillAuto'} name={'toHubId.country'}/>;
  };

  //!Readonly column component

  //Checkbox column

  const CheckboxColumn = (props) => {
    const {
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name = '',
      className,
      focus,
      stopEditing,
      disabled,
    } = props;

    const [error, setError] = useState(false);

    const onChangeValue = (e) => {
      setRowData(e.target.checked);
    };

    return <div className={'f-element f-element-select-box p-2 w-100 d-flex justify-content-center'}>
      <Input
        type="checkbox"
        onChange={onChangeValue}
        checked={rowData}
        disabled={disabled}
      />
    </div>;
  };

  const TerminalProcessingColumn = (data) => {
    return <CheckboxColumn {...data} name={'terminalProcessing'}/>;
  };

  //!Checkbox column

  //Text input column
  const TextInputColumn = (props) => {
    const {
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name = '',
      className,
      focus,
      stopEditing,
      disabled,
    } = props;
    const [error, setError] = useState(false);
    const inputRef = useRef(null);

    const onChangeValue = (e) => {
      setRowData(e.target.value);
    };

    useEffect(() => {
      if (focus) {
        inputRef?.current?.focus();
      } else {
        inputRef?.current?.blur();
      }
    }, [focus]);

    return <div className={'f-element f-element-text w-100'}>
      <Input
        style={{ border: 'none' }}
        innerRef={inputRef}
        type="text"
        onChange={onChangeValue}
        value={rowData}
        disabled={disabled}
      />
    </div>;

  };
  //!Text input column

  const onPasteAirHubSelect = (value, airHubs, rowData, rowIndex) => {
    const result = checkAccessAllowPaste(rowIndex) ? onPasteValueAirHubSelect(value, airHubs, rowData, rowIndex) : rowData;
    setTimeout(() => {
      setRerender(true);
    }, 200);
    return result;
  };

  const [columns, setColumns] = useState([]);

  const generateRateColumns = () => {
    let resultColumns = [];

    const model = createTariffItem(type, airWeightRanges);
    const rateColumns = Object.keys(model).filter(v => v.includes('rate_'));
    for (let i = 0; i < rateColumns.length; i++) {
      let name = rateColumns[i];
      resultColumns.push(
        keyColumn(
          name,
          {
            component: (p) => <RateColumn {...p} name={name}/>,
            title: `${name.split('_')[1]}+`,
            minWidth: 150,
            copyValue: ({ rowData }) => onCopyValueRate(rowData),
            pasteValue: ({
              rowData,
              value,
              rowIndex,
            }) => checkAccessAllowPaste(rowIndex) ? onPasteValueRate(value, rowData, rowIndex) : rowData,
            disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
          },
        ),
      );
    }

    return resultColumns;
  };

  const columnsByTariff = {
    SEA_TARIFFS: [
      keyColumn(
        'departurePortId',
        {
          component: SelectDeparturePortIdPortHubComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.departurePort.title'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, ports),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, ports, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'destinationPortId',
        {
          component: SelectDestinationPortIdPortHubComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.destinationPort.title'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, ports),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, ports, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'seaLineId',
        {
          component: SelectSeaLineComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.seaLine.title'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, seaLines),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, seaLines, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'freightId',
        {
          component: SelectSeaSurchargesComponent,
          headerClassName: '',
          title: <FormattedMessage id={'crudForm.field.surcharges'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData, rowIndex }) => onCopyValueSelect(rowData, seaTariffSurcharges),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelectSurcharges(value, seaTariffSurcharges, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'freightCondition',
        {
          component: SelectFreightConditionComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.freightCondition.title'}/>,
          minWidth: 150,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, typeFreight),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, typeFreight, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'transitTime',
        {
          component: TransitTimeColumn,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.transitTime.title'}/>,
          minWidth: 160,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'demurrage',
        {
          component: DemurrageTimeColumn,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.demurrage.title'}/>,
          minWidth: 160,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'detention',
        {
          component: DetentionTimeColumn,
          title: <FormattedMessage id={'crudForm.field.detention'}/>,
          minWidth: 160,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'documentDeadline',
        {
          component: DocumentDeadlineTimeColumn,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.documentDeadline.title'}/>,
          minWidth: 200,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'currency',
        {
          component: SelectCurrencyComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.currency.title'}/>,
          minWidth: 150,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, currincies),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, currincies, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'freight20',
        {
          component: Freight20Column,
          title: <FormattedMessage id={'crud.field.freight20.title'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'weight20',
        {
          component: Weight20Column,
          title: <FormattedMessage id={'crudForm.field.weightLimit'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'freight40',
        {
          component: Freight40Column,
          title: <FormattedMessage id={'crud.field.freight40.title'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'weight40',
        {
          component: Weight40Column,
          title: <FormattedMessage id={'crudForm.field.weightLimit'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'freight40HC',
        {
          component: Freight40HCColumn,
          title: <FormattedMessage id={'crud.field.freight40HC.title'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'weight40HC',
        {
          component: Weight40HCColumn,
          title: <FormattedMessage id={'crudForm.field.weightLimit'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'commentDescription',
        {
          component: (p) => <TextInputColumn {...p} name={'commentDescription'}/>,
          title: <FormattedMessage id={'crud.field.commentDescription.title'}/>,
          minWidth: 200,
          keepFocus: true,
          disableKeys: false,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
    ],
    AIR_TARIFFS: [
      keyColumn(
        'transitTime',
        {
          component: TransitTimeColumn,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.transitTime.title'}/>,
          minWidth: 160,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'toHubId',
        {
          component: SelectDestinationAirportIdHubComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.code.title'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, airHubs),
          deleteValue: ({ rowData, rowIndex }) => {
            setRerender(true);
            return null;
          },
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => onPasteAirHubSelect(value, airHubs, rowData, rowIndex),
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),

      keyColumn(
        'city',
        {
          component: ReadOnlyColumnToHubIdCity,
          title: <FormattedMessage id={'crud.field.city.title'}/>,
          minWidth: 150,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData, rowIndex }) => onCopyValueReadonly('toHubId.city', data, airHubs, rowIndex),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => rowData,
          disabled: ({ rowIndex }) => true,
        },
      ),
      keyColumn(
        'country',
        {
          component: ReadOnlyColumnToHubIdCountry,
          title: <FormattedMessage id={'crud.field.country.title'}/>,
          minWidth: 150,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData, rowIndex }) => onCopyValueReadonly('toHubId.country', data, airHubs, rowIndex),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => rowData,
          disabled: ({ rowIndex }) => true,
        },
      ),
      keyColumn(
        'fromHubId',
        {
          component: SelectDepartureAirportIdHubComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.origin.title'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, airHubs),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueAirHubSelect(value, airHubs, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'aviaCompanyId',
        {
          component: SelectAirCarrierComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.aviaCompany.title'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, aviaCompanies),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, aviaCompanies, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'typeCargo',
        {
          component: SelectTypeCargoComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.product.title'}/>,
          minWidth: 250,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, typeCargo),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueTypeCargoSelect(value, typeCargo, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'currency',
        {
          component: SelectCurrencyComponent,
          headerClassName: 'cell-required',
          title: <FormattedMessage id={'crud.field.currency.title'}/>,
          minWidth: 150,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, currincies),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, currincies, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'minPrice',
        {
          component: (p) => <RateColumn {...p} name={'minPrice'}/>,
          title: <FormattedMessage id={'crud.field.minPrice.title'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueRate(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueRate(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      ...generateRateColumns(),
      keyColumn(
        'terminalProcessing',
        {
          component: TerminalProcessingColumn,
          title: <FormattedMessage id={'crud.field.terminalProcessing.title'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueCheckbox(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
      keyColumn(
        'surcharges',
        {
          component: SelectAirSurchargesComponent,
          title: <FormattedMessage id={'crud.field.surcharges.title'}/>,
          minWidth: 150,
          keepFocus: true,
          disableKeys: true,
          copyValue: ({ rowData }) => onCopyValueSelect(rowData, currincies),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => false && checkAccessAllowPaste(rowIndex) ? onPasteValueSelect(value, currincies, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => true,
        },
      ),
      keyColumn(
        'commentDescription',
        {
          component: (p) => <TextInputColumn {...p} name={'commentDescription'}/>,
          title: <FormattedMessage id={'crud.field.commentDescription.title'}/>,
          minWidth: 200,
          keepFocus: true,
          disableKeys: false,
          copyValue: ({ rowData }) => onCopyValueText(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueText(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        },
      ),
    ],
  };

  useMemo(() => {
    let condition = false;
    switch (type) {
      case 'SEA_TARIFFS':
        condition = seaLines.length > 0 && currincies.length > 0 && ports.length > 0 && typeFreight.length > 0;
        break;
      case 'AIR_TARIFFS':
        condition = currincies.length > 0 && airHubs.length > 0 && aviaCompanies.length > 0 && typeCargo.length > 0;
        break;
    }

    if (condition) {
      let defaultColumnsArray = [];
      if (data.filter(d => d.id !== null).length > 0) {
        defaultColumnsArray.push(
          keyColumn(
            'id',
            {
              component: IdColumn,
              headerClassName: '',
              title: <FormattedMessage id={'crud.field.id.title'}/>,
              minWidth: 100,
              copyValue: ({ rowData }) => onCopyValueText(rowData),
              disabled: () => true,
            },
          ));
      }

      defaultColumnsArray.push(keyColumn('dateFrom', {
          component: DateFromColumn,
          headerClassName: 'cell-required',
          keepFocus: true,
          title: <FormattedMessage id={'crudForm.field.from'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueDate(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueDate(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        }),
        keyColumn('dateTo', {
          component: DateToColumn,
          headerClassName: 'cell-required',
          keepFocus: true,
          title: <FormattedMessage id={'crudForm.field.to'}/>,
          minWidth: 150,
          copyValue: ({ rowData }) => onCopyValueDate(rowData),
          pasteValue: ({
            rowData,
            value,
            rowIndex,
          }) => checkAccessAllowPaste(rowIndex) ? onPasteValueDate(value, rowData, rowIndex) : rowData,
          disabled: ({ rowIndex }) => checkDisabledField(rowIndex),
        }));

      setColumns([
        ...defaultColumnsArray,
        ...columnsByTariff[type],
      ]);
      setIsLoad(false);
      setRerender(false);
    }
  }, [seaLines, currincies, ports, typeFreight, errors, rerender, seaTariffSurcharges, airHubs, aviaCompanies, typeCargo]);


  return (
    <div className={'table-tariffs'}>
      {(isLoad === false) &&
        <DynamicDataSheetGrid
          addRowsComponent={createAddRowsComponent()}
          value={data}
          onChange={onChange}
          columns={columns}
          height={800}
          contextMenuComponent={ContextMenu}
          createRow={() => createTariffItem(type, airWeightRanges)}
          duplicateRow={({ rowData }) => (onDuplicate(rowData))}
        />
      }
      {isLoad === true &&
        <div style={{ minHeight: '500px' }} className={'d-flex align-items-center justify-content-center'}>
          <div className="fa-1x">
            <i className="fa fa-spinner fa-spin" style={{ fontSize: '36px' }}/>
          </div>
        </div>
      }
      <FormGroup className="btn-controls mt-3">
        <Row className="no-gutters" style={{ justifyContent: 'flex-start' }}>
          <Button disabled={isLoad} className={'btn-primary-new'} onClick={onSave}>

            <div className={'d-flex'}>
              {isLoad === true && <div className="fa-1x">
                <i className="fa fa-spinner fa-spin" style={{ color: '#fff' }}/>
              </div>}

              <div className={`${isLoad === true ? 'ml-2' : ''}`}><FormattedMessage
                id="crudForm.button.save"/></div>
            </div>
          </Button>
          <Button disabled={isLoad} className={"btn-outline-primary-new"} onClick={onCancel}><FormattedMessage
            id="crudForm.button.cancel"/></Button>
        </Row>
      </FormGroup>
    </div>
  )
}

export default TableTariffs;
