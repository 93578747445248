import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isArray, isEmpty } from 'lodash';
import { DynamicDataSheetGrid, keyColumn, textColumn } from 'react-datasheet-grid';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select';
import userStore from '../../../stores/userStore';
import DictionaryService from '../../../services/dictionaryService';
import {getExistLangValue, translate} from '../../../utils/utils';
import { Popover, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { Checkbox, NumberInput, TextInput } from '../form-control';
import Link from './Link';
import { emptyComponent } from './TableContainersUtils';
import InputMask from "react-input-mask";
const ContainerNumberInput = ({onChange, value, disabled, type, inputRef, style, error, name, id})=>{

  return <InputMask
    autoComplete={"off"}
    mask={type?.id === 1 ? 'aaaa9999999' : 'aaaa9999999'}
    onChange={(ev) => onChange(ev)}
    value={value}
    maskChar={null}
    disabled={false}
  >
    {(inputProps) => <input
      {...inputProps}
      ref={inputRef}
      id={id}
      type={"text"}
      placeholder={name === "containerId" ? translate({id: "orderForm.idContainer"}) : translate({id: "common.placeholder"})}
      className="form-control small"
      style={error ? {backgroundColor: "rgba(255,119,119,0.2)", border: "none"} : {
        border: "none",
        backgroundColor: "transparent"
      }}
    />}
  </InputMask>
}
const TableContainers = (props) => {

  const {items, orderDelivery, isPutTruckInfo} = props;
  const [data, setData] = useState([])
  const [containersTypes, setContainersTypes] = useState([])
  const [rerender, setRerender] = useState(false)


  // Структура строки
  // containerId - Номер контейнера
  // type.formatName - Тип контейнера
  // weight - Значение веса
  // weightUnit - Единица веса
  // item.deliveryTruckDetail.truckNumber - Гос. номер грузовика
  // item.deliveryTruckDetail.driverName - ФИО Водителя
  // item.deliveryTruckDetail.driverMobile - Телефон водителя
  // status - Статус

  const formatValues = (values = []) => {
    let result = []
    for (let val of values) {
      result.push({value: val.id, label: getExistLangValue(val.name)})
    }
    return result;
  }

  const isShowLinkTrackingPage = (index) => {
    const {items} = props;
    if (items && items[index]?.trackingPageUrl) {
      return true;
    } else return false;
  }

  const canEditItem=(index)=>{
    const item = data[index];
    if(orderDelivery?.fake !== true)
      return true
    if(item?.idCargo===null && userStore.isManagerOrAdminCargobar())
      return false;
    return true;
  }

  const canEditStatus = (index) => {
    const item = data[index];

    return !item?.isEditManualStatus;
  }

  const SelectComponent = (data) => {

    const {
      rowData,
      setRowData,
      focus,
      stopEditing,
      disabled,
      active,
      defaultValue= null,
      rowIndex
    } = data

    const [values, setValues] = useState([])


    useEffect(() => {
      DictionaryService.getCargoContainerTypes('COMMON')
        .then((response) => {
          if (isEmpty(response)) {
            return;
          }
          setValues(response);
        })
    }, [containersTypes]);

    const value = {
      value: rowData.id,
      label: rowData.formatName,
    }

    const onChange = (e) => {
      setRowData(containersTypes.find(container => container.id === e.value));
      setRerender(true)
    }

    return (
      <Select
        disabled={disabled}
        placeholder={<FormattedMessage id={"crud.filter.cargoContainerType"}/>}
        noOptionsMessage={() => <FormattedMessage id={"form.select.noResultText"}/>}
        value={value}
        options={formatValues(values)}
        isClearable={false}
        classNamePrefix={"Select5V"}
        className={"w-100 text-left Select5V no-border small"}
        onChange={onChange}
        onMenuClose={() => setTimeout(e => stopEditing({nextRow: false}), 200)}
        menuIsOpen={focus}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: base => ({...base, zIndex: 9999}),
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
          }),
        }}
      />
    )
  }

  const CheckBoxColumn = (props) => {
    const {
      rowData,
      setRowData,
    } = props

    const onChange = (ev) => {
      setRowData(ev.target.checked)
      setRerender(true)
    }

    return  <div style={{margin: "60px"}}>
      <Checkbox
        checked={rowData}
        onChange={ev => onChange(ev)}
        disabled={false}
      /></div>
  }

  const TextInputColumn = (cellProps) => {
    const {
      active,
      addId,
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      className,
      focus,
      stopEditing,
      disabled,
      typeContainer
    } = cellProps
    const [idField, setIdField] = useState(`element-table-${rowIndex}-${name}`)
    const [error, setError] = useState(false)
    const inputRef = useRef()

    useEffect(() => {
      setIdField(`element-table-${rowIndex}-${name}`)
    }, [name])

    useEffect(()=>{
      console.log("FOCUS", focus)
      if (inputRef && inputRef.current) {
        if (focus === true) {
          inputRef.current.focus();
        } else {
          inputRef.current.blur();
        }
      }
    }, [focus])

    const onChange = (ev) => {
      setRowData(ev.target.value)
    }

    return <div>
      <div id={idField} className={"f-element f-element-text"}>
        {name === "containerId" ?
          <ContainerNumberInput style={{border: "none"}}
                                onChange={onChange}
                                inputRef={inputRef}
                                value={rowData}
                                disabled={false}
                                type={typeContainer}
                                name={name}
                                id={idField}
                                error={error}
          />
          :
          <input
            autoComplete={"off"}
            id={idField}
            type={"text"}
            value={rowData}
            disabled={false}
            placeholder={name === "containerId" ? translate({id: "orderForm.idContainer"}) : translate({id: "common.placeholder"})}
            onChange={(ev) => onChange(ev)}
            className="form-control small"
            ref={inputRef}
            style={error ? {backgroundColor: "rgba(255,119,119,0.2)", border: "none"} : {
              border: "none",
              backgroundColor: "transparent"
            }}
          />
        }
      </div>
      {
        ((focus || active) && name === "containerId" && document.getElementById(idField) && rowData && isShowLinkTrackingPage(rowIndex)) ?
          <UncontrolledPopover
            className={"z-index-9999"}
            placement="left"
            target={idField}
            trigger="legacy click"
          >
            <PopoverBody>
              <Link items={items} index={rowIndex} />
            </PopoverBody>
          </UncontrolledPopover>
          :(name === "containerId" && document.getElementById(idField)) && focus &&
          <UncontrolledPopover
            className={"z-index-9999"}
            placement="left"
            target={idField}
            trigger="legacy click"
          >
            <PopoverBody>
              {typeContainer?.id===1?<FormattedMessage id={"containerId.40.hint"}/>:<FormattedMessage id={"containerId.40.hint"}/>}
            </PopoverBody>
          </UncontrolledPopover>
      }
    </div>

  }
  const TextInputColumnStatus = (props) => {
    const {
      active,
      addId,
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      className,
      focus,
      stopEditing,
      disabled
    } = props
    const [idField, setIdField] = useState(`element-table-${rowIndex}-${name}`)
    const [error, setError] = useState(false)
    const inputRef = useRef(null)

    useEffect(() => {
      setIdField(`element-table-${rowIndex}-${name}`)
    }, [name])

    useEffect(() => {
      if (focus) {
        inputRef?.current?.focus()
      } else {
        inputRef?.current?.blur()
      }
    }, [focus])

    const onChange = (ev) => {
      setRowData(ev.target.value)
    }
    return <input
      autoComplete={"off"}
      id={idField}
      type={"text"}
      value={rowData}
      disabled={canEditStatus(rowIndex)}
      placeholder={name === "containerId" ? translate({id: "orderForm.idContainer"}) : translate({id: "common.placeholder"})}
      onChange={(ev) => onChange(ev)}
      className="form-control small"
      ref={inputRef}
      style={error ? {backgroundColor: "rgba(255,119,119,0.2)", border: "none",width: "300px"} : {
        border: "none",
        backgroundColor: "transparent",
        width: "300px"
      }}
    />
  }

  const GetStatusColumn = (data) => {
    const {
      active,
      addId,
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      className,
      focus,
      stopEditing,
      disabled
    } = data
    const [idField, setIdField] = useState(`element-table-${rowIndex}-${name}`)
    const [error, setError] = useState(false)
    const inputRef = useRef(null)
    const {onChange} = props;

    return (<div>
        <div id={idField} className={"f-element f-element-text w-100"} style={{border: "none", whiteSpace: "nowrap"}}>
          { rowData ?
            <a>{rowData.substring(0, 35)}{rowData.length > 35 ? "..." : ""}</a> : " - "}
        </div>
        {document.getElementById(idField) && rowData &&
          <Popover
            className={"z-index-9999"}
            placement="left"
            isOpen={active || focus}
            target={idField}>
            <PopoverBody>{rowData}</PopoverBody>
          </Popover>}
      </div>
    );
  }

  const IntegerColumnInput = (data) => {
    const {
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      className,
      focus,
      disabled
    } = data

    const onChange = (value) => {
      if(value) {
        setRowData(value)
        setRerender(true)
      }
    }

    return(
      <NumberInput
        style={{border:"none"}}
        disabled={(orderDelivery?.fake !== true)}
        placeholder="orderForm.modal.cargoWeight"
        onChange={onChange}
        value={rowData}
      />)
  }

  const getTruckInfoColumns = () => {
    if (isPutTruckInfo) {
      return [
        keyColumn(
          'truckNumber',
          {
            component: (p)=><TextInputColumn {...p} name={'deliveryTruckDetail.truckNumber'}/>,
            title: <FormattedMessage id="orderForm.modal.stateTruckNumber"/>,
            minWidth: 150,
          },
        ),
        keyColumn('driverName',
          {
            component: (p)=><TextInputColumn {...p} name={'deliveryTruckDetail.driverName'}/>,
            title: <FormattedMessage id="orderForm.modal.driver"/>,
            minWidth: 200,
          }),
        keyColumn('driverMobile',
          {
            component: (p)=><TextInputColumn {...p} name={'deliveryTruckDetail.driverMobile'}/>,
            title: <FormattedMessage id="orderForm.modal.driverPhone"/>,
            minWidth: 200,
          })
      ]
    } else return [];
  }

  const [columns, setColumns] = useState([]);
  useMemo(() => {
    setColumns([
      keyColumn('containerId',
        {
          component: (p) => <TextInputColumn {...p} typeContainer={data[p.rowIndex]?.type} name={"containerId"} />,
          title: <FormattedMessage id="orderForm.idContainer"/>,
          minWidth: 180,
          disabled: false,
        }),
      keyColumn('isEditManualStatus',
        {
          component: (p)=><CheckBoxColumn {...p} name={'isEditManualStatus'} />,
          title: <FormattedMessage id="orderForm.enableAutoTrackingSystem"/>,
          minWidth: 140,
          disabled: false,
        }),
      keyColumn('statusTracking',
        {
          component: (p)=><TextInputColumnStatus {...p} name={'statusTracking'} />,
          title: <FormattedMessage id="orderForm.currentStatus"/>,
          minWidth: 300,
          disabled: (rowData) => canEditStatus(rowData.rowIndex),
        }),
      keyColumn('type',
        {
          component: SelectComponent,
          title: <FormattedMessage id="crud.filter.cargoContainerType"/>,
          headerClassName: 'cell-required',
          minWidth: 120,
          disabled:(rowData) => canEditItem(rowData.rowIndex)
        }),

      keyColumn('weight',
        {
          component: IntegerColumnInput,
          title: <FormattedMessage id="orderForm.modal.cargoWeight"/>,
          minWidth: 150,
          disabled:orderDelivery?.fake !== true
        }),
      {
        ...keyColumn('weightUnit', textColumn),
        title: <FormattedMessage id="orderForm.modal.cargoWeight"/>,
        minWidth: 100,
        disabled:orderDelivery?.fake !== true
      },
      ...getTruckInfoColumns(),
    ])
    setRerender(false)
  }, [rerender, containersTypes]);

  useEffect(() => {
    if (items && isArray(items)){
      setData(prepareItems(items))
      setRerender(true)
    }

    DictionaryService.getCargoContainerTypes('COMMON')
      .then((response) => {
        if (!isEmpty(response)) {
          setContainersTypes(response);
        }
      })
  }, []);

  const prepareItems = (items) => {
    const blocks = items.map((item, index) => {
      console.log("ITEM");
      console.log(item);
      return {
        ...item,
        isEditManualStatus: item?.editManualStatus,
         truckNumber: item.deliveryTruckDetail?.truckNumber,
        driverName: item?.deliveryTruckDetail?.driverName,
        driverMobile: item?.deliveryTruckDetail?.driverMobile,
      };
    });

    return blocks;
  };

  const {onChange} = props;

  return (
    <DynamicDataSheetGrid
      value={data}
      onChange={(e, operations) => {
        onChange(e, "updateTableContainer")
        setData(e)
        for (let operation of operations) {
          if(operation.type === "CREATE") setRerender(true)
        }
      }}
      columns={columns}
      addRowsComponent={emptyComponent()}
      disableContextMenu={true}
    />
  )
}

export default TableContainers;
