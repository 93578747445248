/* eslint-disable */

import React, {Component, Fragment, useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import PropTypes from 'prop-types';
import {isEmpty, cloneDeep, set, get} from 'lodash';
import {Button, Form, Row, Col, FormGroup} from 'reactstrap';
import FlashStore from '../../stores/flashStore';
import DictionaryService from '../../services/dictionaryService';
import OrderDeliveryService from '../../services/orderDeliveryService';
import {Modal} from './Modal';
import AppStore from '../../stores/appStore';
import {DATE_FORMAT} from '../../constants/global';
import { TextInput, NumberInput, Select, DateInput, Checkbox } from './form-control';
import {translate, addFormatNameToConstant, copyToBuffer, addFormatName, getExistLangValue} from '../../utils/utils';
import {WEIGHT_UNITS} from '../../constants/staticData';
import FontAwesome from "react-fontawesome";
import UserStore from "../../stores/userStore";
import TableContainers from './TableCargoContainers/TableContainers';
import userStore from "../../stores/userStore";
import DatePicker from "react-datepicker";
import moment from "moment";
import {Error} from "./form-control/common";


const ContainerNumberInput = ({onChange, value, disabled, type})=>{
    return <TextInput
        maskChar={null}
        mask={type.id===1? 'aaaa9999999':'aaaa9999999'}
        placeholder="orderForm.idContainer"
        onChange={onChange}
        value={value}
        type="text"
        disabled={disabled}
        tooltip={type.id===1?<FormattedMessage id={"containerId.40.hint"}/>:<FormattedMessage id={"containerId.40.hint"}/>}
    />
}

export default class LogisticModal extends Component {
    state = {
        isShown: false,
        currency: this.props.manualCost ? this.props.manualCost.currency : 'USD',
        currencies: [],
        price: this.props.manualCost ? this.props.manualCost.value : 0,
        comment: this.props.manualCostDescription,
        remarks: this.props.remarks || '',
        internalId: this.props.internalId,
        driverMobile: this.props.driverMobile,
        driverName: this.props.driverName,
        truckNumber: this.props.truckNumber,
        sealines: [],
        sealine: this.props.sealine,
        awb: this.props.awb,
        containersTypes: [],
        containers: this.props.containers,
        generals: this.props.generals,
        vehicle: this.props.vehicle?[this.props.vehicle]:[],
        documentContainer: this.props.documentContainer,
        transportDocument: this.props.transportDocument,
        containerId: this.props.containerId,
        containerNumber: this.props.item?.containerNumber,
        awbNumber: this.props.item?.awbNumber,
        railwayNumber: this.props.railwayNumber,
        shippingDatePlan: this.props.shippingDatePlan,
        shippingDateFact: this.props.shippingDateFact,
        item: this.props.item,
        isTableContainersView: true,
        isBlockContainersView: false,
        BOL: this.props.item?.bol,
        canEditCreateDate: false,
        deliveryTrackingInfo: [],
        loading: true,
        codeCargoValues: this.props.codeCargo ? addFormatName([this.props.codeCargo]) : [],
        codeCargo: this.props.codeCargo
            ? {
                ...this.props.codeCargo,
                formatName: getExistLangValue(this.props.codeCargo.name),
            }
            : null,
        costCargo: this.props.costCargo,
        errors: {},
    };

    updateState(newState, callback=()=>{}){
        setTimeout(
            ()=>{this.setState(newState, callback)}
        )
    }

    componentDidUpdate(prevProps) {
        /* if (this.props.shippingDatePlan !== prevProps.shippingDatePlan) {
             // eslint-disable-next-line react/no-did-update-set-state
             this.updateState({
                 shippingDatePlan: this.props.shippingDatePlan,
                 shippingDateFact: this.props.shippingDateFact,
             });
         }*/
        if(this.props.item !== prevProps.item){
            this.updateState({
                item: this.props.item
            });
        }
        if(this.props.item.createDate !== prevProps.item.createDate)
            this.updateState({
                item: this.props.item
            });
        if(this.props.activeTab !== prevProps.activeTab || this.props.isShow !== prevProps.isShow){
            console.log("CHANGE SHOW")
            this.updateState({
                loading: true,
            })
            OrderDeliveryService.getTrackingInfo(this.state.item?.id).then(res=>{
                this.updateState({
                    deliveryTrackingInfo: res.data,
                    loading:false
                })
            })
        }
    }

    async UNSAFE_componentWillMount() {
        this.updateState({
            loading: true
        })
        await DictionaryService.getCurrencies()
            .then((response) => {
                if (isEmpty(response)) {
                    return;
                }

                this.updateState({
                    currencies: response,
                });
            });

        await DictionaryService.getSeaLines()
            .then((response) => {
                if (isEmpty(response)) {
                    return;
                }

                this.updateState({
                    sealines: response,
                });
            });

        await DictionaryService.getCargoContainerTypes('COMMON')
            .then((response) => {
                if (isEmpty(response)) {
                    return;
                }

                this.updateState({
                    containersTypes: response,
                });
            });
        await OrderDeliveryService.getTrackingInfo(this.state.item?.id).then(res=>{
            this.updateState({
                deliveryTrackingInfo: res.data
            })
        })
        this.updateState({
            loading: false
        })

    }

    onChange = (ev, name, index, cargoType) => {
        if(this.props.onChangeItem && typeof this.props.onChangeItem === 'function')
            this.props.onChangeItem(true);
        if(name==="updateTableContainer"){
            const cloned = cloneDeep(ev);
            this.updateState({
                containers: cloned,
            })
            return;
        }

        if(name==="deleteContainer"){
            const cloned = cloneDeep(this.state.containers).filter((v,i)=>i!==index);
            this.updateState({
                containers: cloned,
            })
            return;
        }
        if(name==="addContainer"){
            const cloned = cloneDeep(this.state.containers);
            cloned.push({
                idCargo:null,
                type: this.state.containersTypes.find(container => container.id === 1),
                weight: 20000,
                weightUnit: "KG",
                deliveryTruckDetail: {truckNumber: "", driverName: "", driverMobile: ""},
            });
            this.updateState({
                containers: cloned,
            })
            return
        }
        if (name === "costCargo.value") {
            this.updateState({
                costCargo: {
                    ...this.state.costCargo,
                    value: ev,
                },
            })
            return
        }
        if (name === "costCargo.currency") {
            this.updateState({
                costCargo: {
                    ...this.state.costCargo,
                    currency: ev,
                },
            })
            return
        }
        if (index !== undefined) {
            if (cargoType === 'generals') {
                const cloned = cloneDeep(this.state.generals);
                if (name === 'trackingNumber' || name==="aviaNumber"||name==="containerNumber") set(cloned, `[${index}].${name}`, ev);
                else set(cloned, `[${index}].${name}`, +ev);
                this.updateState({
                    generals: cloned,
                });
            } else {
                const cloned = cloneDeep(this.state.containers);
                if (name === 'type') {
                    set(cloned, `[${index}].${name}`, this.state.containersTypes.find(container => container.id === ev));
                } else {
                    set(cloned, `[${index}].${name}`, ev);
                }
                this.updateState({
                    containers: cloned,
                });
            }
        } else {
            // eslint-disable-next-line no-lonely-if
            if (name === 'transportationDocument' || name === 'homeTransportationDocument') {
                this.updateState({
                    transportDocument: {
                        ...this.state.transportDocument,
                        [name]: ev,
                    },
                });
            } else {
                this.updateState({
                    [name]: ev,
                });
            }
        }
    };

    /*onChangeTrackingDate = (name, value)=>{
        let datePlan = new Date(value);
        let orderDate = new Date(this.state.item.createDate.split(' ')[0]);
        let delta = datePlan.getTime() - orderDate.getTime();
        if(delta >= 0) {
            this.updateState({
                [name]: value
            }, ()=>{
                const pointOfPickupReady = {
                    plan: this.state.shippingDatePlan,
                };
                let id = this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1]);
                if (id.toString().endsWith('#not-complete')) {
                    id = id.replace('#not-complete', '');
                }
                OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady).then(response=>{
                    if ((response.status === 'success')) {
                        FlashStore.successNow(translate({id: 'common.save.success'}), true);
                        if (this.props.onUpdateTrackingInfo && typeof this.props.onUpdateTrackingInfo === 'function') {
                            this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                        }
                    }
                });
            });
        }else{
            FlashStore.errorNow(translate({id: 'tracking.fields.error.datePlanError'}), true);
        }

    }*/

    async onSave() {
        if(this.props.onSaving && typeof this.props.onSaving === 'function')
            this.props.onSaving(true);
        let id = this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1]);
        if (id.toString().endsWith('#not-complete')) {
            id = id.replace('#not-complete', '');
        }
        if(id.toString().includes("?"))
            id = id.toString().replace("?", "");

        const manualCost = {
            currency: this.state.currency,
            value: this.state.price === 0 ? undefined : this.state.price,
        };
        const manualCostDescription = this.state.comment ? this.state.comment : '';
        const {
            internalId,
            remarks,
            trackingNumber,
            containerNumber,
            awbNumber,
            BOL
        } = this.state;
        const containers = this.state.containers && this.state.containers.map((item) => {
            const container = {
                idCargo: item.idCargo,
                containerId: item.containerId,
                typeId: item.type.id,
                weight: item.weight,
                weightUnit: item.weightUnit,
                deliveryTruckDetail: item.deliveryTruckDetail,
                cargoReadyDate: item.cargoReadyDate,
                statusTracking: item.statusTracking,
                isEditManualStatus: item.isEditManualStatus,
            };
            return container;
        });

        const generals = this.state.generals && this.state.generals.map((item) => {
            const general = {
                idCargo: item.idCargo,
                amount: item.amount,
                height: item.height,
                length: item.length,
                weight: item.weight,
                width: item.width,
                sizeUnit: item.sizeUnit,
                cubeVolume: item.cubeVolume,
                typeId: item.typeId,
            };
            return general;
        });

        const deliveryTruckDetail = {
            driverMobile: this.state.driverMobile,
            driverName: this.state.driverName,
            truckNumber: this.state.truckNumber,
        };

        const pointOfPickupReady = {
            fact: this.state.shippingDateFact,
            plan: this.state.shippingDatePlan,
        };

        //const containerNumber = this.state.containerId;

        const transportDocument = {
            transportationDocument: this.state.documentContainer.mainDocument && this.state.transportDocument.transportationDocument,
            carrierCode: this.state.sealine ? this.state.sealine : undefined,
            homeTransportationDocument: this.state.documentContainer.homeDocument ? this.state.transportDocument.homeTransportationDocument : '',
            containerNumber: this.state.containerId,
        };

        const cargoInfo = {
            containers,
            generals,
        };

        const data = {
            manualCost,
            manualCostDescription,
            deliveryTruckDetail,
            transportDocument,
            cargoInfo,
            internalId,
            bol:BOL,
            trackingNumber,
            containerNumber,
            awbNumber,
            remarks,
            codeCargo: this.state.codeCargo,
            costCargo: this.state.costCargo,
        };
        const errors = {}
        this.updateState({
            errors: {},
        })
        if (data.costCargo?.value < 1) {
            set(errors, "costCargo.value", "common.greaterThanZero")
        }

        if (Object.keys(errors).length > 0) {
            this.updateState({
                errors,
            })
            FlashStore.errorNow(translate("common.invalidForm"), true)
            if (this.props.onSaving && typeof this.props.onSaving === "function") this.props.onSaving(false)
        }
        if (Object.keys(errors).length === 0)
            await OrderDeliveryService.sendOrderTracking(id, data).then((response) => {
                if (response.status === "success") {
                    FlashStore.successNow(translate({ id: "common.save.success" }), true)
                    if (this.props.onSaving && typeof this.props.onSaving === "function") this.props.onSaving(false)
                    if (this.props.isModal === true || this.props.showSaveModal === false) this.props.onClose(true)
                    /*if (this.props.onUpdateTrackingInfo && typeof this.props.onUpdateTrackingInfo === 'function') {
                                    this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                                }*/
                } else {
                    if (this.props.onSaving && typeof this.props.onSaving === "function") this.props.onSaving(false)
                }
            })
    }

    getContainerNumber = () => {
        if (this.state.containers && this.state.containers[0].containerId) {
            return this.state.containers[0].containerId;
        }
        if (this.state.transportDocument && this.state.transportDocument.containerNumber) {
            return this.state.transportDocument.containerNumber;
        }
        return 'N/A';
    };

    async onChangeCreateDate(value) {
        const item = cloneDeep(this.state.item);
        set(item, 'createDate', value);
        this.updateState({item});
        let res = await OrderDeliveryService.updateCreateDate(item.id, value)
        this.props.onChangeCreateDate(res.data.createDate);
    }

    canEditItem=(item)=>{
        if(this.props.item?.fake !== true)
            return true
        if(item.idCargo===null && userStore.isManagerOrAdminCargobar())
            return false;
        return true;
    }

    renderContent() {

        const checkIsExistFactDate = (type="") =>{
            if(this.state.deliveryTrackingInfo?.trackingInfoItems)
                for(let item of this.state.deliveryTrackingInfo.trackingInfoItems) {
                    const stages = [
                        "pointOfPickup.ready", "pointOfPickup.load", "pointOfPickup.departure",
                        "pointOfOrigin.arrival", "pointOfOrigin.departure",
                        "pointOfArrival.arrival", "pointOfArrival.receiptOfDocuments", "pointOfArrival.departure",
                        "customsClearance.arrival", "customsClearance.departure",
                        "pointOfDestination.arrival",
                        "deliveryToRecipientDate.date"
                    ]
                    const indexElem = stages.findIndex(value => value === type);
                    if (indexElem === -1)
                        return false

                    for (let i = indexElem; i < stages.length; i++) {
                        let elem = stages[i]
                        let value = get(item.trackingInfo, elem, null)
                        if (value !== null) {
                            elem += ".fact"
                            value = get(item.trackingInfo, elem, null)
                            if (value !== null) {
                                if (value.length > 0)
                                    return true
                            }
                        }
                    }
                }
            return false
        }

        const checkIsExistTrackingId = (type, index) => {

            if (type === "idContainer") {
                const containerId = get(this.state.item.cargoInfo, `containers[${index}].containerId`, null);
                if (containerId !== null) {
                    if (containerId.length > 0) {
                        return true;
                    } else return false;
                } else {
                    return false;
                }
            } else if (type === "trackingNumber") {
                const trackingNumber = get(this.state.item.requestDetails, "trackingNumber", null);
                if (trackingNumber !== null) {
                    if (trackingNumber.length > 0) {
                        return true;
                    } else return false;
                } else {
                    return false;
                }
            }
        }

        const isOutPutAwbNumber = () => {
            return this.state.generals && this.state.item.orderCalculation && this.state.item.orderCalculation.transportationType && this.state.item.orderCalculation.transportationType === "AIR";
        }

        const isOutPutContainerNumber = () => {
            return this.state.generals && this.state.item?.orderCalculation?.transportationType === "SEA";
        }

        const isPutTruckInfo = () => {
            const directionBool = !((this.state.item?.order?.fromLocation?.type === "SEA_PORT" && this.state.item?.order?.toLocation?.type === "SEA_PORT")
                || (this.state.item?.order?.fromLocation?.type === "AIRPORT_HUB" && this.state.item?.order?.toLocation.type === "AIRPORT_HUB"));
            if (this.state.item?.fake === true) {
                return directionBool;
            } else {
                return true;
            }
        }

        const isPutBOL = () => {
            return this.state.containers ? true : this.state.item?.orderCalculation?.transportationType === "SEA" || this.state.item?.orderCalculation?.transportationType === "RAILWAY";
        }

        const checkAccessPlanPrice = (item) => {
            if(item?.fake===true){
                return false
            }
            if(userStore.isClient()|| userStore.isGuest()){
                return false
            }
            return true;
        }

        const checkAccessFactPrice = (item) => {
            if(item?.fake===true){
                return false
            }
            if(userStore.isClient()|| userStore.isGuest()){
                return false
            }
            return true;
        }

        const checkAccessEditField=()=>{
            if(userStore.isClient()|| userStore.isGuest()){
                return false
            }
            return true;
        }
        const loadOptionsCodeCargo = async (input) => {
            const query = input.trim()
            let res = []
            if (query) {
                res = await DictionaryService.getCustomsCategory({ query })
                this.setState({ codeCargoValues: addFormatName(res) })
            } else if (this.state.codeCargo && Object.keys(this.state.codeCargo).length > 0) {
                const id = get(
                    this.state.codeCargo,
                    `id`,
                    null,
                )
                const current = await DictionaryService.getCustomsCategoryById({ id })
                res = addFormatName([current])
                this.setState({ codeCargoValues: res })
            } else {
                this.setState({ codeCargoValues: [] })
            }
        }

        const mainBlock = (<Fragment>
            <Col xs={12} md={12}>
                <p className={"logistic-modal__header-item"}><FormattedMessage id="crud.field.price.title"/></p>
                <Row>
                    <div className="logistic-modal__inputs">
                        <Row>
                            {(checkAccessPlanPrice(this.state.item) === true) &&
                                <Col xs={12} md={4} lg={4} className={"pl-0"}>
                                    <p className="logistic-modal__inputs_fact-price"><FormattedMessage
                                        id="orderForm.estPrice"/> {this.props.estCost.value} {this.props.estCost.currency}
                                    </p>
                                </Col>
                            }
                            <Col xs={12} md={"auto"} lg={8} className={"d-flex pl-0"}>
                                <Row>
                                    <Col xs={8} md={"auto"} className={"pl-0"}>
                                        <div className={"input-price"}>
                                            <NumberInput
                                                disabled={(checkAccessFactPrice(this.state.item)===false)}
                                                leftAddon={<FormattedMessage id="orderForm.actPrice"/>}
                                                placeholder="orderForm.actPrice"
                                                value={this.state.price}
                                                onChange={value => this.onChange(value, 'price')}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={4} md={"auto"} className={"pr-0"}>
                                        <div className={"select-money"}>
                                            <Select
                                                disabled={(checkAccessFactPrice(this.state.item)===false)}
                                                placeholder="crudForm.field.currency"
                                                labelKey="code"
                                                valueKey="code"
                                                value={this.state.currency}
                                                values={this.state.currencies}
                                                className="crud-select"
                                                onChange={value => this.onChange(value.code, 'currency')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Row>
                <Row>
                    <Col xs={12} className={"pl-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="customsOfCargo.fields.name" />
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                        <Select
                            searchable
                            clearable={true}
                            values={this.state.codeCargoValues}
                            value={this.state.codeCargo}
                            valueKey="id"
                            labelKey="formatName"
                            onInputChange={(input) => loadOptionsCodeCargo(input)}
                            onChange={(value) => {
                                if(Object.keys(value).length===0) {
                                    this.updateState({
                                        codeCargoValues: []
                                    })
                                    this.onChange(null, "codeCargo")
                                }else {
                                    this.onChange(value, "codeCargo")
                                }
                            }}
                            filterOption={() => {
                                return true
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={"pl-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="form.order.costCargo" />
                        </p>
                    </Col>

                    <Col xs={12} md={12} lg={6} className={"pl-0 pr-0"}>
                        <Row className={"d-flex align-items-start"}>
                            <Col xs={8} className={"pl-0"}>

                                <div className={"input-price input-42px"}>
                                    <NumberInput
                                        className={"mb-0"}
                                        placeholder="form.order.costCargo"
                                        min={0}
                                        value={this.state.costCargo?.value}
                                        onChange={(value) => this.onChange(value, "costCargo.value")}
                                        error={get(this.state.errors, "costCargo.value")}
                                    />
                                </div>

                            </Col>
                            <Col xs={4} className={"pr-0"}>
                                <div className={"select-money"}>
                                    <Select
                                        clearable={false}
                                        placeholder="crudForm.field.currency"
                                        labelKey="code"
                                        valueKey="code"
                                        value={this.state.costCargo?.currency}
                                        values={this.state.currencies}
                                        className="crud-select"
                                        onChange={(value) => this.onChange(value.code, "costCargo.currency")}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={"pl-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="orderForm.modal.comment" />
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                        <TextInput
                            className={"logistic-modal__text-area"}
                            placeholder="orderForm.commentPrice"
                            onChange={ev => this.onChange(ev.target.value, 'comment')}
                            value={this.state.comment}
                            type="textarea"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={"pl-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="form.order.remarks"/>
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                        <TextInput
                            className={"logistic-modal__text-area"}
                            placeholder="form.order.remarks"
                            onChange={ev => this.onChange(ev.target.value, 'remarks')}
                            value={this.state.remarks}
                            type="textarea"
                        />
                    </Col>

                </Row>
                <Row>
                    <Col xs={12} className={"pl-0 pr-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="form.order.internalId"/>
                        </p>
                    </Col>
                    <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                        <TextInput
                            disabled={!checkAccessEditField()}
                            className={"logistic-modal__text-input"}
                            placeholder="form.order.internalId"
                            onChange={ev => this.onChange(ev.target.value, 'internalId')}
                            value={this.state.internalId}
                            type="text"
                        />
                    </Col>
                </Row>
                {isPutBOL()===true &&
                    <Row>
                        <Col xs={12} className={"pl-0 pr-0"}>
                            <p className={"logistic-modal__header-item"}>
                                <FormattedMessage id="form.order.BOL"/>
                            </p>
                        </Col>
                        <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                            <TextInput
                                disabled={!checkAccessEditField()}
                                className={"logistic-modal__text-input"}
                                placeholder="form.order.BOL"
                                onChange={ev => this.onChange(ev.target.value, 'BOL')}
                                value={this.state.BOL}
                                type="text"
                            />
                        </Col>
                    </Row>
                }
                { isOutPutAwbNumber(this.state) &&
                    <Row>
                        <Col xs={12} className={"pl-0 pr-0"}>
                            <p className={"logistic-modal__header-item"}>
                                <FormattedMessage id = "orderForm.aviaNumber.placeholder" />
                            </p>
                        </Col>
                        <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                            <TextInput
                                disabled={!checkAccessEditField()}
                                placeholder="orderForm.aviaNumber.placeholder"
                                onChange={ev => this.onChange(ev.target.value, 'awbNumber')}
                                value={this.state.awbNumber}
                                // disabled={checkIsExistTrackingId('trackingNumber', null)}
                            />
                        </Col>
                    </Row>
                }

                { isOutPutContainerNumber(this.state) &&
                    <Row>
                        <Col  xs={12} className={"pl-0 pr-0"}>
                            <p className={"logistic-modal__header-item"}>
                                <FormattedMessage id = "orderForm.containerNumber.placeholder" />
                            </p>
                        </Col>
                        <Col xs={12} md={12} lg={12} className={"pl-0 pr-0"}>
                            <TextInput
                                placeholder="orderForm.containerNumber.placeholder"
                                onChange={ev => this.onChange(ev.target.value, 'containerNumber')}
                                value={this.state.containerNumber}
                                disabled={!checkAccessEditField()}
                            />
                        </Col>
                    </Row>
                }
                <Row className={"date-tracking"}>
                    <Col xs={12} className={"pl-0 pr-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="crud.field.createDate.title"/>
                        </p>
                    </Col>
                    <Col xs={12} md={4} lg={4} className={"pl-0"}>
                        <DateInput
                            disabled={checkIsExistFactDate("pointOfPickup.ready") || !UserStore.isAdminOrganization() }
                            placeholder="form.order.selectDate"
                            value={this.state.item.createDate}
                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                            className={'tracking-item-date'}
                            onChange={value => this.onChangeCreateDate(value)}
                            onChangeOnDelete
                        />
                    </Col>
                </Row>
                {/*<Row className={"date-tracking"}>
                    <Col xs={12} className={"pl-0 pr-0"}>
                        <p className={"logistic-modal__header-item"}>
                            <FormattedMessage id="form.order.shippingDatePlan"/>
                        </p>
                    </Col>
                    <Col xs={12} md={4} lg={4} className={"pl-0"}>
                        <DateInput
                            placeholder="form.order.selectDate"
                            value={this.state.shippingDatePlan}
                            dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                            icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                            className={'tracking-item-date'}
                            onChange={value => this.onChangeTrackingDate('shippingDatePlan', value)}
                            onChangeOnDelete
                            disabled={checkIsExistFactDate("pointOfPickup.ready")}
                        />
                    </Col>
                </Row>*/}
            </Col>
        </Fragment>);

        const deliveryBlock = (<Fragment>
            {(this.props.isShowDelivery && !this.state.containers && isPutTruckInfo()) && <div>
                <Row>
                    <Col xs={12} md={12} className={"pr-0"}>
                        <p className={"logistic-modal__header-item"}><FormattedMessage id="deliveryOrders.delivery"/>
                        </p>
                    </Col>
                </Row>
                <Row className={"logistic-modal__delivery_block"}>
                    <Col xs={12} md={6} lg={4} className={"item-padding"}>
                        <p><FormattedMessage id="orderForm.modal.stateTruckNumber"/></p>
                        <TextInput
                            disabled={!checkAccessEditField()}
                            placeholder="orderForm.modal.truckNumber"
                            onChange={ev => this.onChange(ev.target.value, 'truckNumber')}
                            value={this.state.truckNumber}
                            type="text"
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} className={"item-padding"}>
                        <p><FormattedMessage id="orderForm.modal.driver"/></p>
                        <TextInput
                            disabled={!checkAccessEditField()}
                            placeholder="orderForm.modal.driverName"
                            onChange={ev => this.onChange(ev.target.value, 'driverName')}
                            value={this.state.driverName}
                            type="text"
                        />
                    </Col>
                    <Col xs={12} md={6} lg={4} className="item-padding align-self-end">
                        <TextInput
                            disabled={!checkAccessEditField()}
                            placeholder="orderForm.modal.driverPhone"
                            onChange={ev => this.onChange(ev.target.value, 'driverMobile')}
                            value={this.state.driverMobile}
                            type="text"
                        />
                    </Col>
                </Row>
            </div>}
        </Fragment>);

        const cargoParamsBlock = (<Fragment>
            <Row>
                <Col xs={12} md={8} className={"pr-0"}>
                    <p className={"logistic-modal__header-item"}><FormattedMessage
                        id="orderForm.modal.cargoParameters"/></p>
                </Col>
                {this.state.containers &&
                    <Col className={"d-flex justify-content-end"}>
                        <Button
                            id="view-btn-55"
                            onClick={() => {
                                this.updateState({isTableContainersView: true, isBlockContainersView: false});

                            }}
                            className={`tbl-btn ${this.state.isTableContainersView===true ? 'active' : ''}`}
                        >
                            <FontAwesome name="table"/>
                        </Button>
                        <Button
                            id="view-btn-4"
                            //color={this.state.isTrackingBlockView ? 'primary' : 'secondary'}
                            onClick={() => {
                                this.updateState({isTableContainersView: false, isBlockContainersView: true});
                            }}
                            className={`tbl-btn ${this.state.isTableContainersView===false ? 'active' : ''}`}
                        >
                            <FontAwesome name="list"/>
                        </Button>
                    </Col>
                }
            </Row>
            {this.state.containers &&
                <Row>
                    <Col xs={12}>
                        <div className={"logistic-modal__table-cargo"}>
                            {/*<Row className={"d-none d-md-flex"}>
                                <Col xs={12} md={1} className={"px-small-0"}>
                                    <p>№</p>
                                </Col>
                                <Col xs={6} md={4} className={"pl-small-0 cargo-container-field"}>
                                    <p><FormattedMessage id="orderForm.idContainer"/></p>
                                </Col>
                                <Col xs={6} md={3} className={"pr-small-0 cargo-container-field"}>
                                    <p><FormattedMessage id="crud.filter.cargoContainerType"/></p>
                                </Col>
                                <Col xs={12} md={4} className={"px-small-0 cargo-container-field"}>
                                    <p><FormattedMessage id="orderForm.modal.cargoWeight"/></p>
                                </Col>
                            </Row>*/}
                            { this.state.isTableContainersView === true && this.state.containers &&
                                <TableContainers
                                    orderDelivery={this.props.item}
                                    items={this.state.containers}
                                    onChange={this.onChange}
                                    isPutTruckInfo={isPutTruckInfo()}
                                />
                            }
                            {this.state.isBlockContainersView === true && this.state.containers.map((item, index) => {
                                {/*{this.state.containers.map((item, index) => {*/}
                                // const containerType = this.state.containersTypes.find(container => container.id === item.type.id);
                                const weightUnits = addFormatNameToConstant(WEIGHT_UNITS);
                                return (
                                    <div
                                        style={index !== this.state.containers.length - 1 ? {marginBottom: "20px"} : {marginBottom: "0"}}
                                        className={"logistic-modal__table-cargo_cargo-block-container"}
                                        key={this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1])}>
                                        <Row>
                                            <Col xs={(this.props.item?.fake === true && userStore.isManagerOrAdminCargobar()) ? 10 : 12} md={1} className={"px-small-0"}>
                                                <p style={{marginBottom:"0.3rem"}}>№</p>
                                                <p className="number-cargo">{index + 1}</p>
                                            </Col>
                                            {(this.props.item?.fake === true && userStore.isManagerOrAdminCargobar()) &&
                                                <Col xs={2} md={1} className={"px-small-0 d-flex d-md-none align-items-center justify-content-end"}>
                                                    <Button
                                                        onClick={(e)=>this.onChange(e, "deleteContainer", index)}
                                                        className={"btn-outline-danger"}
                                                        style={{marginTop:10}}>
                                                        <FontAwesome name={"trash"}/>
                                                    </Button>
                                                </Col>
                                            }
                                            <Col xs={6} md={4} className={"pl-small-0 cargo-container-field"}>
                                                <p className={"d-block d-md-block"}>
                                                    <FormattedMessage
                                                        id="orderForm.idContainer"/>
                                                </p>
                                                <ContainerNumberInput
                                                    onChange={ev => this.onChange(ev.target.value, 'containerId', index, 'containers')}
                                                    value={item.containerId}
                                                    type={item.type}
                                                    disabled={checkIsExistTrackingId("idContainer", index) || !checkAccessEditField()}
                                                />
                                            </Col>
                                            <Col xs={6} md={3} className={"pr-small-0 cargo-container-field"}>
                                                <p className={"d-block d-md-block"}><FormattedMessage
                                                    id="crud.filter.cargoContainerType"/></p>
                                                <Select
                                                    disabled={this.canEditItem(item)}
                                                    placeholder="crud.filter.cargoContainerType"
                                                    labelKey="formatName"
                                                    valueKey="id"
                                                    value={item.type}
                                                    values={this.state.containersTypes}
                                                    clearable={false}
                                                    onChange={value => this.onChange(value.id, 'type', index, 'containers')}
                                                />
                                            </Col>
                                            <Col xs={12} md={4} className={"px-small-0 cargo-container-field"}>
                                                <p className={"d-block d-md-block"}><FormattedMessage
                                                    id="orderForm.modal.cargoWeight"/></p>
                                                <div className={"d-flex"}>
                                                    <NumberInput
                                                        disabled={(this.props.item?.fake === true && !userStore.isManagerOrAdminCargobar())}
                                                        placeholder="orderForm.modal.cargoWeight"
                                                        onChange={ev => this.onChange(ev, 'weight', index, 'containers')}
                                                        value={item.weight}
                                                    />
                                                    <Select
                                                        disabled={(this.props.item?.fake === true && !userStore.isManagerOrAdminCargobar())}
                                                        values={weightUnits}
                                                        clearable={false}
                                                        labelKey="code"
                                                        valueKey="code"
                                                        value={item.weightUnit}
                                                        className={'cargo-unit-select ml-1'}
                                                        onChange={ev => this.onChange(ev.code, 'weightUnit', index, 'containers')}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        {/*
                                            <Row>
                                                <Col xs={12} md={1} className={"px-small-0"}>
                                                    <p className="number-cargo d-none">{index + 1}</p>
                                                </Col>
                                                <Col xs={12} md={4} className={"pl-small-0 cargo-container-field mb-md-3"}>
                                                    <p className={"d-block d-md-block"}><FormattedMessage
                                                        id="deliveryOrders.orderList.headerTable.dateReady"/> </p>
                                                    <DateInput
                                                        placeholder="deliveryOrders.orderList.headerTable.dateReady"
                                                        value={item.cargoReadyDate}
                                                        dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                        icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                        className={'tracking-item-date'}
                                                        onChange={value => this.onChange(value,'cargoReadyDate', index, 'containers')}
                                                        disabled={checkIsExistFactDate("pointOfPickup.ready")}
                                                    />
                                                </Col>
                                                <Col xs={0} md={4}></Col>
                                            </Row>
                                        */}
                                        {isPutTruckInfo() && <Row >
                                            <Col xs={12} md={1} className={"px-small-0 align-items-start d-none d-md-flex"} style={{marginTop:5}}>
                                                {(this.props.item?.fake === true && userStore.isManagerOrAdminCargobar()) ?
                                                    <Button
                                                        className={"btn-outline-danger"}
                                                        onClick={(e)=>this.onChange(e, "deleteContainer", index)}
                                                    >
                                                        <FontAwesome name={"trash"}/>
                                                    </Button>
                                                    :
                                                    <p className={"d-none"}>{index + 1}</p>
                                                }
                                            </Col>
                                            <Col xs={12} md={4} lg={4} className={"pl-small-0 cargo-container-field"}>
                                                <p className={"d-block d-md-block"}><FormattedMessage id="orderForm.modal.stateTruckNumber"/></p>
                                                <TextInput
                                                    placeholder="orderForm.modal.truckNumber"
                                                    onChange={ev => this.onChange(ev.target.value, 'deliveryTruckDetail.truckNumber', index, 'containers')}
                                                    value={item.deliveryTruckDetail!==undefined?item.deliveryTruckDetail.truckNumber:''}
                                                    type="text"
                                                />
                                            </Col>
                                            <Col xs={12} md={3} lg={3} className={"pr-small-0 cargo-container-field"}>
                                                <p className={"d-block d-md-block"}><FormattedMessage id="orderForm.modal.driver"/></p>
                                                <TextInput
                                                    placeholder="orderForm.modal.driverName"
                                                    onChange={ev => this.onChange(ev.target.value, 'deliveryTruckDetail.driverName', index, 'containers')}
                                                    value={item.deliveryTruckDetail?item.deliveryTruckDetail.driverName:''}
                                                    type="text"
                                                />
                                            </Col>
                                            <Col xs={12} md={4} lg={4} className={"px-small-0 cargo-container-field"}>
                                                <p className={"d-block d-md-block"}><FormattedMessage id="orderForm.modal.driverPhone"/></p>
                                                <TextInput
                                                    placeholder="orderForm.modal.driverPhone"
                                                    onChange={ev => this.onChange(ev.target.value, 'deliveryTruckDetail.driverMobile', index, 'containers')}
                                                    value={item.deliveryTruckDetail?item.deliveryTruckDetail.driverMobile:''}
                                                    type="text"
                                                />
                                            </Col>
                                        </Row>}
                                        {index!== this.state.containers.length-1? <hr />:'' }

                                    </div>
                                );
                            })
                            }
                            {(this.state.isBlockContainersView === true && this.props.item?.fake === true && userStore.isManagerOrAdminCargobar()) &&
                                <Row className={"mt-3"}>
                                    <Col xs={12} className={"w-auto d-flex justify-content-end"}>
                                        <Button
                                            className={"btn-primary-new"}
                                            onClick={(e)=>this.onChange(e, "addContainer")}
                                        >
                                            <FormattedMessage id={"orderForm.modal.addContainer"}/>
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                </Row>
            }
            {this.state.generals &&
                <Row>
                    <Col xs={12}>
                        <div className={"logistic-modal__table-cargo"}>
                            {this.state.generals.map((item, index) => {
                                const weightUnits = addFormatNameToConstant(WEIGHT_UNITS);
                                return (
                                    <div
                                        style={index !== this.state.generals.length - 1 ? {marginBottom: "20px"} : {marginBottom: "0"}}
                                        className={"logistic-modal__table-cargo_cargo-block-container"}
                                        key={`${this.props.id}-${index}` || (window.location.href.split('/')[window.location.href.split('/').length - 1])}>
                                        <Row className={"d-none d-md-flex"}>
                                            <Col xs={12} md={1} className={"px-small-0"}>
                                                <p>№</p>
                                            </Col>
                                            <Col xs={6} md={item.typeId === 3 ?5: 2} className={"pl-small-0 cargo-container-field"}>
                                                <p><FormattedMessage id={item.typeId === 3 ? "wizard.cargo.form.size.totalVolume" : "wizard.cargo.form.size.length"}/></p>
                                            </Col>
                                            <Col xs={6} md={item.typeId === 3 ?5: 2} className={"pr-small-0 cargo-container-field"}>
                                                <p><FormattedMessage id={item.typeId === 3 ? "orderForm.modal.cargoWeight" : "wizard.cargo.form.size.width"}/></p>
                                            </Col>
                                            {item.typeId !== 3 &&
                                                <Col xs={6} md={2} className={"pr-small-0 cargo-container-field"}>
                                                    <p><FormattedMessage id="wizard.cargo.form.size.height"/></p>
                                                </Col>}
                                            {item.typeId !== 3 &&
                                                <Col xs={12} md={2} className={"px-small-0 cargo-container-field"}>
                                                    <p><FormattedMessage id="orderForm.modal.cargoWeight"/></p>
                                                </Col>}
                                            {item.typeId !== 3 &&
                                                <Col xs={12} md={3} className={"px-small-0 cargo-container-field"}>
                                                    <p><FormattedMessage id="orderForm.modal.numberOfPlaces"/></p>
                                                </Col>}
                                        </Row>


                                        {item.typeId === 3 ?
                                            <Row>
                                                <Col xs={12} md={1} className={"px-small-0"}>
                                                    <p className="number-cargo">{index + 1}</p>
                                                </Col>
                                                <Col xs={12} md={5} className={"pl-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-md-none"}><FormattedMessage
                                                        id="wizard.cargo.form.size.totalVolume"/></p>
                                                    <TextInput
                                                        placeholder="wizard.cargo.form.size.totalVolume"
                                                        onChange={ev => this.onChange(ev.target.value, 'cubeVolume', index, 'generals')}
                                                        value={item.cubeVolume}
                                                        disabled={true}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={8} md={4} className={"pl-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-md-none"}><FormattedMessage
                                                        id="orderForm.modal.cargoWeight"/></p>
                                                    <TextInput
                                                        disabled={true}
                                                        placeholder="orderForm.modal.cargoWeight"
                                                        onChange={ev => this.onChange(ev.target.value, 'weight', index, 'generals')}
                                                        value={item.weight}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={4} md={2} className={"pl-small-0 cargo-container-field"}>
                                                    <Select
                                                        disabled={true}
                                                        values={weightUnits}
                                                        clearable={false}
                                                        labelKey="code"
                                                        valueKey="code"
                                                        value={item.weightUnit}
                                                        className={'cargo-unit-select ml-1'}
                                                        onChange={ev => this.onChange(ev.code, 'weightUnit', index, 'generals')}
                                                    />
                                                </Col>
                                                <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}></Col>
                                            </Row>
                                            :
                                            <Row>
                                                <Col xs={12} md={1} className={"px-small-0"}>
                                                    <p className="number-cargo">{index + 1}</p>
                                                </Col>
                                                <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-md-none"}><FormattedMessage
                                                        id="wizard.cargo.form.size.length"/></p>
                                                    <TextInput
                                                        disabled={true}
                                                        placeholder="wizard.cargo.form.size.length"
                                                        onChange={ev => this.onChange(ev.target.value, 'length', index, 'generals')}
                                                        value={item.length}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-md-none"}><FormattedMessage
                                                        id="wizard.cargo.form.size.width"/></p>
                                                    <TextInput
                                                        disabled={true}
                                                        placeholder="wizard.cargo.form.size.width"
                                                        onChange={ev => this.onChange(ev.target.value, 'width', index, 'generals')}
                                                        value={item.length}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-md-none"}><FormattedMessage
                                                        id="wizard.cargo.form.size.height"/></p>
                                                    <TextInput
                                                        disabled={true}
                                                        placeholder="wizard.cargo.form.size.height"
                                                        onChange={ev => this.onChange(ev.target.value, 'height', index, 'generals')}
                                                        value={item.height}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={6} md={2} className={"pl-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-md-none"}><FormattedMessage
                                                        id="orderForm.modal.cargoWeight"/></p>
                                                    <TextInput
                                                        disabled={true}
                                                        placeholder="orderForm.modal.cargoWeight"
                                                        onChange={ev => this.onChange(ev.target.value, 'weight', index, 'generals')}
                                                        value={item.weight}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={6} md={3} className={"pl-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-md-none"}><FormattedMessage
                                                        id="orderForm.modal.numberOfPlaces"/></p>
                                                    <TextInput
                                                        disabled={true}
                                                        placeholder="orderForm.modal.numberOfPlaces"
                                                        onChange={ev => this.onChange(ev.target.value, 'amount', index, 'generals')}
                                                        value={item.amount}
                                                        type="text"
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        {/*{(isOutPutContainerNumber()===true || isOutPutAwbNumber()===true) &&*/}
                                        {/*    <Row>*/}
                                        {/*        <Col xs={12} md={1} className={"px-small-0"}>*/}
                                        {/*            <p ></p>*/}
                                        {/*        </Col>*/}
                                        {/*{isOutPutAwbNumber()===true &&*/}
                                        {/*    <Col xs={12} md={4} className={"pl-0 number-field-no-padding"} >*/}
                                        {/*        <TextInput value={item.aviaNumber}*/}
                                        {/*                   onChange={ev => this.onChange(ev.target.value, 'aviaNumber', index, 'generals')}*/}
                                        {/*                   label={<FormattedMessage id={"orderForm.trackingNumber"}/>}*/}
                                        {/*                   placeholder={"orderForm.aviaNumber.placeholder"}*/}
                                        {/*        />*/}
                                        {/*    </Col>*/}
                                        {/*}*/}
                                        {/*{isOutPutContainerNumber()===true &&*/}
                                        {/*    <Col xs={12} md={4} className={"pl-0 number-field-no-padding"} >*/}
                                        {/*        <TextInput value={item.containerNumber}*/}
                                        {/*                   onChange={ev => this.onChange(ev.target.value, 'containerNumber', index, 'generals')}*/}
                                        {/*                   label={<FormattedMessage id={"orderForm.trackingNumber"}/>}*/}
                                        {/*                   placeholder={"orderForm.containerNumber.placeholder"}*/}
                                        {/*        />*/}
                                        {/*    </Col>*/}
                                        {/*}*/}
                                        {/*    </Row>*/}
                                        {/*}*/}
                                        {index!== this.state.generals.length-1? <hr />:'' }
                                    </div>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
            }
            {this.state.vehicle && this.state.vehicle.length > 0 &&
                <Row>
                    <Col xs={12}>
                        <div className={"logistic-modal__table-cargo"}>
                            <Row className={"d-none d-lg-flex"}>
                                <Col xs={12} md={12} lg={1} className={"px-small-0"}>
                                    <p>№</p>
                                </Col>
                                <Col xs={12} md={4} lg={4} className={"pl-small-0 pr-small-0 cargo-container-field vehicle"}>
                                    <p><FormattedMessage id="detailsOfCalculation.orderTable.typeOfCargo"/></p>
                                </Col>
                                <Col xs={6} md={2} lg={1} className={"pr-small-0 cargo-container-field"}>
                                    <p><FormattedMessage id="deliveryOrders.cargoInformation.cargoAmountTitle"/></p>
                                </Col>
                                <Col xs={6} md={6} lg={3} className={"px-small-0 cargo-container-field"}>
                                    <p>
                                        <FormattedMessage id="deliveryOrders.cargoInformation.cargoSizeTitle"/>

                                    </p>
                                </Col>
                                <Col xs={12} md={6} lg={3} className={"px-small-0 cargo-container-field"}>
                                    <p>
                                        <FormattedMessage id="deliveryOrders.cargoInformation.cargoWeightTitle"/>
                                    </p>
                                </Col>
                            </Row>
                            {this.state.vehicle.map((item, index) => {
                                    const weightUnits = addFormatNameToConstant(WEIGHT_UNITS);
                                    const sizeCar = item.type.size.length +" x "+ item.type.size.width + " x " + item.type.size.height +" "+ item.type.size.unit
                                    const units = [{code: "М"}, {code: "M"}, {code: "KG"}, {code: "КГ"}];
                                    return (
                                        <div
                                            key={this.props.id || (window.location.href.split('/')[window.location.href.split('/').length - 1])}
                                            style={{marginBottom: "20px"}}
                                            className={"logistic-modal__table-cargo_cargo-block-container"}
                                        >
                                            <Row>
                                                <Col xs={12} md={12} lg={1} className={"px-small-0"}>
                                                    <p className="number-cargo">{index + 1}</p>
                                                </Col>
                                                <Col xs={12} md={4} lg={4} className={"px-small-0 pr-0 cargo-container-field vehicle"}>
                                                    <p className={"d-block d-lg-none"}>
                                                        <FormattedMessage id="detailsOfCalculation.orderTable.typeOfCargo"/>
                                                    </p>
                                                    <TextInput
                                                        disabled
                                                        value={translate("constants.vehicleBodyType."+(item.bodyType.toLowerCase()))}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={6} md={2} lg={1} className={"pr-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-lg-none"}><FormattedMessage
                                                        id="deliveryOrders.cargoInformation.cargoAmountTitle"/></p>
                                                    <TextInput
                                                        disabled
                                                        value={1}
                                                        type="text"
                                                    />
                                                </Col>
                                                <Col xs={6} md={6} lg={3} className={"px-small-0 cargo-container-field size"}>
                                                    <p className={"d-block d-lg-none"}>
                                                        <FormattedMessage
                                                            id="deliveryOrders.cargoInformation.cargoSizeTitle"/>
                                                    </p>
                                                    <div className={"d-flex"}>
                                                        <TextInput
                                                            disabled
                                                            value={sizeCar}
                                                            type="text"
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={6} lg={3} className={"px-small-0 cargo-container-field"}>
                                                    <p className={"d-block d-lg-none"}>
                                                        <FormattedMessage
                                                            id="deliveryOrders.cargoInformation.cargoWeightTitle"/>
                                                    </p>
                                                    <div className={"d-flex"}>
                                                        <TextInput
                                                            disabled
                                                            value={item.type.maxWeight.value}
                                                            type="text"
                                                        />
                                                        <Select
                                                            disabled
                                                            values={units}
                                                            labelKey="code"
                                                            valueKey="code"
                                                            value={item.type.maxWeight.unit}
                                                            className={'cargo-unit-select ml-1'}
                                                            clearable={false}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>)
                                }
                            )}
                        </div>
                    </Col>
                </Row>
            }
        </Fragment>);
        return (
            <Form>
                <Row>
                    {/*{UserStore.isAdmin() &&
                        <Col xs={12}>
                            <OrderChangesDateHistory id={this.state.item?.id}/>
                        </Col>
                    }*/}
                </Row>
                {mainBlock}
                {/*{docsBlockReadOnly}*/}
                {deliveryBlock}
                {cargoParamsBlock}
                <Row className="no-gutters logistic-modal__btn-manage"
                     style={{justifyContent: 'flex-start', width: '100%', flexDirection: 'row-reverse'}}>
                    <Col xs={12} className={"d-flex"}>
                        <div className={"ml-auto"} style={{width:"fit-content"}}>
                            <Button
                                color="primary"
                                onClick={() => this.onSave()}
                                style={{marginLeft: '1em'}}
                            >
                                <FormattedMessage id="detailsOfCalculation.save"/>
                            </Button>
                        </div>
                        <div className={"ml-2"} style={{width:"fit-content"}}>
                            <Button color="secondary" onClick={() => {
                                this.props.onClose(false);
                            }}>
                                <FormattedMessage id="crudForm.button.cancel"/>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        );
    }

    onCancelSaveChanges=async ()=>{
        this.props.onReloadData(false)
    }

    onSaveChanges=async ()=>{
        await this.onSave();
        this.props.onReloadData(true)
    }

    render() {
        const {isShow, onClose, isModal} = this.props;
        if (isModal === true) {
            return (
                <Modal
                    isShow={isShow}
                    className="logistic-modal edit-order-modal"
                    title={translate({id: 'crudForm.field.edit'})}
                    onClose={() => {
                        onClose(false);
                    }}
                    top
                >
                    {this.state.loading === true ?
                        <div className={"d-flex justify-content-center"}>
                            <div className="fa-1x mr-2" style={{fontSize: 20}}>
                                <i className="fa fa-spinner fa-spin"/>
                            </div>
                        </div> :
                        this.renderContent()
                    }
                </Modal>
            );
        } else {
            return (<div className={"logistic-modal content"}>
                <Modal
                    isShow={this.props.showSaveModal}
                    onClose={this.onCancelSaveChanges}
                    title={<Row><Col xs={12}><FormattedMessage id={"dialog.confirm.title"}/></Col></Row>}
                    isModal={true}
                >
                    <Row>
                        <Col xs={12}>
                            <FormattedMessage id={"dialog.saveUnsavedChanges.body"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className={"d-flex mt-3"}>
                            <Button
                                className={"btn-primary-new"}
                                onClick={this.onSaveChanges}
                                disabled={this.props.saving === true}
                            >
                                <div className={"d-flex"}>
                                    {this.props.saving === true && <div className="fa-1x mr-2">
                                        <i className="fa fa-spinner fa-spin"/>
                                    </div>}
                                    <span><FormattedMessage id={"dialog.confirm.yes"}/></span>
                                </div>

                            </Button>
                            <Button
                                className={"btn-primary-new ml-2"}
                                onClick={this.onCancelSaveChanges}
                                disabled={this.props.saving === true}
                            >
                                <span><FormattedMessage id={"dialog.confirm.no"}/></span>
                            </Button>
                        </Col>
                    </Row>
                </Modal>
                {this.state.loading === true ?
                    <div className={"d-flex justify-content-center"}>
                        <div className="fa-1x mr-2" style={{fontSize: 20}}>
                            <i className="fa fa-spinner fa-spin"/>
                        </div>
                    </div> :
                    this.renderContent()
                }
            </div>)
        }
    }
}

LogisticModal.defaultProps = {
    isModal: false,
    onChangeCreateDate: () => {
    }
}

LogisticModal.propTypes = {
    isModal: PropTypes.bool,
    isShow: PropTypes.bool,
    onClose: PropTypes.func,
    onUpdateTrackingInfo: PropTypes.func,
    awb: PropTypes.string,
    containerId: PropTypes.string,
    trackingNumber: PropTypes.string,
    manualCost: PropTypes.object,
    manualCostDescription: PropTypes.string,
    estCost: PropTypes.object,
    containers: PropTypes.array,
    generals: PropTypes.array,
    id: PropTypes.number,
    driverMobile: PropTypes.string,
    driverName: PropTypes.string,
    truckNumber: PropTypes.string,
    documentContainer: PropTypes.object,
    transportDocument: PropTypes.object,
    isShowDelivery: PropTypes.bool,
    // item: PropTypes.object,
    railwayNumber: PropTypes.string,
    sealine: PropTypes.string,
    item: PropTypes.object,
    onChangeCreateDate: PropTypes.func
};
