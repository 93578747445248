import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {Button, Form, FormGroup, Popover, PopoverBody, Row} from 'reactstrap';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Modal } from './Modal';
import { TextInput, NumberInput } from './form-control';
import { translate } from '../../utils/utils';
import UserService from '../../services/userService';
import { metricsEvents, pushAnalyticalEvent } from '../../utils/analyticsMetrics';
// import UserStore from '../../stores/userStore';

const defaultUserData = {
  userName: '',
  email: '',
  phone: '',
  //nameCargo:'',
  question: '',
};

export default class ModalContactUs extends Component {
  state = {
    isShown: false,
    sendEmailItem: {},
    errors: {},
    isShowResponse: false,
    responseStatus: '',
    user: { ...defaultUserData },
  };

  // UNSAFE_componentWillMount() {
  //   const currentUser = UserStore.user || {};
  //   const userName = UserStore.isGuest() ? '' : `${get(currentUser, 'lastName', '')} ${get(currentUser, 'firstName', '')} ${get(currentUser, 'middleName', '')}`;
  //   const email = UserStore.isGuest() ? '' : currentUser.email;
  //
  //   this.setState({
  //     user: {
  //       ...this.state.user,
  //       userName,
  //       email,
  //     },
  //   });
  // }

  async submitForm() {
    const { onClose } = this.props;
    const { user } = this.state;

    const res = await UserService.senQuestionEmail(user);

    if (res.status === 'success') {
      // eslint-disable-next-line quote-props
      pushAnalyticalEvent(metricsEvents.contactUs);
      onClose();

      this.setState({
        user: { ...defaultUserData },
        responseStatus: res.status,
        isShowResponse: true,
      });
    } else {
      const errors = {};

      get(res.data, 'errors', []).forEach((error) => {
        errors[error.fieldName] = error.errorText;
      });

      this.setState({ errors });
    }
  }

  onChangeField = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;

    this.setState({
      user: {
        ...this.state.user,
        [fieldName]: fieldValue,
      },
      errors: {
        [fieldName]: '',
      },
    });
  };

  onChangePhone = (value) => {
    this.setState({
      user: {
        ...this.state.user,
        phone: value,
      },
      errors: {
        phone: '',
      },
    });
  };
  isPopoverOpen = (target) => {
    return this.state[target] ? this.state[target] : false;
  };

  onHover = (target) => {
    this.setState({
      ...this.state,
      [target]: true,
      //isPopoverOpen: true,
    });
  };

  onHoverLeave = (target) => {
    this.setState({
      ...this.state,
      [target]: false,
      //isPopoverOpen: false,
    });
  };

  renderContent() {
    const { user, errors,nameCargo } = this.state;

    return (
      <div className="contactModal">
        <Form>
          <TextInput
            onChange={event => this.onChangeField(event)}
            name="userName"
            placeholder="modal.contactUs.name"
            id="contact-us-name"
            value={user.userName}
            error={errors.userName}
            leftAddon={
              <span className="flag-required">
                {translate('modal.contactUs.name')}
              </span>
            }
          />
          <TextInput
            onChange={event => this.onChangeField(event)}
            name="email"
            id="contact-us-email"
            placeholder="modal.contactUs.email"
            value={user.email}
            error={errors.email}
            leftAddon={
              <span className="flag-required">
                {translate('modal.contactUs.email')}
              </span>
            }
          />
          <NumberInput
            onChange={val => this.onChangePhone(val)}
            name="phone"
            type="tel"
            id="contact-us-phone"
            placeholder="modal.contactUs.phone"
            value={user.phone}
            error={errors.phone}
            leftAddon={
              <span>
                {translate('modal.contactUs.phone')}
              </span>
            }
          />
          {/*<TextInput
            label="form.field.cargoName"
            onChange={event => this.onChangeField(event)}
            name="nameCargo"
            id="contact-us-cargo-name"
            value={nameCargo}
            error={errors.nameCargo}
          />*/}
          <TextInput
            onChange={event => this.onChangeField(event)}
            name="question"
            id="contact-us-question"
            placeholder="modal.contactUs.question"
            value={user.question}
            error={errors.question}
            type={'textarea'}
          />
          <FormGroup className="btn-controls">
            <Row className="no-gutters" style={{ justifyContent: 'flex-start' }}>
              <Button
                color="primary"
                id="contact-us-submit"
                onClick={() => this.submitForm()}
                onPointerEnter={() => this.onHover(`contact-us-submit`)}
                onPointerLeave={() => this.onHoverLeave(`contact-us-submit`)}
              >
                <FormattedMessage id="modal.contactUs.sendBtn" />
              </Button>
              {/*<Popover
                  placement="right"
                  isOpen={this.isPopoverOpen("contact-us-submit")}
                  target={"contact-us-submit"}>
                <PopoverBody>{translate({id:"detailsOfCalculation.OrderPopover"})}</PopoverBody>
              </Popover>*/}
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }

  render() {
    const { isShown, onClose } = this.props;
    const { isShowResponse, responseStatus } = this.state;

    return (
      <div>
        <Modal
          isShow={isShown}
          className="sendEmail-modal"
          title={translate({ id: 'modal.contactUs.title' })}
          onClose={() => { onClose(); }}
          top
        >
          { this.renderContent() }
        </Modal>
        <Modal
          isShow={isShowResponse}
          className="sendEmail-modal"
          title={translate({ id: 'modal.contactUs.title' })}
          onClose={() => { this.setState({ isShowResponse: false, responseStatus: '' }); }}
          top
        >
          <div className="py-3">
            { (responseStatus === 'success') ?
              <FormattedMessage id="modal.contactUs.successBody" /> :
              <FormattedMessage id="modal.contactUs.failBody" />
            }
          </div>
        </Modal>
      </div>
    );
  }
}

ModalContactUs.propTypes = {
  isShown: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
