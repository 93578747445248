/*eslint-disable*/
import { observable, computed, action, toJS } from 'mobx';
import { get, includes, map, some, cloneDeep, isEqual, lowerCase } from 'lodash';
import moment from 'moment';
import config from '../config';

import UserService from '../services/userService';
import * as URL from '../constants/routes';
import AppStore from './appStore';
import { PARTNER_PROFILE_TYPES } from '../constants/global';
import OrganizationProfilesStore from './organizationProfilesStore';
import { deleteAllHashParamsFromUrl } from '../utils/utils';
import { CLIENT } from '../constants/userRoles';
import {
  ADMIN_LOGISTIC_DOCUMENT_KINDS_CRUD, ADMIN_PROFILE,
  ADMIN_RATING_USERS,
  ADMIN_SUBSCRIPTION_REQUESTS,
  PARTNER_CUSTOMS_CLEARANCE, PARTNER_EMPTY_ORDERS,
} from '../constants/routes';
import { getOrderId } from '../pages/common/calculationUtils';
import OrganizationService from '../services/organizationService';

const PUBLIC_ROUTES = [
  '/',
  '/ui-kit',
  URL.AUTH_REGISTER,
  URL.AUTH_LOGIN,
  URL.AUTH_PASSWORD_RECOVERY,
  URL.AUTH_PASSWORD_RECOVERY,
  URL.WIZARD,
  URL.WIDGET_WIZARD,
  "/code"
];

/**
 * Стор для хранения и обновления информации о пользователе, для авторизации и разлогинивания.
 */
class UserStore {
  @observable user = null;

  @observable _seaTransportationOnlyView = false;
  @observable _portAgentProfileOnlyView = false;
  @observable _roadTransportationProfileOnlyView = false;
  @observable _airTransportationProfileOnlyView = false;
  @observable _railwayProfileOnlyView = false;
  @observable _palletProfileOnlyView = false;
  @observable _guestOrder = null;
  @observable calcsToBeShowAll = [];

  @observable _hasSavingOrder = false;
  @observable _hasOrdering = false;
  @observable _params = {
    id: null,
    calcId: null,
    type: '',
  };
  @observable _calcId = null;

  DESTINATION_PARAMETER_NAME = 'destination';
  LAST_LOGIN_DATE_TIME = 'lastLoginDateTime';

  constructor() {
    this.lastLoginDateTime = localStorage[this.LAST_LOGIN_DATE_TIME];

    window.addEventListener('storage', (event) => {
      if (window.location.pathname === URL.AUTH_LOGIN) {
        return;
      }

      const lastLoginDateTimeIsUpdated = event.key === this.LAST_LOGIN_DATE_TIME;

      if (!lastLoginDateTimeIsUpdated) {
        return;
      }

      const prevUser = cloneDeep(toJS(this.user));

      this.update(true)
        .then((user) => {
          const nextUser = toJS(user);

          if (!isEqual(prevUser, nextUser) && !PUBLIC_ROUTES.includes(window.location.pathname)) {
            window.location.pathname = URL.AUTH_LOGIN;
          }
        });
    });
  }

  @computed
  get hasSavingOrder() {
    return this._hasSavingOrder;
  }

  @computed
  get hasOrdering() {
    return this._hasOrdering;
  }

  @computed
  get params() {
    return this._params;
  }

  @computed
  get calcId() {
    return this._calcId;
  }

  @computed
  get guestOrder() {
    return this._guestOrder;
  }

  isCalcShouldShowAll(orderId) {
    return this.calcsToBeShowAll.includes(+orderId);
  }

  @computed
  get isAuthorized() {
    return this.user !== null;
  }

  @computed
  get isAdminOnlyView() {
    return ({
      sea: this._seaTransportationOnlyView,
      port: this._portAgentProfileOnlyView,
      road: this._roadTransportationProfileOnlyView,
      air: this._airTransportationProfileOnlyView,
      railway: this._railwayProfileOnlyView,
      pallet: this._palletProfileOnlyView,
    });
  }

  @computed
  get isAllowedOnlyEditingForPartnerPortServices() {
    return this.isPartner() && this.isAccessAllowed(URL.PARTNER_PORT_SERVICES);
  }

  @computed
  get isAllowedOnlyEditingForAdminTariffs() {
    return this.isOperator();
  }

  @action
  setHasSavingOrder(status) {
    this._hasSavingOrder = status;
  }

  @action
  setHasOrdering(status) {
    this._hasOrdering = status;
  }

  @action
  setParams(params) {
    this._params = {
      id: params.id,
      calcId: params.calcId,
      type: lowerCase(params.type),
    };
  }

  @action
  setCalcId(id) {
    this._calcId = id;
  }

  @action
  setAdminNoSeaTransportationProfile(status) {
    this._seaTransportationOnlyView = status;
  }

  @action
  setGuestOrder(order = null) {
    this._guestOrder = order;
  }

  @action
  addCalcToBeShowAll(orderId) {
    this.calcsToBeShowAll.push(+orderId);
  }

  @action
  removeCalcToBeShowAll(orderId) {
    this.calcsToBeShowAll = this.calcsToBeShowAll.filter(el => el !== +orderId);
  }

  @action
  async forceUpdate() {
    const currentRoute = window.location.pathname;
    const urlHash = window.location.hash;

    const isPublicPage = PUBLIC_ROUTES.includes(currentRoute);


    const res = await UserService.current();
    const isNotAuthorized = res.status === 401;

    if (res.ok) {
      console.log('forceUpdate res is ok');
      console.log('forceUpdate res', res);
      const resJson = await res.json();
      console.log('forceUpdate resJson', resJson);
      console.log('forceUpdate resJson.data', resJson.data);
      this.user = resJson.data;
      console.log('forceUpdate user', this.user);
      await OrganizationProfilesStore.updateCurrentOrganizationProfiles();
      console.log('forceUpdate await OrganizationProfilesStore.updateCurrentOrganizationProfiles() ok');
      if (urlHash.includes(this.DESTINATION_PARAMETER_NAME)) {
        console.log('forceUpdate is DESTINATION_PARAMETER_NAME = true');
        const destinationUrl = decodeURIComponent(get(urlHash.split('='), '[1]', ''));
        const isAccessAllowed = this.isAccessAllowed(destinationUrl);

        const urlIsExistsAndIsAllowed = (
          destinationUrl && isAccessAllowed && includes(URL, destinationUrl)
        );

        deleteAllHashParamsFromUrl();

        if (urlIsExistsAndIsAllowed) {
          window.location.pathname = destinationUrl;
        }
      }
    } else if (isNotAuthorized) {
      console.log('forceUpdate res is not ok and isNotAuthorized');
      this.user = null;
      console.log('forceUpdate user is setted to null');
      if (!isPublicPage) {
        console.log('forceUpdate res is not ok and isNotAuthorized is not public page');
        window.location.hash = `${this.DESTINATION_PARAMETER_NAME}=${encodeURIComponent(currentRoute)}`;
        window.location.pathname = URL.AUTH_LOGIN;
      }
    } else {
      console.log(`Failed to update user: ${res.statusText}`);
    }
    console.log('forceUpdate end if (res.ok) then else ...');
    this.showOrHideAccessDeniedMessage();
    console.log(`forceUpdate returning user`, this.user);
    return this.user;
  }

  @action
  showOrHideAccessDeniedMessage() {
    AppStore.hideAccessDeniedMessage();

    const isAccessAllowed = this.isAccessAllowed(window.location.pathname);

    if (isAccessAllowed) {
      console.log('showOrHideAccessDeniedMessage hiding message');
      AppStore.hideAccessDeniedMessage();
    } else {
      console.log('showOrHideAccessDeniedMessage showing message');
      AppStore.showAccessDeniedMessage();
    }
  }

  @action
  async update(forceUpdate = false) {
    console.log('user store update user', this.user);
    console.log('user store update forceUpdate', forceUpdate);
    if (this.user && !forceUpdate) {
      console.log('user store update user exist and !forceUpdate === true, returning user', this.user);
      return this.user;
    }
    console.log('user store update user is not exist or !forceUpdate !== true, returning user', this.user);
    return await this.forceUpdate();
  }

  @action
  async auth(username, password) {
    const response = await UserService.auth(username, password, this._guestOrder);
    const res = await response.json();
    console.log("AUTH RESPONSE: ", res)
    const loginDateTime = moment().toISOString();

    this.lastLoginDateTime = loginDateTime;
    if ('localStorage' in window) {
      localStorage[this.LAST_LOGIN_DATE_TIME] = loginDateTime;
    }

    console.log('RESPONSE', response, res)

    if (response.status===200) {
      localStorage.setItem("token", res.access_token)
      localStorage.setItem("refreshToken", res.refresh_token)
      console.log('Auth request is success');
      var isNotPublic = !PUBLIC_ROUTES.includes(window.location.pathname);
      var result = isNotPublic ?
        await this.update(true).then(() => {
          console.log('page will be reloaded');
          let domainV3 = ""
          if(this.user.organization && this.user.organization.domain && window.location.host.split(".").length===2){
            const host = window.location.host;
            domainV3 = host.includes(`${this.user.organization.domain}.`)===true?host:`${this.user.organization.domain}.${host}`
            if(window.location.host.includes("localhost")===false && window.location.host.includes(domainV3)===false) {
              window.location.replace(`https://${domainV3}${window.location.pathname}?token=${localStorage.getItem("token")}`);
            }
            else {
              window.location.reload();
            }
          }else {
            window.location.reload();
          }
          /*window.location.reload();*/
        })
        : await this.update(true);
      return (result)
    } else {
      console.log('Auth request has errror');
      console.error(res);
      return (res)
    }

    return Promise.resolve();
  }

  @action
  async logout(redirectLogout=true) {
    const token = localStorage.getItem("token");
    console.log("token: ", token)
    localStorage.removeItem("token")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("intervalUpdateToken");
    //await this.update(true);
    if(redirectLogout===true)
      window.location = config.endpointSSO+'/logout?token=' + token;
    /*await this.update(true);
    document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    document.cookie = 'remember-me=; expires=Thu, 01 Jan 1970 00:00:00 GMT';*/
  }

  getAllUserRoles = () => map(get(this.user, 'roles', []), 'id');

  isGuest = () => some(this.getAllUserRoles(), userRole => userRole.includes('GUEST'));

  isClient = () => {
    // если у организации пользователя есть головная организация, то считаем, что это клиент не смотря на роли
    return some(this.getAllUserRoles(), userRole => userRole.includes('CLIENT'));
  };

  isAdminOrganization = ()=>{
    return some(this.getAllUserRoles(), userRole=>userRole.includes('PARTNER_ADMIN') || userRole.includes('OPERATOR_ADMIN'))
  }
  isManagerOrGreater = () => some(this.getAllUserRoles(), userRole => userRole.includes('PARTNER') ||
      userRole.includes('OPERATOR') ||
      userRole.includes('PARTNER_MANAGER') ||
      userRole.includes('PARTNER_ADMIN') ||
      userRole.includes('OPERATOR') ||
      userRole.includes("OPERATOR_ADMIN")
  );

  isPartner = () => some(this.getAllUserRoles(), userRole => userRole.includes('PARTNER'));
  isPartnerSaas = () =>  this.isPartner() && this.user && this.user.organization && !_.isEmpty(this.user.organization.domain);
  isClientOfPartnerSaas = () =>  !!(this.user && this.user.organization && this.user.organization.parent);

  isOperator = () => some(this.getAllUserRoles(), userRole => userRole.includes('OPERATOR'));
  isAdmin = () => some(this.getAllUserRoles(), userRole => userRole.includes("OPERATOR_ADMIN"));

  isManagerCargobar = () => {
    return some(this.getAllUserRoles(), userRole => userRole.includes('PARTNER_MANAGER') || userRole.includes('PARTNER_ADMIN') ) && (
      this.user.organization &&
      this.user.organization.id === 4
    );
  };

  getOrganizationId=()=>{
    if(this.user.organization)
      return this.user.organization.id
    return null
  }

  isManagerOrAdminCargobar = () => {
    return some(this.getAllUserRoles(), userRole => userRole.includes('PARTNER_MANAGER') || userRole.includes('PARTNER_ADMIN') || userRole.includes("OPERATOR_ADMIN") ) && (
        this.user.organization &&
        this.user.organization.id === 4
    );
  };


  getMainOrganizationName=async ()=>{
    if(!this.user.organization)
      return ""
    if(!this.user.organization.parent){
      if(AppStore.userLang==="ru")
        return this.user.organization.name
      else
        return this.user.organization.nameEn ? this.user.organization.nameEn : this.user.organization.name
    }else{
      const data = (await OrganizationService.getOrganizationById(this.user.organization.parent))
      console.log("DATA: ",data)
      if(AppStore.userLang==="ru")
        return data.name
      else
        return data.nameEn ? data.nameEn : data.name
    }
  }

  isEnabledMarginRules=()=>{
    return !this.user?.organization?.disableIntegratorReward
  }

  isDisabledInsuranceForm=()=>{
    console.log("IS DISABLED INSURANCE FORM: ",this.user?.organization?.disableInsuranceForm)
    return !!this.user?.organization?.disableCustomsClearanceFee
  }
  isDisabledCustomsClearanceFee=()=>{
    return !!this.user?.organization?.disableInsuranceForm
  }

  hasApplicationId = () => AppStore.appId !== '';

  getRoleTranslation = () => {
    const generalRole = get(this.user, 'generalRole');

    if (!generalRole) {
      return 'common.roleIsUnknown';
    }

    if (generalRole.search(/_ADMIN$/g) >= 0) {
      return 'common.roleAdmin';
    }

    if (generalRole.search(/_MANAGER$/g) >= 0) {
      return 'common.roleManager';
    }

    if (generalRole === CLIENT) {
      return 'common.roleClient';
    }

    return 'common.roleIsUnknown';
  };

  removeIdAtTheEndOfTheRoute = routeWithId => (
    routeWithId.replace(/\/\d+$/g, '')
  );

  isAccessAllowed = (route = '') => {
    const routeToCheck = this.removeIdAtTheEndOfTheRoute(route);

    const OPERATOR_ROUTES = [
      URL.WIZARD,
      URL.CITIES_CITIES,
      URL.ERROR,
      URL.ADMIN,
      URL.ADMIN_ORGANIZATIONS,
      URL.ADMIN_LANGUAGES,
      URL.ADMIN_CURRENCIES,
      URL.ADMIN_CONTAINER_TYPES,
      URL.ADMIN_GENERAL_TYPES,
      URL.ADMIN_LOGISTIC_DOCUMENT_KINDS_CRUD,
      URL.ADMIN_COMMON_CONTAINER_TYPES,
      URL.ADMIN_AUTO_CONTAINER_TYPES,
      URL.ADMIN_AIR_CONTAINER_TYPES,
      URL.ADMIN_SEA_LINES,
      URL.ADMIN_CONSOLIDATORS,
      URL.ADMIN_SEA_PORT_HUBS,
      URL.ADMIN_SURCHARGES,
      URL.ADMIN_AIRLINE_DISCLAIMERS,
      URL.ADMIN_RAILWAY_DISCLAIMERS,
      URL.ADMIN_VEHICLE_DISCLAIMERS,
      URL.ADMIN_TRANSPORT_HUBS,
      URL.ADMIN_AIR_HUBS,
      URL.ADMIN_SERVICES,
      URL.ADMIN_GEOGRAPHY,
      URL.ADMIN_DELIVERY_ORDERS,
      URL.ADMIN_EMPTY_ORDERS,
      URL.ADMIN_GEOGRAPHY_OF_PRESENCE,
      URL.ADMIN_DOCUMENTS,
      URL.ADMIN_RATES_SEA,
      URL.ADMIN_PORT_SERVICES,
      URL.ADMIN_ROAD_TRANSPORTATION,
      URL.ADMIN_COMPLEX_TARIFF,
      URL.ADMIN_RAILWAY_OPERATORS,
      URL.ADMIN_RAILWAY_TRANSPORTATION,
      URL.ADMIN_RAILWAY_SERVICES,
      URL.ADMIN_AIR_TRANSPORTATION,
      URL.ADMIN_AIRPORT_SERVICES,
      URL.ADMIN_FREIGHT_SURCHARGES,
      URL.ADMIN_TRUNKING,
      URL.ADMIN_TRUNKING_INTERNATIONAL,
      URL.ADMIN_PALLET,
      URL.ADMIN_COMMISSION,
      URL.ADMIN_ORGANIZATION_PROFILES,
      URL.ADMIN_ORDERS,
      URL.PARTNER_ORDERS,
      URL.ADMIN_ORDER_TEMPLATE,
      URL.CLIENT_ORDERS,
      URL.ADMIN_PERSONAL_ORDERS,
      URL.ADMIN_EXTERNAL_APIS,
      URL.ADMIN_EXTERNAL_APIS_AGORA,
      URL.ADMIN_REPORTS,
      URL.ADMIN_STATISTICS,
      URL.ADMIN_AUDIT,
      URL.ADMIN_USERS,
      URL.ADMIN_AIRLINES,
      URL.ADMIN_RAILWAY_HUBS,
      URL.ADMIN_SEA_CONDITIONS,
      URL.ADMIN_COUNTRY_CATEGORIES,
      URL.ADMIN_INTEREST_TYPES,
      URL.ADMIN_INSURANCE_RATES,
      // URL.AGREEMENT,
      URL.ADMIN_FORWARDERS,
      URL.ADMIN_CUSTOMS,
      URL.ADMIN_EVENTS,
      URL.ADMIN_STATUSES,
      URL.ADMIN_TRANSPORTATION_RULES,
      URL.ADMIN_CHATBOT,
      URL.ADMIN_CUSTOMS_CLEARANCE,
      URL.ADMIN_TEMPLATES_DOCUMENTS,
      URL.ADMIN_SUBSCRIPTION_CONFIG,
      URL.ADMIN_SUBSCRIPTION_USERS,
      URL.ADMIN_RATING_USERS,
      URL.ADMIN_SUBSCRIPTION_TARIFFS,
        URL.ADMIN_SUBSCRIPTION_MANAGE,
        URL.ADMIN_SUBSCRIPTION_REQUESTS,
        URL.ADMIN_RESOURCE_REPORT,
      URL.ADMIN_ORGANIZATION_ACTS,
      URL.ADMIN_TEMPLATES_DESCR_TARIFFS,
      URL.ADMIN_FILES,
      URL.ADMIN_PROFILE
      // URL.PROFILE,
    ];

    const PARTNER_ROUTES = [
      URL.WIZARD,
      URL.CITIES_CITIES,
      URL.ERROR,
      URL.PARTNER,
      URL.PARTNER_PROFILE,
      URL.PARTNER_ORDERS,
      URL.PARTNER_ORDER_TEMPLATE,
      URL.PARTNER_FREIGHT_SURCHARGES,
      URL.PARTNER_GEOGRAPHY_OF_PRESENCE,
      URL.PARTNER_DOCUMENTS,
      URL.PARTNER_COMMISSION,
      URL.PARTNER_USERS,
      URL.PARTNER_ORGANIZATIONS,
      URL.PARTNER_RATES_SEA,
      URL.PARTNER_ROAD_TRANSPORTATION,
      URL.PARTNER_AIR_TRANSPORTATION,
      URL.ADMIN_COMPLEX_TARIFF,
      URL.PARTNER_TRUNKING,
      URL.PARTNER_COMPLEX_TARIFF,
      URL.PARTNER_DELIVERY_ORDERS,
      URL.PARTNER_PORT_SERVICES,
      URL.PARTNER_AIRPORT_SERVICES,
      URL.PARTNER_RAILWAY_TRANSPORTATION,
      URL.PARTNER_RAILWAY_SERVICES,
      URL.PARTNER_PALLET,
      URL.PARTNER_PERSONAL_ORDERS,
      URL.PARTNER_DELIVERY_GROUP_ORDERS,
      // URL.AGREEMENT,
      URL.PARTNER_CUSTOMS,
      // URL.PROFILE,
      URL.ADMIN_EMPTY_ORDERS,
      URL.PARTNER_TEMPLATES_DOCUMENTS,
      URL.PARTNER_SUBSCRIPTION_MANAGE,
      URL.PARTNER_TEMPLATES_DESCR_TARIFFS,
      URL.PARTNER_STATISTICS,
      URL.PARTNER_REPORTS,
      URL.PARTNER_AIRLINE_DISCLAIMERS,
      URL.PARTNER_RAILWAY_DISCLAIMERS,
      URL.PARTNER_SEA_CONDITIONS,
      URL.PARTNER_VEHICLE_DISCLAIMERS,
      URL.PARTNER_CUSTOMS_CLEARANCE,
      URL.PARTNER_TRUNKING_INTERNATIONAL,
      URL.PARTNER_FILES,
      URL.PARTNER_EMPTY_ORDERS,
    ];

    const CLIENT_ROUTES = [
      URL.WIZARD,
      URL.CITIES_CITIES,
      URL.ERROR,
      URL.CLIENT,
      URL.CLIENT_ORDERS,
      URL.CLIENT_DELIVERY_ORDERS,
      URL.CLIENT_CUSTOMS,
      URL.CLIENT_DOCUMENTS,
      URL.CLIENT_SUBSCRIPTION_MANAGE
      // URL.AGREEMENT,
      // URL.PROFILE,
    ];

    const GUEST_ROUTES = [
      URL.WIZARD,
      URL.CITIES_CITIES,
      URL.ERROR,
      URL.GUEST_ORDERS,
      // URL.AGREEMENT,
    ];

    const allRoutes = [].concat(
      PUBLIC_ROUTES,
      OPERATOR_ROUTES,
      PARTNER_ROUTES,
      CLIENT_ROUTES,
      GUEST_ROUTES,
    );

    if (!allRoutes.includes(routeToCheck)) {
      return false;
    }

    const allowedRoutes = [].concat(
      PUBLIC_ROUTES,
      this.isOperator() ? OPERATOR_ROUTES : [],
      this.isPartner() ? PARTNER_ROUTES : [],
      this.isClient() ? CLIENT_ROUTES : [],
      this.isGuest() ? GUEST_ROUTES : []
    );

    const allAllowedRoutes = allowedRoutes;

    return allAllowedRoutes.includes(routeToCheck);
  };

  isRatesTabVisible = (endpoint) => {
    const {
      INTEGRATOR,
      PORT_AGENT,
      ROAD_TRANSPORTATION,
      RAILWAY_TRANSPORTATION,
      SEA_TRANSPORTATION,
      AIR_CARRIER,
      FORWARDER,
    } = PARTNER_PROFILE_TYPES;

    const profiles = OrganizationProfilesStore.currentUserOrganizationProfiles;
    const profNames = profiles.map(el => get(el, 'value.uid', null));

    const allowedRoutesToEndpoint = {
      [PORT_AGENT]: ['/tariff/sea-port/container', '/tariff/sea-port/general', '/tariff/sea-port/bills'],
      [SEA_TRANSPORTATION]: ['/tariff/sea-freight/container', '/tariff/sea-freight/general', '/surcharge-value/sea-freight/container', '/surcharge-value/sea-freight/general'],
      [ROAD_TRANSPORTATION]: ['/tariff/road-transport/container', '/tariff/road-transport/general', '/tariff/trunking'],
      [RAILWAY_TRANSPORTATION]: ['/tariff/railway/container', '/tariff/railway/general'],
      [AIR_CARRIER]: ['/tariff/air/general', '/tariff/airport/general', '/tariff/airport/bills'],
      [FORWARDER]: ['/tariff/sea-port/forwarding'],
    };

    if (includes(profNames, INTEGRATOR)) {
      return true;
    }
    return profNames.some(el => includes(allowedRoutesToEndpoint[el], endpoint));
  };
}

export default new UserStore();
