import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';

export const createAddRowsComponent = () => ({addRows}) => {
  const [value, setValue] = useState(1)
  const [rawValue, setRawValue] = useState(String(value))

  return (
    <Row className={"mt-3"}>
      <Col xs={12} className={"w-auto d-flex justify-content-end"}>
        <Button
          className={"btn-primary-new"}
          onClick={(v)=>addRows(value)}
        >
          <FormattedMessage id={"orderForm.modal.addContainer"}/>
        </Button>
      </Col>
    </Row>
  )
}

export const emptyComponent = () => ({addRows}) => {
  return (<div></div>)
}
