/* eslint-disable react/no-unused-prop-types */
/* eslint-disable */
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../Modal';

const CrudForm = (props) => {
  const {
    store,
    endpoint,
    item,
    children,
    isShow,
      titleName,
  } = props;

  const content = children ? cloneElement(children, { store, item, endpoint }) : null;
  let title = item ?
    <FormattedMessage id={titleName?titleName.edit:"crud.form.edit.title"} />
    : <FormattedMessage id={titleName?titleName.add:"crud.form.add.title"} />;

  if (store.readOnly) {
    title = <FormattedMessage id={titleName?titleName.view:"crud.form.view.title"} />;
  }

  return (
    <Modal
      isShow={isShow}
      className="crud-modal"
      title={title}
      isModal={true}
      onClose={() => {
        store.cancelEdit();
        if (props.onClose && typeof props.onClose === 'function'){
          props.onClose();
        }
      }}
    >
      {content}
    </Modal>
  );
};

CrudForm.defaultProps = {
  isShow: false,
  crudType: 'form',
};

CrudForm.propTypes = {
  store: PropTypes.object,
  item: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  isShow: PropTypes.bool,
  children: PropTypes.object,
  endpoint: PropTypes.string,
  crudType: PropTypes.string,
  readOnly: PropTypes.bool,
  titleName: PropTypes.object
};

export default CrudForm;
