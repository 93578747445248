import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {Button, Col} from 'reactstrap';
import * as URL from '../../constants/routes';
import { Menu, MenuItem } from '../ui/Menu';
import UserStore from '../../stores/userStore';
import config from "../../config";
import {useHistory} from "react-router";
import {get} from "lodash";
import {
  ADMIN_ORGANIZATION_ACTS,
  ADMIN_SUBSCRIPTION_REQUESTS,
  ADMIN_SUBSCRIPTION_TARIFFS,
  PARTNER_TRUNKING_INTERNATIONAL,
} from '../../constants/routes';
import subscriptionService from "../../services/subscriptionService";
import AppStore from "../../stores/appStore";

const MainMenu = ({ organizationProfiles, expiredTariffs, isOpen, showContactModal, changeGoogleApi }) => {
    const phoneHref = `tel:${config.phoneNumber.replaceAll(' ', '').replaceAll('-', '').replaceAll('(', '').replaceAll(')', '')}`;

    const [enableSubscription, setEnabledSubscription] = useState(false);

    useEffect(()=>{
        if(!UserStore.isGuest())
            subscriptionService.checkEnabled().then(res=>{
                if(res.code===200)
                    setEnabledSubscription(res.data)
            })
    }, [])

    let historyHook = useHistory();
    if (!isOpen) {
    if (UserStore.isOperator()) {
      return (
        <Menu history={historyHook} changeGoogleApi={changeGoogleApi}>
          <MenuItem
            onlyImage
            image="order_menu"
            url={URL.ADMIN_ORDERS}
          />
          {/* <MenuItem
            onlyImage
            image="request_menu"
            url={URL.ADMIN_PERSONAL_ORDERS}
          /> */}
          <MenuItem
            onlyImage
            image="delivery_menu"
            url={URL.ADMIN_DELIVERY_ORDERS}
          />
            <MenuItem
                onlyImage
                image="customs_menu"
                url={URL.ADMIN_CUSTOMS}
            />
            {/*<MenuItem
                isModal
                image="rate_menu"
                headerModal="partner.rates"
            >
                <MenuItem
                    name="partner.rates.sea"
                    url={URL.ADMIN_RATES_SEA}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'sea')[0]}
                >
                </MenuItem>
                <MenuItem
                    name="partner.freightSurcharges"
                    url={URL.ADMIN_FREIGHT_SURCHARGES}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'freight-surcharges')[0]}
                />
                <MenuItem
                    name="partner.rates.portServices"
                    url={URL.ADMIN_PORT_SERVICES}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'port-services')[0]}
                />
                <MenuItem
                    name="partner.rates.roadTransportation"
                    url={URL.ADMIN_ROAD_TRANSPORTATION}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'road-transportation')[0]}
                />
                <MenuItem
                    name="partner.rates.trunking"
                    url={URL.ADMIN_TRUNKING}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'trunking')[0]}
                />
                <MenuItem
                    name="partner.rates.air"
                    url={URL.ADMIN_AIR_TRANSPORTATION}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'air-transportation')[0]}
                />
                <MenuItem
                    name="partner.rates.airportServices"
                    url={URL.ADMIN_AIRPORT_SERVICES}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'airport-services')[0]}
                />
                <MenuItem
                    name="partner.rates.railwayTransportation"
                    url={URL.ADMIN_RAILWAY_TRANSPORTATION}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'railway-transportation')[0]}
                />
                <MenuItem
                    name="partner.rates.railwayServices"
                    url={URL.ADMIN_RAILWAY_SERVICES}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'railway-services')[0]}
                />
                <MenuItem
                    name="partner.rates.excel.tariffs"
                    url={URL.ADMIN_PALLET}
                    expiredTariffs={expiredTariffs.filter(item => item.name === 'pallet')[0]}
                />
            </MenuItem>*/}
          {/*<div className="left-menu_nav_list_common">
            <MenuItem
              onlyImage
              image="common1_menu"
              url={'/'}
            />
            <MenuItem
              onlyImage
              image="common2_menu"
              url={'/'}
            />
          </div>*/}
        </Menu>
      );
    }
    if (UserStore.isPartner()) {
      return (
        <Menu changeGoogleApi={changeGoogleApi}>
          <MenuItem
            onlyImage
            image="order_menu"
            url={URL.PARTNER_ORDERS}
          />
          {/* <MenuItem
            onlyImage
            image="request_menu"
            url={URL.PARTNER_PERSONAL_ORDERS}
          /> */}
          <MenuItem
            onlyImage
            image="delivery_menu"
            url={URL.PARTNER_DELIVERY_ORDERS}
          />
        </Menu>
      );
    }
    if (UserStore.isClient()) {
      return (
        <Menu changeGoogleApi={changeGoogleApi}>
          <MenuItem
            onlyImage
            image="order_menu"
            url={URL.CLIENT_ORDERS}
          />
          <MenuItem
            onlyImage
            image="delivery_menu"
            url={URL.CLIENT_DELIVERY_ORDERS}
          />
        </Menu>
      );
    }
    return null;
  }

  if (UserStore.isOperator()) {
    return (
      <Menu data-profiles-count={organizationProfiles.length} changeGoogleApi={changeGoogleApi}>
        <MenuItem
          image={"subscriptionpartner_menu"}
          name="partner.generalInformation" url={URL.ADMIN_PROFILE}
        />
        {/*<MenuItem
          name="person.wizard"
          url={URL.WIZARD}
          //icon="money"
          isWizard
        />*/}
        {/*<MenuItem name="page.main" url="/" />*/}
        {/*<MenuItem name="partner.orders" isOpen>*/}
        <MenuItem
          image="order_menu"
          name="person.calculations"
          showExpiredOrders
          url={URL.ADMIN_ORDERS}
        />
        {/* <MenuItem
          image="request_menu"
          name="operatorAdmin.personalOrders"
          url={URL.ADMIN_PERSONAL_ORDERS}
        /> */}
        <MenuItem
          image="delivery_menu"
          name="operatorAdmin.deliveryForClients"
          url={URL.ADMIN_DELIVERY_ORDERS}
        />
        <MenuItem
          name="operatorAdmin.emptyOrders"
          image="incompletecalculation_menu"
          url={URL.ADMIN_EMPTY_ORDERS}
        />
        <MenuItem
          image="customs_menu"
          name="operatorAdmin.customsCalculator"
          url={URL.ADMIN_CUSTOMS}
        />
        {/*</MenuItem>*/}
        <MenuItem name="partner.rates" isOpen image="rate_menu">
          <MenuItem
            name="partner.rates.sea"
            url={URL.ADMIN_RATES_SEA}
            icon="ship"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'sea')[0]}
          >
          </MenuItem>
          <MenuItem
            name="partner.freightSurcharges"
            url={URL.ADMIN_FREIGHT_SURCHARGES}
            icon="plus-circle"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'freight-surcharges')[0]}
          />
          <MenuItem
            name="partner.rates.portServices"
            url={URL.ADMIN_PORT_SERVICES}
            icon="ship"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'port-services')[0]}
          />
          <MenuItem
            name="partner.rates.roadTransportation"
            url={URL.ADMIN_ROAD_TRANSPORTATION}
            icon="truck"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'road-transportation')[0]}
          />
          {!UserStore.user?.organization?.international &&
          <MenuItem
            name="partner.rates.trunking"
            url={URL.ADMIN_TRUNKING}
            icon="truck"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'trunking')[0]}
          />
          }
          <MenuItem
            name="partner.rates.trunking.international"
            url={URL.ADMIN_TRUNKING_INTERNATIONAL}
            icon="truck"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'trunking')[0]}
          />
          <MenuItem
            name="partner.rates.air"
            url={URL.ADMIN_AIR_TRANSPORTATION}
            icon="plane"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'air-transportation')[0]}
          />
          <MenuItem
            name="partner.rates.airportServices"
            url={URL.ADMIN_AIRPORT_SERVICES}
            icon="plane"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'airport-services')[0]}
          />
          <MenuItem
            name="partner.rates.railwayTransportation"
            url={URL.ADMIN_RAILWAY_TRANSPORTATION}
            image="train_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'railway-transportation')[0]}
          />
          <MenuItem
            name="partner.rates.railwayServices"
            url={URL.ADMIN_RAILWAY_SERVICES}
            image="train_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'railway-services')[0]}
          />
          <MenuItem
            name="partner.rates.complexTariff"
            url={URL.ADMIN_COMPLEX_TARIFF}
            image="complex_menu"
            //expiredTariffs={expiredTariffs.filter(item => item.name === 'complex-Tariff')[0]}
          />
          <MenuItem
            name="partner.rates.excel.tariffs"
            url={URL.ADMIN_PALLET}
            icon="th"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'pallet')[0]}
          />
          <MenuItem name="operatorAdmin.directories.templateDescriptionTariff"
                    url={URL.ADMIN_TEMPLATES_DESCR_TARIFFS}
                    icon="list"
          />
        </MenuItem>
        <MenuItem
          name="operatorAdmin.users"
          url={URL.ADMIN_USERS}
          image="users_menu"
        />

          <MenuItem name="organizationsProfiles.title" url={URL.ADMIN_ORGANIZATION_PROFILES} image="organizationprofiles_menu" />
          <MenuItem name="operatorAdmin.organizations" url={URL.ADMIN_ORGANIZATIONS} image="organization_menu" />
          <MenuItem name={"operatorAdmin.directories.documentsFlow"} isOpen icon="archive">
            {get(UserStore, 'user.organization.id', 0) !== 0 && UserStore.isManagerOrGreater() === true &&
                <MenuItem name="operatorAdmin.directories.template" url={URL.ADMIN_ORDER_TEMPLATE}/>
            }
            {get(UserStore, 'user.organization.id', 0) !== 0 && UserStore.isManagerOrGreater() === true &&
                <MenuItem name="templates.documents.page.title" url={URL.ADMIN_TEMPLATES_DOCUMENTS}/>
            }
            <MenuItem name="acts.page.title" url={URL.ADMIN_ORGANIZATION_ACTS}/>
            {/*<MenuItem name="files.page.title" url={URL.ADMIN_FILES}/>*/}
          </MenuItem>
        <MenuItem name="operatorAdmin.transportationRules" url={URL.ADMIN_TRANSPORTATION_RULES} image="rulestransportation_menu" />
        <MenuItem
          isShow={UserStore.isEnabledMarginRules()}
          name="partner.salesRules"
          url={URL.ADMIN_COMMISSION}
          image="sales_rules_menu"
        />
        <MenuItem
          name="partner.geography"
          url={URL.ADMIN_GEOGRAPHY_OF_PRESENCE}
          image="geography_menu"
        />
        {/* <MenuItem name="common.documents" url={URL.ADMIN_DOCUMENTS} /> */}
        <MenuItem name="operatorAdmin.insuranceRates" url={URL.ADMIN_INSURANCE_RATES} image="insurancerates_menu" />
        <MenuItem
            name="operatorAdmin.reports"
            image="reports_menu"
            isOpen
        >
            <MenuItem name="rating.page.title" url={URL.ADMIN_RATING_USERS}/>
            <MenuItem name="operatorAdmin.charts" url={URL.ADMIN_REPORTS} />
            <MenuItem name="mainMenu.reportResource" url={URL.ADMIN_RESOURCE_REPORT} />
            <MenuItem name="reports.types.POPULAR_DESTINATIONS" url={URL.ADMIN_STATISTICS} />
        </MenuItem>

        <MenuItem name="operatorAdmin.listOfAPIs" url={URL.ADMIN_EXTERNAL_APIS} image="api_menu" />
        <MenuItem name="operatorAdmin.listOfAPIsAgora" url={URL.ADMIN_EXTERNAL_APIS_AGORA} image="api_menu" />
        <MenuItem name="operatorAdmin.audit" url={URL.ADMIN_AUDIT} image="audit_menu" />
        <MenuItem name="operatorAdmin.directoriesSuperAdmin" image="reference_menu">
          <MenuItem
            name="operatorAdmin.directories.languages"
            url={URL.ADMIN_LANGUAGES}
            icon="language"
          />
          <MenuItem
            name="operatorAdmin.directories.currencies"
            url={URL.ADMIN_CURRENCIES}
            icon="usd"
          />
          <MenuItem
            name="operatorAdmin.directories.typesOfGoods"
            url={URL.ADMIN_COMMON_CONTAINER_TYPES}
            icon="archive"
          />
          <MenuItem
                name="operatorAdmin.directories.typesOfGeneralCargo"
                url={URL.ADMIN_GENERAL_TYPES}
                icon="archive"
          />
          <MenuItem
            name="operatorAdmin.directories.trucks"
            url={URL.ADMIN_AUTO_CONTAINER_TYPES}
            icon="truck"
          />
          <MenuItem
            name="operatorAdmin.directories.airs"
            url={URL.ADMIN_AIR_CONTAINER_TYPES}
            icon="plane"
          />

          <MenuItem
            name="operatorAdmin.directories.seaLines"
            url={URL.ADMIN_SEA_LINES}
            icon="anchor"
          />
          <MenuItem
            name="operatorAdmin.directories.consolidators"
            url={URL.ADMIN_CONSOLIDATORS}
            icon="compress"
          />
          <MenuItem
            name="operatorAdmin.directories.seaPortHubs"
            url={URL.ADMIN_SEA_PORT_HUBS}
            icon="ship"
          />
          <MenuItem
            name="operatorAdmin.directories.freightSurcharges"
            url={URL.ADMIN_SURCHARGES}
            icon="calculator"
          />
          <MenuItem
            name="operatorAdmin.directories.transportHubs"
            url={URL.ADMIN_TRANSPORT_HUBS}
            icon="building"
          />
          <MenuItem
            name="operatorAdmin.directories.airportsHubs"
            url={URL.ADMIN_AIR_HUBS}
            icon="plane"
          />
          <MenuItem
            name="operatorAdmin.directories.geography"
            url={URL.ADMIN_GEOGRAPHY}
            icon="globe"
          />
          <MenuItem
            name="operatorAdmin.directories.services"
            url={URL.ADMIN_SERVICES}
            icon="list"
          />
          <MenuItem
            name="operatorAdmin.directories.airlines"
            url={URL.ADMIN_AIRLINES}
            icon="plane"
          />
          <MenuItem
            name="operatorAdmin.directories.railwayHubs"
            url={URL.ADMIN_RAILWAY_HUBS}
            icon="train"
          />
          <MenuItem
              name="operatorAdmin.directories.railwayOperators"
              url={URL.ADMIN_RAILWAY_OPERATORS}
              icon="train"
          />
          <MenuItem
            name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.seaConditions`}
            url={URL.ADMIN_SEA_CONDITIONS}
            icon="dollar"
          />
          <MenuItem
            name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.airlineDisclaimers`}
            url={URL.ADMIN_AIRLINE_DISCLAIMERS}
            icon="dollar"
          />
          <MenuItem
            name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.railwayDisclaimers`}
            url={URL.ADMIN_RAILWAY_DISCLAIMERS}
            icon="dollar"
          />
          <MenuItem
            name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.vehicleDisclaimers`}
            url={URL.ADMIN_VEHICLE_DISCLAIMERS}
            icon="dollar"
          />
          <MenuItem
            name="operatorAdmin.directories.forwarders"
            url={URL.ADMIN_FORWARDERS}
            icon="user"
          />
          <MenuItem
            name="operatorAdmin.directories.interestTypes"
            url={URL.ADMIN_INTEREST_TYPES}
            icon="archive"
          />
          <MenuItem
            name="operatorAdmin.directories.countryCategories"
            url={URL.ADMIN_COUNTRY_CATEGORIES}
            icon="globe"
          />
          <MenuItem
            name="operatorAdmin.directories.events"
            url={URL.ADMIN_EVENTS}
            icon="calendar"
          />
          <MenuItem
            name="operatorAdmin.directories.statuses"
            url={URL.ADMIN_STATUSES}
            icon="list"
          />
            <MenuItem
                name="operatorAdmin.directories.customsClearance"
                url={URL.ADMIN_CUSTOMS_CLEARANCE}
                icon="list"
            />
            <MenuItem
                name="operatorAdmin.directories.logisticDocumentKindTitle"
                url={URL.ADMIN_LOGISTIC_DOCUMENT_KINDS_CRUD}
                icon="archive"
            />

        </MenuItem>
          <MenuItem
              name="operatorAdmin.directories.chatbot"
              image="chatbot_menu"
              url={URL.ADMIN_CHATBOT}
          />
        <div className="left-menu_nav_list_other-link">
          <MenuItem
            name="site.leftMenu.link.profile"
            url={URL.PROFILE}
          />
          <MenuItem
            name="site.leftMenu.link.termsOfUse"
            url={URL.AGREEMENT}
          />
            {/*<li className="d-md-none">
                <div className="header__buttons_wizard_btn">
                    <Button style={{width:"100%"}} onClick={()=>{historyHook.push(URL.WIZARD)}}>
                        { <FormattedMessage id="person.wizard" /> }
                    </Button>
                </div>
            </li>*/}
            <li>
            {/*<Button
                className="contactBtn"
                onClick={showContactModal}
            >
                <div style={{display:"flex"}}>
                <div className="phone-block" style={{marginLeft:"auto", display:"block"}}>
                    <a href={phoneHref} className={'phone-link'}>
                        <div className={'phone-link-icon white-icon'} style={{display:"inline-block"}}/>
                    </a>
                </div>
                <FormattedMessage id="site.leftMenu.link.contactUs" />
                </div>
            </Button>*/}
            </li>

        </div>
      </Menu>
    );
  }

  if (UserStore.isPartner()) {
    return (
      <Menu data-profiles-count={organizationProfiles.length} changeGoogleApi={changeGoogleApi}>
        {/*<MenuItem
          name="person.wizard"
          url={URL.WIZARD}
          //icon="money"
          isWizard
        />*/}
        {/*<MenuItem name="page.main" url="/" />*/}
        <MenuItem
          image={"subscriptionpartner_menu"}
          name="partner.generalInformation" url={URL.PARTNER_PROFILE}
        />
        {/*<MenuItem name="partner.orders" isOpen>*/}
        <MenuItem
          image="order_menu"
          name="person.calculations"
          showExpiredOrders
          url={URL.PARTNER_ORDERS}
        />
          {enableSubscription &&
              <MenuItem
                  name="mainMenu.mySubscription"
                  url={URL.PARTNER_SUBSCRIPTION_MANAGE}
                  image="subscription"
              />
          }
        {/* <MenuItem
          image="request_menu"
          name="partner.personalOrders"
          url={URL.PARTNER_PERSONAL_ORDERS}
        /> */}
        {(!UserStore.isManagerCargobar()&&!UserStore.isClientOfPartnerSaas())&&
          <MenuItem
            name="operatorAdmin.emptyOrders"
            image="incompletecalculation_menu"
            url={URL.PARTNER_EMPTY_ORDERS}
          />
        }
        {UserStore.isManagerCargobar() && <MenuItem
          image="incompletecalculation_menu"
          name="operatorAdmin.emptyOrders"
          url={URL.ADMIN_EMPTY_ORDERS}
        />}
        {UserStore.isManagerCargobar() && <MenuItem
          name="operatorAdmin.users"
          url={URL.ADMIN_USERS}
          image="users_menu"
        />}
        <MenuItem
          image="delivery_menu"
          name="partner.deliveryForClients"
          url={URL.PARTNER_DELIVERY_ORDERS}
        />

          {AppStore.userLang === 'ru' &&
                <MenuItem
                  image="customs_menu"
                  name="operatorAdmin.customsCalculator"
                  url={URL.PARTNER_CUSTOMS}
                />
          }

        {/*</MenuItem>*/}

        <MenuItem name="partner.rates" isOpen image="rate_menu" isShow={organizationProfiles.length && !UserStore.isClientOfPartnerSaas()}>
          <MenuItem
            name="partner.rates.sea"
            url={URL.PARTNER_RATES_SEA}
            // icon="ship"
            image="sea_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'sea')[0]}
            isOpen
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Sea_transportation' && profile.status === 'APPROVED')}
          >
          </MenuItem>
          <MenuItem
            name="partner.freightSurcharges"
            url={URL.PARTNER_FREIGHT_SURCHARGES}
            // icon="plus-circle"
            image="sea_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'freight-surcharges')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Forwarder' && profile.status === 'APPROVED')}
          />
          <MenuItem
            name="partner.rates.portServices"
            url={URL.PARTNER_PORT_SERVICES}
            // icon="ship"
            image="sea_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'port-services')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Port_agent' && profile.status === 'APPROVED')}
          />
          <MenuItem
            name="partner.rates.roadTransportation"
            url={URL.PARTNER_ROAD_TRANSPORTATION}
            // icon="truck"
            image="truck_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'road-transportation')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Road_transportation' && profile.status === 'APPROVED')}
          />
          {!UserStore.user?.organization?.international &&
            <MenuItem
              name="partner.rates.trunking"
              url={URL.PARTNER_TRUNKING}
              // icon="truck"
              image="truck_menu"
              expiredTariffs={expiredTariffs.filter(item => item.name === 'trunking')[0]}
              isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Mainline_carrier' && profile.status === 'APPROVED')}
            />
          }
          <MenuItem
            name="partner.rates.trunking.international"
            url={URL.PARTNER_TRUNKING_INTERNATIONAL}
            // icon="truck"
            image="truck_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'trunking')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Mainline_carrier' && profile.status === 'APPROVED')}
          />
          <MenuItem
            name="partner.rates.air"
            url={URL.PARTNER_AIR_TRANSPORTATION}
            // icon="plane"
            image="air_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'air-transportation')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Air_carrier' && profile.status === 'APPROVED')}
          />
          <MenuItem
            name="partner.rates.airportServices"
            url={URL.PARTNER_AIRPORT_SERVICES}
            // icon="plane"
            image="air_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'airport-services')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Air_carrier' && profile.status === 'APPROVED')}
          />

          <MenuItem
            name="partner.rates.railwayTransportation"
            url={URL.PARTNER_RAILWAY_TRANSPORTATION}
            image="train_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'railway-transportation')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Railway_transportation' && profile.status === 'APPROVED')}
          />
          <MenuItem
            name="partner.rates.railwayServices"
            url={URL.PARTNER_RAILWAY_SERVICES}
            image="train_menu"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'railway-services')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Railway_transportation' && profile.status === 'APPROVED')}
          />
          <MenuItem
            name="partner.rates.excel.tariffs"
            url={URL.PARTNER_PALLET}
            icon="th"
            expiredTariffs={expiredTariffs.filter(item => item.name === 'pallet')[0]}
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Pallet' && profile.status === 'APPROVED')}
          />
          <MenuItem
            name="partner.rates.complexTariff"
            url={URL.PARTNER_COMPLEX_TARIFF}
            image="complex_menu"
            isShow={!!organizationProfiles.find(profile => profile.value.uid === 'complex_transportation' && profile.status === 'APPROVED')}
            //expiredTariffs={expiredTariffs.filter(item => item.name === 'complex-Tariff')[0]}
          />
          <MenuItem name="operatorAdmin.directories.templateDescriptionTariff"
                    url={URL.PARTNER_TEMPLATES_DESCR_TARIFFS}
                    icon="list"
          />
        </MenuItem>
        <MenuItem
          name="partner.salesRules"
          image="sales_rules_menu"
          url={URL.PARTNER_COMMISSION}
          isShow={!UserStore.isClientOfPartnerSaas()}
        />

        <MenuItem
          name="partner.geography"
          image="geography_menu"
          url={URL.PARTNER_GEOGRAPHY_OF_PRESENCE}
          isShow={!UserStore.isPartnerSaas() && !UserStore.isClientOfPartnerSaas()}
        />
        <MenuItem
          name="operatorAdmin.organizations"
          image="users_menu"
          url={URL.PARTNER_ORGANIZATIONS}
          isShow={UserStore.isPartnerSaas() && UserStore.isAdminOrganization()}
        />
        <MenuItem
          name="partner.users"
          image="users_menu"
          url={URL.PARTNER_USERS}
          isShow={UserStore.isPartnerSaas() && UserStore.isAdminOrganization()}
        />
        <MenuItem
          name="operatorAdmin.reports"
          image="reports_menu"
          isOpen
          isShow={UserStore.isPartnerSaas() && UserStore.isAdminOrganization()}
        >
          {/*<MenuItem name="rating.page.title" url={URL.ADMIN_RATING_USERS}/>*/}
          <MenuItem
            name="operatorAdmin.charts"
            url={URL.PARTNER_REPORTS}
            isShow={UserStore.isPartnerSaas() && UserStore.isAdminOrganization()}
          />
          {/*<MenuItem name="mainMenu.reportResource" url={URL.ADMIN_RESOURCE_REPORT} />*/}
          <MenuItem
            name="reports.types.POPULAR_DESTINATIONS"
            url={URL.PARTNER_STATISTICS}
            isShow={UserStore.isPartnerSaas() && UserStore.isAdminOrganization()}
          />
        </MenuItem>
        {/* <MenuItem name="common.documents" url={URL.PARTNER_DOCUMENTS} /> */}
        <div className="left-menu_nav_list_other-link">
          <MenuItem
            name="site.leftMenu.link.profile"
            url={URL.PROFILE}
          />
            <MenuItem name={"operatorAdmin.directories.documentsFlow"} isOpen icon="archive" isShow={UserStore.isManagerOrGreater() && !UserStore.isClientOfPartnerSaas()}>
                {get(UserStore, 'user.organization.id', 0) !== 0 && UserStore.isManagerOrGreater() === true &&
                    <MenuItem name="operatorAdmin.directories.template" url={URL.PARTNER_ORDER_TEMPLATE}/>
                }
                {get(UserStore, 'user.organization.id', 0) !== 0 && UserStore.isManagerOrGreater() === true && !UserStore.isClientOfPartnerSaas() &&
                    <MenuItem name="templates.documents.page.title" url={URL.PARTNER_TEMPLATES_DOCUMENTS}/>
                }
            </MenuItem>
          <MenuItem name="operatorAdmin.directories"
                    isShow={UserStore.isAdminOrganization() && !UserStore.isClientOfPartnerSaas()}
                    image="reference_menu">
            <MenuItem
              name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.seaConditions`}
              url={URL.PARTNER_SEA_CONDITIONS}
              isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Sea_transportation' && profile.status === 'APPROVED')}
              icon="dollar"
            />
            <MenuItem
              name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.airlineDisclaimers`}
              url={URL.PARTNER_AIRLINE_DISCLAIMERS}
              isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Air_carrier' && profile.status === 'APPROVED')}
              icon="dollar"
            />
            <MenuItem
              name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.railwayDisclaimers`}
              url={URL.PARTNER_RAILWAY_DISCLAIMERS}
              isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Railway_transportation' && profile.status === 'APPROVED')}
              icon="dollar"
            />
            <MenuItem
              name={`operatorAdmin.${AppStore.userLang==="en"?'menu.':''}directories.vehicleDisclaimers`}
              url={URL.PARTNER_VEHICLE_DISCLAIMERS}
              isShow={!!organizationProfiles.find(profile => profile.value.uid === 'Road_transportation' && profile.status === 'APPROVED')}
              icon="dollar"
            />
            <MenuItem
              name="operatorAdmin.directories.customsClearance"
              url={URL.PARTNER_CUSTOMS_CLEARANCE}
              icon="list"
            />
          </MenuItem>
          <MenuItem
            name="site.leftMenu.link.termsOfUse"
            url={URL.AGREEMENT}
          />
            {/*<li className="d-md-none">
                <div className="header__buttons_wizard_btn">
                    <Button style={{width:"100%"}} onClick={()=>{historyHook.push(URL.WIZARD)}}>
                        { <FormattedMessage id="person.wizard" /> }
                    </Button>
                </div>
            </li>*/}
            <li>
               {/* <Button
                    className="contactBtn"
                    onClick={showContactModal}
                >
                    <div style={{display:"flex"}}>
                        <div className="phone-block" style={{marginLeft:"auto", display:"block"}}>
                            <a href={phoneHref} className={'phone-link'}>
                                <div className={'phone-link-icon white-icon'} style={{display:"inline-block"}}/>
                            </a>
                        </div>
                        <FormattedMessage id="site.leftMenu.link.contactUs" />
                    </div>
                </Button>*/}
            </li>
        </div>
      </Menu>
    );
  }

  if (UserStore.isClient()) {
    return (
      <Menu data-profiles-count={organizationProfiles.length} changeGoogleApi={changeGoogleApi}>
        {/*<MenuItem
          name="person.wizard"
          url={URL.WIZARD}
          //icon="money"
          isWizard
        />*/}
        {/*<MenuItem name="page.main" url="/" />*/}
        {/*<MenuItem name="partner.orders" isOpen>*/}
        <MenuItem
          image="order_menu"
          name="person.calculations"
          showExpiredOrders
          url={URL.CLIENT_ORDERS}
        />
        <MenuItem
          image="delivery_menu"
          name="person.delivery"
          url={URL.CLIENT_DELIVERY_ORDERS}
        />
        {/* <MenuItem
          name="common.documents"
          url={URL.CLIENT_DOCUMENTS}
        /> */}
        <MenuItem
          image="customs_menu"
          name="operatorAdmin.customsCalculator"
          url={URL.CLIENT_CUSTOMS}
        />
          {enableSubscription &&
              <MenuItem
                  name="mainMenu.mySubscription"
                  url={URL.CLIENT_SUBSCRIPTION_MANAGE}
                  image="subscription"
              />
          }
        {/*</MenuItem>*/}
        <div className="left-menu_nav_list_other-link">
          <MenuItem
            name="site.leftMenu.link.profile"
            url={URL.PROFILE}
          />
          <MenuItem
            name="site.leftMenu.link.termsOfUse"
            url={URL.AGREEMENT}
          />
            {/*<li className="d-md-none">
                <div className="header__buttons_wizard_btn">
                    <Button style={{width:"100%"}} onClick={()=>{historyHook.push(URL.WIZARD)}}>
                        { <FormattedMessage id="person.wizard" /> }
                    </Button>
                </div>

            </li>*/}
            <li>
               {/* <Button
                    className="contactBtn"
                    onClick={showContactModal}
                >
                    <div style={{display:"flex"}}>
                        <div className="phone-block" style={{marginLeft:"auto", display:"block"}}>
                            <a href={phoneHref} className={'phone-link'}>
                                <div className={'phone-link-icon white-icon'} style={{display:"inline-block"}}/>
                            </a>
                        </div>
                        <FormattedMessage id="site.leftMenu.link.contactUs" />
                    </div>
                </Button>*/}
            </li>
        </div>
      </Menu>
    );
  }

  return null;
};

MainMenu.propTypes = {
  organizationProfiles: PropTypes.object,
  expiredTariffs: PropTypes.object,
  isOpen: PropTypes.bool,
  showContactModal: PropTypes.func,
  changeGoogleApi: PropTypes.func,
};

export default MainMenu;
