import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { get } from 'lodash';
import ToggleDropdown from './ToggleDropdown';
import { getExistLangValue } from '../../utils/utils';
import UserStore from "../../stores/userStore";

function CustomsClearanceFee({ isIncluded, switchInclude, customsData,isShow }) {
  // const amount = +get(customsData, 'containerAmount');
  const costValue = get(customsData, 'cost.value', null);

  return (
    <div className="block-margin-top mb-4">
      <ToggleDropdown
        isShown={isIncluded}
        label={<FormattedMessage id="wizard.customsOfCargo.title" />}
        onChange={switchInclude}
      >
        { customsData && !!costValue &&
        <p>
          {/* { !!amount &&
          <span>{amount} {getWordDeclension(amount, 'container', 'wizard.customsOfCargo')}: </span>
          } */}
          {/* <span className="customsClearance__price">
            { costValue } { get(customsData, 'cost.currency') }
          </span> */}
        </p>
        }
        {/*eslint-disable*/}
        { customsData && get(customsData, 'conditions') &&
        <p className="customsClearance__desc" dangerouslySetInnerHTML={{ __html: getExistLangValue(get(customsData, 'conditions')) }} />
        }
      </ToggleDropdown>
    </div>
  );
}

CustomsClearanceFee.propTypes = {
  isIncluded: PropTypes.bool,
  switchInclude: PropTypes.func,
  customsData: PropTypes.object,
  isShow: PropTypes.bool,
};

export default CustomsClearanceFee;
