import React from 'react';
import { cloneDeep, isString, isArray, isEmpty as _isEmpty, get, has, compact, uniq, find, set } from 'lodash';
import moment from 'moment';
import AppStore from '../stores/appStore';
import DialogStore from '../stores/dialogStore';
import { DEFAULT_DELAY, ICON_NAME_BY_LOCATION, USER_LANG, TYPE_OF_LOCATIONS } from '../constants/global';
import { ORDERS_SORT_FILTER, VEHICLE_BODY_TYPE, WEIGHT_UNITS } from '../constants/staticData';
import {
  ADMIN_ORDERS,
  ADMIN_DELIVERY_ORDERS,
  PARTNER_DELIVERY_ORDERS,
  PARTNER_ORDERS,
  CLIENT_ORDERS,
  CLIENT_DELIVERY_ORDERS,
  ADMIN_ORGANIZATIONS,
  PARTNER_PROFILE,
  WIZARD,
  LOCALHOST,
  TEST,
  PRE_PROD_LANDING,
  PROD_LANDING,
  TEST_CARGOBAR,
  PRE_PROD_CARGOBAR,
  PROD_CARGOBAR,
  GUEST_ORDERS, ADMIN_SUBSCRIPTION_MANAGE, PARTNER_SUBSCRIPTION_MANAGE, CLIENT_SUBSCRIPTION_MANAGE,
} from '../constants/routes';
import * as ROLES from '../constants/userRoles';
import FlashStore from "../stores/flashStore";
import UserStore from '../stores/userStore';
import * as URL from '../constants/routes';

export const TYPE_STATISTIC={
  POPULAR_DESTINATIONS:"POPULAR_DESTINATIONS"
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const promiseWithTimeout = (params = {}) => {
  const {
    promise = () => Promise.resolve(),
    timeout = DEFAULT_DELAY,
  } = params;

  return new Promise((resolve, reject) => {
    const rejectByTimeout = setTimeout(reject, timeout);

    promise()
        .then((response) => {
          clearTimeout(rejectByTimeout);
          resolve(response);
        })
        .catch((reason) => {
          clearTimeout(rejectByTimeout);
          reject(reason);
        });
  });
};

export const addHashParamToUrl = (parameter) => {
  if (!parameter) {
    return;
  }

  history.replaceState('', document.title, (
      `${window.location.pathname}#${parameter}`
  ));
};

export const deleteAllHashParamsFromUrl = () => {
  history.replaceState('', document.title, window.location.pathname);
};

export const translate = (param) => {
  if (!param) {
    return '';
  }

  let value = param;

  if (isString(param)) {
    value = { id: param };
  }

  return AppStore.translate(value);
};

export const formatFullName = (user) => {
  if (_isEmpty(user)) {
    return '';
  }

  return (
      `${user.lastName || ''} ${user.firstName || ''} ${user.middleName || ''}`
  );
};

export const convertErrorsArrayToObject = (errors) => {
  if (_isEmpty(errors)) {
    return {};
  }

  return errors.reduce((previous, error) => ({
    ...previous,
    [error.fieldName]: error.errorText,
  }), {});
};

export const urlParams = (obj, isEmpty = false) => {
  if (!obj) {
    return '';
  }

  return Object.keys(obj).map((key) => {
    let param = '';

    if (isEmpty || obj[key] !== '' || obj[key] !== undefined) {
      param = `${key}=${encodeURIComponent(obj[key])}`;
    }

    return param;
  }).filter(item => !!item).join('&');
};

export const getCountryFromGeosuggestAddress = (place) => {
  let prepareValue = {};

  if (!place) {
    return null;
  }

  const countrySegment = place.gmaps.address_components.find(item => item.types.indexOf('country') > -1);

  if (countrySegment) {
    prepareValue = {
      name: countrySegment.long_name,
      code: countrySegment.short_name.toUpperCase(),
    };
  }

  return prepareValue;
};

/**
 * Возвращает название поля в зависимости от языка системы
 *
 * @param {object} item
 *
 * @return String
 */
export function getExistLangValue(item) {
  if (item) {
    const clonedItem = cloneDeep(item);

    let resultValue = clonedItem[AppStore.userLang];
    if (_isEmpty(resultValue)) {
      const langPriority = ['en','ru','zh'];
      const lang = langPriority.filter((_lang) => { return !!(item[_lang]); })[0] || '';

      resultValue = clonedItem[lang];
    }

    return resultValue;
  }

  return '-';
}

/**
 * Добавляет поле formatName для структур данных, приходящих с бекенда.
 *
 * @param {array} data
 *
 * @return Array
 */
export const addFormatName = (data) => {
  return data.map((item) => {
    const newItem = cloneDeep(item);
    newItem.formatName = getExistLangValue(newItem.name);

    return newItem;
  });
};

export function addFormatNameToConstant(data) {
  return data.map((item) => {
    const newItem = cloneDeep(item);
    newItem.formatName = translate({ id: `constants.${item.message}` });

    return newItem;
  });
}

export function getQuantitySizeUnits(data, type) {
  return data.filter((item) => item.typeQuantity === type);
}

export const addFormatNameForAirsWithCode = (data) => {
  return data.map((item) => {
    const newItem = cloneDeep(item);
    const name = getExistLangValue(newItem.name);

    const addInfo = get(newItem, 'code', false) ? ` (${newItem.code})` : '';
    newItem.formatName = `${name}${addInfo}`;

    return newItem;
  });
};

export const addFormatNameForAirsWithCity = (data) => {
  return data.map((item) => {
    const newItem = cloneDeep(item);
    const name = getExistLangValue(newItem.name);
    const cityName = getExistLangValue(newItem.city.name);

    newItem.formatName = `${name} (${cityName})`;

    return newItem;
  });
};

export const addFormatNameWithCode = (data) => {
  return data.map((item) => {
    const newItem = cloneDeep(item);
    const name = getExistLangValue(newItem.city.name);
    const hubName = getExistLangValue(newItem.name);
    const addInfo = get(newItem, 'hasTerminals', false) ? ` (${translate({ id: 'form.wizard.allTerminals' })})` : ` (${newItem.code})`;
    newItem.formatName = `${hubName}, ${name}${addInfo}`;
    return newItem;
  });
};

export const addFormatNameToCity = (data) => {
  return data.map((item) => {
    const newItem = cloneDeep(item);
    const name = getExistLangValue(newItem.city.name);
    const hubName = getExistLangValue(newItem.name);
    newItem.formatName = `${hubName}, ${name}`;
    return newItem;
  });
};

export const addFormatNameToAddress = (data) => {
  let cloneData = cloneDeep(data);
  if (data.errorId !== undefined) {
    cloneData = [];
  }
  return cloneData.map((item) => {
    const newItem = cloneDeep(item);
    newItem.formatName = newItem.description;
    return newItem;
  });
};

export const addFormatNameWithHub = (data) => {
  return data.map((item) => {
    const newItem = cloneDeep(item);
    const cityName = getExistLangValue(newItem.city.name);
    const name = getExistLangValue(newItem.name);

    newItem.formatName = `${cityName} (${name})`;
    return newItem;
  });
};


export const setName = (item) => {
  const newItem = cloneDeep(item);
  if(newItem && newItem.name)
    return getExistLangValue(newItem.name);
  return ("-")
};

export const redirectToSubscription=(role)=>{
  let url = null;

  switch (role) {
    case ROLES.OPERATOR_ADMIN:
      url = ADMIN_SUBSCRIPTION_MANAGE;
      break;

    case ROLES.PARTNER_ADMIN:
    case ROLES.PARTNER_MANAGER:
      url = PARTNER_SUBSCRIPTION_MANAGE;
      break;

    case ROLES.GUEST:
      url = GUEST_ORDERS;
      break;

    case ROLES.CLIENT:
    default:
      url = CLIENT_SUBSCRIPTION_MANAGE;
      break;
  }
  return url
}

/**
 * Производит перенаправления на страницу расчета в зависимости от роли пользователя
 * @param role - роль пользователя
 * @param id - id расчета
 * @param history = this.props.history
 * @param hash - hash
 */
export const orderRedirect = (role, id, history, hash = '', widget, startSearching, isUpdate) => {
  let urlPrefix = null;

  switch (role) {
    case ROLES.OPERATOR_ADMIN:
      urlPrefix = ADMIN_ORDERS;
      break;

    case ROLES.PARTNER_ADMIN:
    case ROLES.PARTNER_MANAGER:
      urlPrefix = PARTNER_ORDERS;
      break;

    case ROLES.GUEST:
      urlPrefix = GUEST_ORDERS;
      break;

    case ROLES.CLIENT:
    default:
      urlPrefix = CLIENT_ORDERS;
      break;
  }

  console.log(history.location);
  console.log("ORDER REDIRECT: ", `${urlPrefix}/${id}${hash ? `#${hash}` : ''}${isUpdate===true?'?update':''}${widget ? '?isWidget=true' : ''}`)
  history.push({
    pathname: `${urlPrefix}/${id}${hash ? `#${hash}` : ''}${isUpdate===true?'?update':''}${widget ? '?isWidget=true' : ''}`,
    state: {
      orderId: id,
      widget,
      startSearching,
    },
  });
};
/**
 * Производит перенаправления на страницу доставки в зависимости от роли пользователя
 * @param role
 * @param history
 */
export const deliveryRedirect = (role, history) => {
  let urlPrefix = null;

  switch (role) {
    case ROLES.OPERATOR_ADMIN:
      urlPrefix = ADMIN_DELIVERY_ORDERS;
      break;

    case ROLES.PARTNER_ADMIN:
    case ROLES.PARTNER_MANAGER:
      urlPrefix = PARTNER_DELIVERY_ORDERS;
      break;

    case ROLES.CLIENT:
    default:
      urlPrefix = CLIENT_DELIVERY_ORDERS;
      break;
  }

  history.push(urlPrefix);
};

/**
 * Производит перенаправления на стартовую страницу в зависимости от роли пользователя
 * @param role
 * @param history
 */
export const startPageRedirect = (role, history) => {
  const urlPrefix = getFooterPageUrl(role);
  if (urlPrefix) {
    let domainV3 = ""
    const user = UserStore.user;

    if(user.organization && user.organization.domain && window.location.host.split(".").length===2){
      const host = window.location.host;
      domainV3 = host.includes(`${user.organization.domain}.`)===true?host:`${user.organization.domain}.${host}`
      if(window.location.host.includes("localhost")===false && window.location.host.includes(domainV3)===false)
        window.location.assign(`https://${domainV3}${urlPrefix}?token=${localStorage.getItem("token")}`);
      else
        history.push(urlPrefix);
    }else {
      history.push(urlPrefix);
    }
  }
};

export function getStartPageUrl(role) {
  switch (role) {
    case ROLES.OPERATOR_ADMIN:
      return ADMIN_ORGANIZATIONS;

    case ROLES.PARTNER_ADMIN:
    case ROLES.PARTNER_MANAGER:
      return PARTNER_PROFILE;

    case ROLES.CLIENT:
      return WIZARD;

    default:
      return null;
  }
}

export function getFooterPageUrl(role) {
  switch (role) {
    case ROLES.OPERATOR_ADMIN:
      return ADMIN_DELIVERY_ORDERS;

    case ROLES.PARTNER_ADMIN:
    case ROLES.PARTNER_MANAGER:
      return PARTNER_DELIVERY_ORDERS;

    case ROLES.CLIENT:
      return CLIENT_DELIVERY_ORDERS;

    default:
      return null;
  }
}

export function pushRouteOrRedirect(history, pathname) {
  if (history.action === 'POP') {
    const appHistory = AppStore.appBrowserHistory;
    const prevUrl = appHistory[appHistory.length - 1];
    const lastSlashIndex = prevUrl.lastIndexOf('/');
    const orderUrlArr = [ADMIN_ORDERS, PARTNER_ORDERS, CLIENT_ORDERS, GUEST_ORDERS];

    if (pathname === WIZARD && orderUrlArr.includes(prevUrl.substring(0, lastSlashIndex))) {
      const orderId = prevUrl.substring(lastSlashIndex + 1);
      history.push(`${pathname}#${orderId}`);
      window.location.reload(true);
      return;
    }
  }

  AppStore.pushRouteInHistory(pathname);
}

/**
 * Транслирует "camelCaseText в Camel Case Text".
 *
 * @param text
 */
export const camelCaseToTile = (text) => {
  return text
      .replace(/\.[a-z]/g, str => str.substring(1).toUpperCase())
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (str) => {
        return str.toUpperCase();
      });
};

/**
 * Генерирует уникальный ключ для перебора массивов
 */
export const keyGenerator = (() => {
  let i;

  return () => {
    i = i ? i += 1 : 1;
    return i.toString();
  };
})();

export const generateKey = (pre) => {
  return `${ pre }_${Math.random()}_${ new Date().getTime() }`;
}


/**
 * Переводит цифры в строку и форматирует их вид 9000 -> 9 000
 *
 * @param cost
 * @returns {string}
 */
export const formatCost = (cost) => {
  const fixedCost = cost.toFixed(2);

  if (fixedCost.search(/\.[0]{2}/g) === -1) {
    return fixedCost.replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');
  }
  return fixedCost.replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ').replace(/\.[0]{2}/g, '');
};

/**
 *
 * @param {string} message
 * @param {function} callback
 */
export const confirm = (message, callback) => {
  DialogStore.confirm(message, callback);
};

/**
 *
 * @param {string} message
 * @param {string} className
 * @param {function} callback
 */
export const confirmStyled  = (message, className, callback) => {
  DialogStore.confirmStyled(message, className, callback);
};

/**
 *
 * @param {Array} array
 * @param {any} value
 * @param {string} field
 */
export const findByField = (array, field, value) => {
  return array.find(item => item[field] === value);
};

export function addIdFieldToContainerRates(_item) {
  const item = cloneDeep(_item);
  if (has(item, 'cargoContainerRates')) {
    item.cargoContainerRates = item.cargoContainerRates.map((el) => {
      const newItem = cloneDeep(el);

      newItem.containerTypeId = el.containerType.id;
      delete newItem.containerType;

      return newItem;
    });
  }
  return item;
}

export const getLocationName = (location = {}) => {
  if (location.hub) {
    return setName(location.hub) || '-';
  }

  if (location.city) {
    return setName(location.city) || '-';
  }

  if (location.address) {
    return get(location, 'address', '-');
  }

  return '-';
};

export const getLocationNameFull = (location = {}) => {
  return getExistLangValue(location.formatted);
};
export const getCountryNameFull = (location = {}) => {
  return getExistLangValue(location.name);
};

export function filterArrayToUniq(arr) {
  return uniq(compact(arr));
}

export const getLocationNameWithIcon = (location = {}) => {
  const icon = ICON_NAME_BY_LOCATION[location.type] || '';
  let locationName = location.type === TYPE_OF_LOCATIONS.AIRPORT_HUB ? setName(location.city) : getLocationName(location);
  if (locationName === 'true') {
    locationName = (location.completeLocation[getLangInStorage()]);
  }
  return getCellWithIconJSX(icon, locationName);
};

export  const getAirLocationWithIcon = (item, where) => {
  const airport = getExistLangValue(get(item, `${where}.hub.name`));
  const city = getExistLangValue(get(item, `${where}.city.name`));
  const icon = ICON_NAME_BY_LOCATION['AIRPORT_HUB'] || '';
  return getCellWithIconJSX(icon, `${airport} (${city})`);
};

export const getLocationNameTruckWithIcon = (location = {}) => {
  const icon = ICON_NAME_BY_LOCATION[location.type] || '';

  return getCellWithIconJSX(icon, setName(location));
};
export const getTransitNameTruckWithIcon = (location = {}) => {
  const icon = ICON_NAME_BY_LOCATION[location.type] || '';

  return getCellWithIconJSX(icon, setName(location));
};

export const getDirectionWithIcon = (direction = '', text) => {
  const directionToIcon = {
    from: 'from-location-icon',
    to: 'to-location-icon',
  };
  const icon = directionToIcon[direction] || '';

  return getCellWithIconJSX(icon, text);
};

export const getCellWithIconJSX = (icon, text = '-') => {
  return (
      <span className="cell-with-icon">
      <i className={`${icon} ${AppStore.iconsColorClassName}`} />
        { text }
    </span>
  );
};

export const getIconByServiceName = (serviceName, length) => {
  const serviceToIcon = {
    freight: 'sea-icon',
    services_departure: 'sea-icon',
    services_destination: 'sea-icon',
    air_transportation: 'air-icon',
    airport_service: 'air-icon',
    cargo_auto_transportation: 'truck-icon',
    Transportation: 'truck-icon',
    main_line_haulage: 'truck-icon',
    railway_services: 'train-icon',
    railway_station_departure: 'train-icon',
    railway_station_destination: 'train-icon',
    transportation_cat: 'truck-icon',
    auto_freight: ['truck-icon', 'sea-icon'],
    auto_air: ['truck-icon', 'air-icon'],
    complex: 'complex-icon',
    document: 'document-icon'

  };
  const iconName = serviceToIcon[serviceName];

  if (isArray(iconName)) {
    return (
        <div className="d-inline-block"  >
          { iconName.map(ico => <i key={keyGenerator()} className={`${ico} ${AppStore.iconsColorClassName}`} />) }
        </div>
    );
  }

  /*if(serviceName === 'complex' && length === 1)
    return null;*/

  return iconName ? <i className={`${iconName} ${AppStore.iconsColorClassName}`} /> : null;
};

export const formatOrderDate = (date) => {
  return moment(date).format('DD.MM.YYYY');
};

export const getWordDeclension = (number, name = 'day', prefix = 'detailsOfCalculation') => {
  const cases = [2, 0, 1, 1, 1, 2];
  const titles = [`${prefix}.${name}ImP`, `${prefix}.${name}RP`, `${prefix}.${name}Mn`];
  return translate({ id: titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]] });
};

export function getLangInStorage() {
  return localStorage.getItem(USER_LANG);
}

export function setLangInStorage(value) {
  localStorage.setItem(USER_LANG, value);
  setSessionCookie('lang', value);
}

export function getLandingHref() {
  //let href = `$\{window.location.origin === LOCALHOST?'http':'https'}://${window.location.host};`;
  let href = URL.WIZARD;

  /*if (window.location.origin === LOCALHOST || window.location.origin === TEST || window.location.origin === TEST_CARGOBAR) {
    href = PROD_LANDING;
  } else if (window.location.origin === PRE_PROD_CARGOBAR) {
    href = PRE_PROD_LANDING;
  } else if (window.location.origin === PROD_CARGOBAR) {
    href = PROD_LANDING;
  }*/
  return href;
}

export function getCargoInfoForCrud(item, isItem) {
  const cellIcoJSX = (name) => {
    return (
        <p key={keyGenerator()} className="cell-with-icon custom_tooltip">
          <i className={`cargo-${name}-icon`} />
          <span className="custom_tooltip__text">{ translate({ id: `modal.orderInfo.${name}` }) }</span>
        </p>
    );
  };
  if (isItem) {
    if (item.general) {
      const generals = item.generals.map((element) => {
        const { amount } = element;

        const volume = element.volumeInMeters;
        const weightInTonnes = element.weightInTonnes;
        let cargo = volume < weightInTonnes ? weightInTonnes : volume;
        cargo *= amount;

        return (
            <p key={keyGenerator()}>
              {element.typeId === 3 ?
                  <span className="custom_tooltip"
                  style={{ marginLeft: '30px'}}>
                    {get(element, 'prettyVolume', '-')}{' '}{get(element, 'prettyVolumeUnit', '-').toLowerCase()}
                  </span>
                  :
                  <span className="custom_tooltip">{amount}{' '}{ translate({ id: 'detailsOfCalculation.pieces' }) }{', '}
              {get(element, 'length', '-')}{' x '}
              {get(element, 'width', '-')}{' x '}
              {get(element, 'height', '-')}{' '}{get(element, 'sizeUnit', '-').toLowerCase()}
              <span className="custom_tooltip__text">, { cargo } w/m</span>
            </span>}
            </p>
        );
      });

      generals.unshift(cellIcoJSX('general'));

      return generals;
    }

    if (item.container) {
      const containers = item.containers.map((element) => {
        const weightUnit = find(WEIGHT_UNITS, unit => unit.code === element.weightUnit);

        return (
            <p key={keyGenerator()}>
            <span>
              { setName(element.type) }{', '}
              { translate({ id: 'detailsOfCalculation.weight' }) }{' '}
              { element.weight }{' '}{weightUnit && translate({ id: `constants.${weightUnit.message}` })}
            </span>
            </p>
        );
      });
      containers.unshift(cellIcoJSX('container'));

      return containers;
    }

    if (item.vehicle) {
      const { bodyType } = item.vehicle;
      const preparedBodyType = find(VEHICLE_BODY_TYPE, { value: bodyType });
      const typeName = translate({ id: `constants.${preparedBodyType.message}` });

      return (
          <p>
            { cellIcoJSX('vehicle') }
            <p>
            <span className="custom_tooltip">
              <span>{ setName(item.vehicle.type) }</span>
              <span className="custom_tooltip__text">{ typeName }</span>
            </span>
            </p>
          </p>
      );
    }
  } else {
    if (item.generals) {
      const generals = item.generals.map((element) => {
        const { amount } = element;

        const volume = element.volumeInMeters;
        const weightInTonnes = element.weightInTonnes;
        let cargo = volume < weightInTonnes ? weightInTonnes : volume;
        cargo *= amount;

        return (
            <p key={keyGenerator()}>
            <span className="custom_tooltip">{amount}{' '}{ translate({ id: 'detailsOfCalculation.pieces' }) }{', '}
              {get(element, 'length', '-')}{' x '}
              {get(element, 'width', '-')}{' x '}
              {get(element, 'height', '-')}{' '}{get(element, 'sizeUnit', '-').toLowerCase()}
              <span className="custom_tooltip__text">, { cargo } w/m</span>
            </span>
            </p>
        );
      });

      generals.unshift(cellIcoJSX('general'));

      return generals;
    }

    if (item.containers) {
      const containers = item.containers.map((element) => {
        const weightUnit = find(WEIGHT_UNITS, unit => unit.code === element.weightUnit);

        return (
            <p key={keyGenerator()}>
            <span>
              { setName(element.type) }{', '}
              { translate({ id: 'detailsOfCalculation.weight' }) }{' '}
              { element.weight }{' '}{weightUnit && translate({ id: `constants.${weightUnit.message}` })}
            </span>
            </p>
        );
      });
      containers.unshift(cellIcoJSX('container'));

      return containers;
    }

    if (item.vehicle) {
      const { bodyType } = item.vehicle;
      const preparedBodyType = find(VEHICLE_BODY_TYPE, { value: bodyType });
      const typeName = translate({ id: `constants.${preparedBodyType.message}` });

      return (
          <p>
            { cellIcoJSX('vehicle') }
            <p>
            <span className="custom_tooltip">
              <span>{ setName(item.vehicle.type) }</span>
              <span className="custom_tooltip__text">{ typeName }</span>
            </span>
            </p>
          </p>
      );
    }
  }

  return null;
}

export function convertKgToLb(value) {
  return +value * 2.2046 || 0;
}

export function convertLbToKg(value) {
  return +value / 2.2046 || 0;
}

export function getCalculationsSortingArray(resultType = 'BEST_PRICE') {
  const preparedSorts = ORDERS_SORT_FILTER.map((item) => {
    if (item.code === 'BEST_PRICE') {
      const language = find(AppStore.languages, lang => lang.code === AppStore.userLang);

      if (language && language.defaultCurrency) {
        set(item, 'fieldName', `multiTotalCostAfterConversion.${language.defaultCurrency}.value`);
      }
    }

    return item;
  });
  const mainOrder = find(preparedSorts, item => item.code === resultType);
  const secondaryOrder = find(preparedSorts, item => item.code !== resultType);

  return ['containsArtificialCalculation', mainOrder.fieldName, secondaryOrder.fieldName];
}

export function isDesktop() {
  return window.innerWidth > 768;
}

export function getNearestItem(value, availableValues = [], allValues = []) {
  if (availableValues.includes(value)) {
    return value;
  }

  const targetIndex = allValues.indexOf(value);

  let resultDiff = Infinity;
  let resultIndex = -1;

  availableValues.forEach((v) => {
    const currentIndex = allValues.indexOf(v);
    const currentDiff = Math.abs(targetIndex - currentIndex);

    if (currentDiff < resultDiff) {
      resultDiff = currentDiff;
      resultIndex = currentIndex;
    }
  });

  return allValues[resultIndex];
}


export function setCookie(cName, cValue, expDays) {
  const date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cName}=${cValue}; ${expires}; path=/`;
}

export function setSessionCookie(cName, cValue) {
  document.cookie = `${cName}=${cValue}; path=/`;
}

export function getCookie(cName) {
  const name = `${cName}=`;
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}



export function copyToBuffer(value) {
  navigator.clipboard.writeText(value)
      .then(() => {
        FlashStore.successNow(translate({id: 'common.copy.success'}), true);
      })
      .catch((err) => {
        FlashStore.errorNow(translate({id: 'common.copy.error'}), true);
        console.log('Something went wrong', err);
      });
};

export const roundToInt = (cost) => {
  if (typeof cost !== "number" || isNaN(cost)) {
    return cost
  }

  if (cost % 1 !== 0) {
    cost = Math.round(cost)
  }
  return cost
}
