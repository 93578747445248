/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep, isArray, isEmpty, isPlainObject, isNumber, compact, get, some, map, find, values as _values } from 'lodash'; //
import { toJS } from 'mobx';
import { Select } from './';
import DictionaryService from '../../../services/dictionaryService';
import { addFormatName, setName, translate, addFormatNameToCity, addFormatNameForAirsWithCity, addFormatNameWithCode, addFormatNameWithHub, addFormatNameToAddress, getLangInStorage, getExistLangValue } from '../../../utils/utils';
import HttpProvider from '../../../services/httpProvider';
import { API } from '../../../constants/api';

class InterlayerAsyncSelect extends Component {
  state = {
    portId: null,
    departurePortId: null,
    destinationPortId: null,
    freightSurchargesGeneralBySeaLineId: null,
    freightSurchargesContainerBySeaLineId: null,
    freightSurchargesRates: null,
    seaLineId: null,
    seaLineIdRates: null,
    geographyCountry: null,
    geographyCity: null,
    fromHubId: null,
    toHubId: null,
    transitHubId:null,
    airFromHubId: null,
    airToHubId: null,
    railwayFromId: null,
    railwayToId: null,
    railwayOperatorId: null,
    countryFromId: null,
    countryToId: null,
    country: null,
    serviceId: null,
    options: [],
    countries: null,
    disputedTerritories: null,
  };

  UNSAFE_componentWillMount() {
    const { name, countryCode } = this.props;

    if (name === 'fromHubId' || name === 'toHubId') {
      const promises = [DictionaryService.getCountries(), DictionaryService.getDisputedTerritories()];

      Promise.all(promises).then((res) => {
        this.setState({
          countries: res[0],
          disputedTerritories: res[1],
        });
      });
    }
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    // для получения портов и аэропортов при переходе с виджета
    if (newProps.name === 'wizardPort' || newProps.name === 'wizardAirport') {
      const res = await this.getOptionsForItem(newProps.name, newProps.value);

      if (!res || res.status === 'error') {
        return;
      }
      if (isPlainObject(res.data) && !res.data.items) {
        const data = cloneDeep(res.data);
        if (newProps.name === 'wizardAirport') {
          const addition = get(data, 'hasTerminals', false) ? ` (${translate({ id: 'form.wizard.allTerminals' })})` : ` (${data.code})`;
          const hubName = getExistLangValue(data.name);
          data.formatName = `${hubName}, ${setName(data.city)}${addition}`;
        } else {
          const hubName = getExistLangValue(data.name);
          data.formatName = `${hubName}, ${setName(data.city)}`;
        }

        this.setState({
          ...this.state,
          [newProps.name]: data,
        });
      } else {
        this.setState({
          ...this.state,
          [newProps.name]: null,
        });
      }
    }
    if (newProps.name === 'wizardStation') {
      const res = await this.getOptionsForItem(newProps.name, newProps.value);

      if (!res || res.status === 'error') {
        return;
      }
      if (isPlainObject(res.data) && !res.data.items) {
        const data = cloneDeep(res.data);
        data.formatName = `${setName(data.city)} (${setName(data)})`;

        this.setState({
          ...this.state,
          [newProps.name]: data,
        });
      } else {
        this.setState({
          ...this.state,
          [newProps.name]: null,
        });
      }
    }

    if (newProps.name === 'wizardAddress') {
      const res = await this.getOptionsForItem(newProps.name, newProps.value);

      if (!res || res.status === 'error') {
        return;
      }
      if (isPlainObject(res)) { // вроде необязателен
        const data = cloneDeep(res);
        this.setState({
          ...this.state,
          [newProps.name]: data,
        });
      }
      if (res.label) {
        const data = cloneDeep(res.label);
        data.formatName = data[`${getLangInStorage()}`];
        this.setState({
          ...this.state,
          [newProps.name]: data,
        });
      } else {
        this.setState({
          ...this.state,
          [newProps.name]: null,
        });
      }
    }

    // удаляем сервис, когда приходит новый id категории
    if (newProps.category !== this.props.category) {
      this.setState({ serviceId: null });
    }
    // удаляем надбавки, когда меняется морская линия
    if (newProps.name === 'freightSurchargesGeneralBySeaLineId' && isEmpty(newProps.value)) {
      this.setState({ freightSurchargesGeneralBySeaLineId: null });
    }

    // удаляем надбавки, когда меняется морская линия
    if (newProps.name === 'freightSurchargesContainerBySeaLineId' && isEmpty(newProps.value)) {
      this.setState({ freightSurchargesContainerBySeaLineId: null });
    }

    const id = this.props.item ? this.props.item.id : null;

    if (newProps.item && newProps.item.id !== id) {
      const newItem = cloneDeep(toJS(newProps.item));

      if (newProps.name === 'fromHubId' && newItem.toHub) {
        newItem.toHub.type = '';
      } else if (newProps.name === 'toHubId' && newItem.fromHub) {
        newItem.fromHub.type = '';
      }
      let res = []
      let dt = (await this.getOptionsForItem(newProps.name, newProps.value, newItem));
      if(newProps.name==='countryCode' && this.props.name==='transitHubId') {
        res = dt.filter(v => newProps.value ? v.country.code.toString() === newProps.value.toString() || v.country.id.toString() === newProps.value.toString() : true);
      }
      else
        res = (dt && typeof dt.filter === 'function') ? dt.filter(v=>this.props.countryCode? v.country.code.toString()===this.props.countryCode.toString()||v.country.id.toString()===this.props.countryCode.toString():true):dt;
      let newData = null;

      if (!res) {
        return;
      }

      if (isArray(res)) {
        newData = res.map((el) => {
          const item = cloneDeep(el);
          item.formatName = setName(item);
          return item;
        });
      } else {
        const data = cloneDeep(res.data);

        if (isArray(data)) {
          newData = data.map((el) => {
            const item = cloneDeep(el);
            item.formatName = setName(item);
            return item;
          });
        } else {
          data.formatName = setName(data);
          newData = data;
        }
      }

      this.setState({
        ...this.state,
        [newProps.name]: await newData,
      });
    }
  }

  getOptionsForItem(name, value, item) {
    switch (name) {
      case 'departurePortId':
      case 'destinationPortId':
      case 'portId':
        return HttpProvider.get(API.BASE(`dictionaries/sea-port-hubs/${value}`), false);

      case 'seaLineId':
      case 'consolidator':
        console.log("VALUE", value)
        if (isNumber(value)) {
          return HttpProvider.get(API.BASE(`dictionaries/sealines/${value}`), false);
        }
        if (value) {
          return HttpProvider.get(API.BASE(`dictionaries/sealines/${value.id}`), false);
        }
        return null;

      case 'seaLineIdRates': {
        return HttpProvider.get(API.BASE(`dictionaries/sealines/${value}`), false);
      }

      case 'airFromHubId':
      case 'airToHubId':
      case 'airportId':
        return HttpProvider.get(API.BASE(`dictionaries/airport-hubs/${value}`), false);

      case 'railwayFromId':
      case 'railwayToId':
      case 'railwayId':
        return HttpProvider.get(API.BASE(`dictionaries/railway-hubs/${value}`), false);

      case 'fromHubId':
      case 'transitHubId':
      case 'toHubId':
        if (item.fromHub.type === 'TRANSPORT' || item.toHub.type === 'TRANSPORT') {
          return HttpProvider.get(API.BASE(`dictionaries/transport-hubs/${value}`), false);
        }

        if (item.fromHub.type === 'SEA_PORT' || item.toHub.type === 'SEA_PORT') {
          return HttpProvider.get(API.BASE(`dictionaries/sea-port-hubs/${value}`), false);
        }

        if (item.fromHub.type === 'AIRPORT_HUB' || item.toHub.type === 'AIRPORT_HUB') {
          return HttpProvider.get(API.BASE(`dictionaries/airport-hubs/${value}`), false);
        }

        return null;

      case 'freightSurchargesGeneralBySeaLineId':
      case 'freightSurchargesContainerBySeaLineId': {
        return toJS(item.freightSurcharges).map(el => el);
      }

      case 'freightSurchargesRates': {
        return HttpProvider.get(API.BASE(`dictionaries/freight-surcharges/${value}`), false);
      }

      case 'country': {
        return HttpProvider.get(API.BASE(`geo/countries/${value.code}`), false);
      }

      case 'wizardCountry': {
        return this.props.values;
      }

      case 'countryFromId':
      case 'countryToId': {
        return HttpProvider.get(API.BASE(`dictionaries/geo/${value}`), false);
      }

      case 'serviceId': {
        return HttpProvider.get(API.BASE(`common/dictionaries/services?categoryId=${item}`), false);
      }

      // В данный момент отсутствие этого запроса ничего не меняет, но может понадобиться в дальнейшем
      case 'wizardAddress' : {
        return value;
      }

      case 'wizardPort': {
        return HttpProvider.get(API.BASE(`common/dictionaries/sea-port-hubs/${value}`), false);
      }

      case 'wizardAirport': {
        return HttpProvider.get(API.BASE(`common/dictionaries/airport-hubs/${value}`), false);
      }

      case 'wizardStation': {
        return HttpProvider.get(API.BASE(`common/dictionaries/railway-hubs/${value}`), false);
      }

      case 'railwayOperatorId': {
        if (value) {
          const val = isNumber(value) ? value : value.id;
          return HttpProvider.get(API.BASE(`dictionaries/railway-operators/${val}`), false);
        }

        return null;
      }

      default: {
        return [];
      }
    }
  }

  onChangeFields(value, name) {
    this.setState({
      ...this.state,
      [name]: value,
    }, () => this.props.onChange(value, name));
  }

  prepareAndFilterHubs(hubs) {
    const used = {};

    return hubs.filter(obj => obj).filter((obj) => {
      if (obj.id in used) {
        return 0;
      }
      used[obj.id] = 1;

      return used[obj.id];
    });
  }

  async requestMissingHubs(item, input, onlyRussia=false, type='') {
    // Чтобы подгружались выбранные хабы, если их нет в первой сотне
    const from = item.fromHub ? await DictionaryService.getAllHubById(item.fromHub.id) : null;
    const to = item.toHub ? await DictionaryService.getAllHubById(item.toHub.id) : null;
    const transit=item.transitHub? await DictionaryService.getAllHubById(item.transitHub.id) : null;
    const all = await DictionaryService.getAllHubsWithChina();

    const newHubs = cloneDeep(all);
    newHubs.push(...[from, transit, to]);
    if(onlyRussia===true){
      let filterHubs = newHubs.filter(v=> v && v.country && v.country.id===193)
      if(type.length>0) {
        filterHubs = cloneDeep(filterHubs.filter(v => v && v.type && v.type === type))

        return this.prepareAndFilterHubs(filterHubs)
      }
      return this.prepareAndFilterHubs(filterHubs)

    }
    if (input.length !== 0) {
      return await DictionaryService.getAllHubsWithChina({ name: input });
    }


    return this.prepareAndFilterHubs(newHubs);
  }

  async requestMissingPorts(item, input) {
    // Чтобы подгружались выбранные хабы, если их нет в первой сотне
    const departurePort = item.departurePort ? await DictionaryService.getSeaPortHubsById(item.departurePort.id) : null;
    const destinationPort = item.destinationPort ? await DictionaryService.getSeaPortHubsById(item.destinationPort.id) : null;
    const all = await DictionaryService.getSeaPortHubs();

    const newHubs = cloneDeep(all);
    newHubs.push(...[departurePort, destinationPort]);

    if (input.length !== 0) {
      return await DictionaryService.getSeaPortHubs({ name: input });
    }

    return this.prepareAndFilterHubs(newHubs);
  }

  async requestMissingOnePort(item, input) {
    // Чтобы подгружался выбранный порт, если его нет в первой сотне
    const port = item.port.id ? await DictionaryService.getSeaPortHubsById(item.port.id) : null;
    const all = await DictionaryService.getSeaPortHubs();

    const newHubs = cloneDeep(all);
    newHubs.push(...[port]);

    if (input.length !== 0) {
      return await DictionaryService.getSeaPortHubs({ name: input });
    }

    return this.prepareAndFilterHubs(newHubs);
  }

  async requestMissingAirports(item, input) {
    if (input.length !== 0) {
      return await DictionaryService.getAirHubs({ name: input });
    }

    if (get(item, 'airport.id', false)) {
      const airport = await DictionaryService.getAirHubsById(item.airport.id);
      const allAir = await DictionaryService.getAirHubs();
      const newAirHubs = cloneDeep(allAir);
      newAirHubs.push(...[airport]);

      return this.prepareAndFilterHubs(newAirHubs);
    }

    const airFromHubId = item.fromHub ? await DictionaryService.getAirHubsById(item.fromHub.id) : null;
    const airToHubId = item.toHub ? await DictionaryService.getAirHubsById(item.toHub.id) : null;
    const all = await DictionaryService.getAirHubs();

    const newHubs = cloneDeep(all);
    newHubs.push(...[airFromHubId, airToHubId]);

    return this.prepareAndFilterHubs(newHubs);
  }

  async requestMissingRailways(item, input) {
    if (input.length !== 0) {
      return await DictionaryService.getRailwayHubs({ name: input });
    }

    if (get(item, 'station.id', false)) {
      const station = await DictionaryService.getRailwayHubById(item.station.id);
      const allStations = await DictionaryService.getRailwayHubs();
      const newStationsArr = cloneDeep(allStations);
      newStationsArr.push(...[station]);

      return this.prepareAndFilterHubs(newStationsArr);
    }

    const from = item.from ? await DictionaryService.getRailwayHubById(item.from.id) : null;
    const to = item.to ? await DictionaryService.getRailwayHubById(item.to.id) : null;
    const all = await DictionaryService.getRailwayHubs();

    const newHubs = cloneDeep(all);
    newHubs.push(...[from, to]);

    return this.prepareAndFilterHubs(newHubs);
  }

  async makeRequest(name, input = '') {
    const { item, seaLine, countryId, countryCode, country,
      countryFrom, countryTo, hubTypeFrom, hubTypeTo, hubFromId, hubToId, isFrom} = this.props;

    switch (name) {
      case 'departurePortId':
      case 'destinationPortId': {
        if (item) {
          return await this.requestMissingPorts(item, input);
        }
        return await DictionaryService.getSeaPortHubs({ name: input });
      }

      case 'portId': {
        if (item) {
          return await this.requestMissingOnePort(item, input);
        }
        return await DictionaryService.getSeaPortHubs({ name: input });
      }

      case 'wizardAddress': {
        if (countryId) {
          return await DictionaryService.getCityesPopular({
            searchString: input,
            language: getLangInStorage(),
            country: country,
            countryFrom: countryFrom,
            countryTo: countryTo,
            typeFrom: hubTypeFrom,
            typeTo: hubTypeTo,
            hubFromId: hubFromId,
            hubToId: hubToId,
            isFrom: isFrom
          })
        }
        return null;
      }

      case 'wizardPort': {
        if (countryId) {
          return await DictionaryService.getSeaPortHubsByCityNamePopular({ name: input, countryId,
            countryFrom: countryFrom,
            countryTo: countryTo,
            typeFrom: hubTypeFrom,
            typeTo: hubTypeTo,
            hubFromId: hubFromId,
            hubToId: hubToId,
            isFrom: isFrom });
        }
        return null;
      }

      case 'wizardAirport': {
        if (countryId) {
          return await DictionaryService.getAirportHubsByCityNamePopular({ name: input, countryId,
            countryFrom: countryFrom,
            countryTo: countryTo,
            typeFrom: hubTypeFrom,
            typeTo: hubTypeTo,
            hubFromId: hubFromId,
            hubToId: hubToId,
            isFrom: isFrom});
        }
        return null;
      }

      case 'wizardStation': {
        if (countryId) {
          return await DictionaryService.getRailwayHubsByCityNamePopular({ name: input, countryId,
            countryFrom: countryFrom,
            countryTo: countryTo,
            typeFrom: hubTypeFrom,
            typeTo: hubTypeTo,
            hubFromId: hubFromId,
            hubToId: hubToId,
            isFrom: isFrom});
        }
        return null;
      }

      case 'seaLineId':
      case 'seaLineIdRates':
        return await DictionaryService.getSeaLines({ name: input, type: 'COMMON' });

      case 'consolidator':
        return await DictionaryService.getSeaLines({ name: input, type: 'CONSOLIDATOR' });
      case 'transitHubId':{
        if (item) {
          return (await this.requestMissingHubs(item, input)).filter(v=>countryCode? v.country.code.toString()===countryCode.toString()||v.country.id.toString()===countryCode.toString():true);
        }
        return (await DictionaryService.getAllHubsWithChina({ name: input })).filter(v=>countryCode? v.country.code.toString()===countryCode.toString()||v.country.id.toString()===countryCode.toString():true);
      }
      case 'fromHubId':
      case 'toHubId': {
        if (item) {
          return await this.requestMissingHubs(item, input);
        }
        return await DictionaryService.getAllHubsWithChina({ name: input });
      }
      case 'airAddressToHubId':
      case 'airAddressFromHubId':
      case 'airAddressTransitHubId':{
        if (item) {
          return await this.requestMissingHubs(item, input);
        }
        return await DictionaryService.getAllHubsWithChina({ name: input });
      }
      case 'portAddressToHubId':
      case'portAddressTransitHubId':
      case 'portAddressFromHubId':{
        if (item) {
          return await this.requestMissingHubs(item, input);
        }
        return await DictionaryService.getAllHubsWithChina({ name: input });
      }
      case 'portRailwayToHubId':
      case 'portRailwayTransitHubId':
      case 'portRailwayFromHubId':{
        if (item) {
          return await this.requestMissingHubs(item, input);
        }
        return await DictionaryService.getAllHubsWithChina({ name: input });
      }
      case 'adrRailwayToHubId':
      case 'adrRailwayTransitHubId':
      case 'adrRailwayFromHubId':{
        if (item) {
          return await this.requestMissingHubs(item, input);
        }
        return await DictionaryService.getAllHubsWithChina({ name: input });
      }
      case 'adrAdrFromHubId':
      case 'adrAdrTransitHubId':
      case 'adrAdrToHubId':{
        if (item) {
          return await this.requestMissingHubs(item, input);
        }
        return await DictionaryService.getAllHubsWithChina({ name: input });
      }
      case 'airportId':
      case 'airFromHubId':
      case 'airToHubId': {
        if (item) {
          return await this.requestMissingAirports(item, input);
        }
        return await DictionaryService.getAirHubs({ name: input });
      }

      case 'railwayId':
      case 'railwayFromId':
      case 'railwayToId': {
        if (item) {
          return await this.requestMissingRailways(item, input);
        }
        return await DictionaryService.getRailwayHubs({ name: input });
      }

      case 'trunkingId':
      case 'trunkingFromId':
      case 'trunkingToId': {
        if (item) {
          return await this.requestMissingHubs(item,input, true, "TRANSPORT")
        }
        const resp = await DictionaryService.getAllHubsWithChina({ name: input });
        const filter = resp.filter(v=>v.country.id===193 && v.type==="TRANSPORT")
        return filter
      }

      case 'freightSurchargesContainerBySeaLineId': {
        if (seaLine) {
          const seaTariffId = item ? item.id : 0;
          const res = await DictionaryService.getContainerFreightSurchargesByTariff({ id: seaLine.id, seaTariffId, code: input });

          return res.map(el => el.surcharge);
        }
        return null;
      }

      case 'freightSurchargesGeneralBySeaLineId': {
        if (seaLine) {
          const seaTariffId = item ? item.id : 0;
          const res = await DictionaryService.getGeneralFreightSurchargesByTariff({ id: seaLine.id, seaTariffId, code: input });

          return res.map(el => el.surcharge);
        }
        return null;
      }

      case 'freightSurchargesRates': {
        return await DictionaryService.getAllFreightSurcharges({ name: input });
      }

      case 'country':
      case 'countryFromId':
      case 'countryToId': {
        return await DictionaryService.getCountries({ name: input });
      }

      case 'wizardCountry': {
        if (input) {
          const search = input.toLowerCase();
          // eslint-disable-next-line no-shadow
          return this.props.values.filter(el => some(_values(el.name), country => country.toLowerCase().startsWith(search)));
          // return await DictionaryService.getCountries({ name: input });
        }
        return this.props.values;
      }

      case 'autocompleteCity': {
        return await DictionaryService.getCitiesExtended({ code: countryCode, name: input });
      }

      case 'serviceId': {
        return DictionaryService.getDictionaryServices({ id: item, name: input });
      }

      case 'airServiceId': {
        return DictionaryService.getAirportServices();
      }

      case 'railwayOperatorId': {
        return DictionaryService.getRailwayOperators({ name: input });
      }

      default: {
        return [];
      }
    }
  }

  async googleAutocomplete(input, countryCode, value) {
    const { countries, disputedTerritories } = this.state;
    const country = countryCode || null;

    const countriesNames = map(countries, item => Object.values(item.name));
    let _input = input;

    if (input === '' && value) {
      _input = value.formatName;
    }

    if (isEmpty(_input)) {
      return;
    }
    const checkSuggestIsDisputed = (suggest) => {
      const city = find(disputedTerritories, (item) => {
        return item.googlePlaceId === suggest.place_id || some(item.googleName, name => suggest.description.endsWith(name));
      });

      return city || null;
    };

    const displaySuggestions = (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        return;
      }

      const skipHandler = (item) => {
        const types = [
          'premise',
          'establishment',
          'route',
          'administrative_area_level_1',
          'administrative_area_level_2',
          'administrative_area_level_3',
          'administrative_area_level_4',
          'administrative_area_level_5',
          'country',
          'street_address',
          'street_number',
        ];
        const suggestCountry = item.terms[item.terms.length - 1].value;
        const disputedCity = checkSuggestIsDisputed(item);

        const isForbiddenTypes = some(types, (el) => { return item.types.includes(el); });
        const isNotInCountries = !some(countriesNames, (_country) => { return _country.includes(suggestCountry); });
        const isDisputedTerritories = !isEmpty(disputedCity);

        if (country) {
          return isForbiddenTypes;
        }

        return isForbiddenTypes || (isNotInCountries && !isDisputedTerritories);
      };

      this.setState({
        options: compact(predictions.map((prediction) => {
          if (skipHandler(prediction)) return null;

          let result = prediction.description;

          const crimeaObj = find(disputedTerritories, el => el.code === 'Crimea');
          let isCrimea = false;

          if (crimeaObj) {
            const crimeaNames = Object.values(crimeaObj.googleName);
            isCrimea = some(crimeaNames, el => result.endsWith(el));
          }

          const city = checkSuggestIsDisputed(prediction);

          if (city) {
            result = `${result}, ${setName(city.parent)}`;
          }

          return ({
            id: prediction.place_id,
            formatName: result,
            isCrimea,
          });
        })),
      });
    };

    const service = new window.google.maps.places.AutocompleteService();
    const request = {
      input: _input,
      componentRestrictions: { country },
    };
    service.getPlacePredictions(request, displaySuggestions);
  }

  loadOptions = async (input) => {
    const { name, countryCode, value, values } = this.props; //
    let res;
    if (name === 'wizardPort') {
      res = values.filter((item) => {
        //For debug! NOT DELETE
        //console.log(item.formatName+' / '+input+' / '+item.formatName.toLowerCase().includes(input.toLowerCase()))
        return item.formatName.toLowerCase().includes(input.toLowerCase())
      });
    }else {
      res = await this.makeRequest(name, input);
    }
    // Автокомплит для ввода адресов в визарде
    // if (name === 'autocompleteCity' && isEmpty(res) && countryCode === 'RU') {
    if (name === 'autocompleteCity') {
      this.googleAutocomplete(input, countryCode, value);

      return { options: this.state.options };
    }

    // Автокомплит для ввода адресов в транспортных тарифах
    if ((name === 'fromHubId' || name === 'toHubId') && isEmpty(res)) {
      this.googleAutocomplete(input, countryCode, value);

      return { options: this.state.options };
    }

    // для надбавок
    if (isPlainObject(res)) {
      const data = res.map((el) => {
        return el.surcharge;
      });
      return { options: addFormatName(data) };
    }

    if (name === 'airFromHubId' || name === 'airToHubId' || name === 'airportId') {
      return { options: addFormatNameForAirsWithCity(res) };
    }

    if(name === 'portAddressFromHubId' || name === 'portAddressToHubId'){
      return {options: addFormatName(res.filter(item=>item.type==='SEA_PORT'||item.type==='TRANSPORT'))}
    }
    if(name === 'portRailwayFromHubId' || name === 'portRailwayToHubId'){
      return {options: addFormatName(res.filter(item=>item.type==='SEA_PORT'||item.type==='RAILWAY_HUB'))}
    }
    if(name === 'airAddressFromHubId' || name === 'airAddressToHubId'){
      return {options: addFormatNameForAirsWithCity(res.filter(item=>item.type==='AIRPORT_HUB'||item.type==='TRANSPORT'))}
    }

    if(name === 'adrRailwayFromHubId' || name === 'adrRailwayToHubId'){
      return {options: addFormatName(res.filter(item=>item.type==='TRANSPORT' || item.type==='RAILWAY_HUB'))}}

    if(name === 'adrAdrFromHubId' || name === 'adrAdrToHubId'){
      return {options: addFormatName(res.filter(item=>item.type==='TRANSPORT' || item.type==='TRANSPORT'))}}
    if(name==='trunkingFromId' || name==='trunkingToId'){
      return {options: addFormatName(res.filter(item=>item.type==='TRANSPORT' && item.country.id===193))}
    }
    if (name === 'wizardAddress') {
      return { options: addFormatNameToAddress(res) };
    }

    if (name === 'wizardPort') {
      return { options: res };
    }

    if (name === 'wizardAirport') {
      return { options: addFormatNameWithCode(res) };
    }

    if (name === 'wizardStation') {
      return { options: addFormatNameWithHub(res) };
    }

    return { options: addFormatName(res) };
  };

  render() {
    const {
      async,
      multi,
      name,
      className,
      labelKey,
      valueKey,
      value,
      values,
      disabled,
      optionRenderer,
      valueRenderer,
      filterOption,
      error,
      searchable,
      leftAddon,
      label,
      cache,
      placeholder,
      clearable,
      backspaceRemoves,
    } = this.props;

    let val = this.state[name];

    // TODO: fix бага https://github.com/JedWatson/react-select/pull/773
    if (!multi && isArray(val)) {
      val = null;
    }

    if(val!==null && val!==undefined && Object.keys(val).length===0){
        val=null
    }

    return (
      <Select
        disabled={disabled}
        async={async}
        multi={multi}
        cache={cache}
        searchable={searchable}
        placeholder={placeholder}
        values={values}
        value={val || value}
        valueKey={valueKey}
        labelKey={labelKey}
        label={label}
        loadOptions={this.loadOptions}
        onChange={v => this.onChangeFields(v, name)}
        leftAddon={leftAddon}
        className={className}
        optionRenderer={optionRenderer}
        valueRenderer={valueRenderer}
        filterOption={filterOption}
        error={error}
        clearable={clearable}
        backspaceRemoves={backspaceRemoves}
      />
    );
  }
}

InterlayerAsyncSelect.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
  className: '',
  disabled: false,
  noResultsText: 'form.select.noResultText',
  searchable: false,
  async: true,
  multi: false,
  backspaceRemoves: true,
  cache: false,
};

InterlayerAsyncSelect.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  category: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  async: PropTypes.bool,
  cache: PropTypes.bool,
  multi: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  optionRenderer: PropTypes.func,
  valueRenderer: PropTypes.func,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
  values: PropTypes.arrayOf(PropTypes.object),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  searchable: PropTypes.bool,
  name: PropTypes.string,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,

  // для надабовок
  seaLine: PropTypes.object,

  // для визарда
  countryId: PropTypes.number,
  countryCode: PropTypes.string,
  filterOption: PropTypes.func,
  countryFrom: PropTypes.number,
  countryTo: PropTypes.number,
  hubTypeFrom: PropTypes.string,
  hubTypeTo: PropTypes.string,
  hubFromId: PropTypes.string,
  hubToId: PropTypes.string,
  isFrom: PropTypes.bool,
  clearable:PropTypes.bool,
  backspaceRemoves:PropTypes.bool,
};

export default InterlayerAsyncSelect;
